import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  MenuItem,
  TextField,
  FormHelperText,
  Select,
  Divider,
  useTheme,
  CircularProgress,
  styled,
} from '@mui/material';
import { format as dateFnsFormat } from 'date-fns';
import { useCallback, useEffect, useState, ReactNode, useMemo } from 'react';
import {
  Controller,
  useForm,
  Control,
  FieldPath,
  UseFormWatch,
} from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';

import {
  getProducts,
  getAttributes,
  updateProducts,
  updateVariants,
} from '@app/adapter/catalog-service';
import { useNavigateAdminDelegatable as useNavigate } from '@app/administrator/utils/useNavigateAdminDelegatable';
import { ProductLayout } from '@app/components/Layout/ProductLayout';
import {
  AttributeSelect,
  AttributeSelectItem,
} from '@app/components/Shared/AttributeSelect';
import { CitySelect } from '@app/components/Shared/CitySelect';
import { DateField } from '@app/components/Shared/DateField';
import { NumberField } from '@app/components/Shared/NumberField';
import { PrefectureSelect } from '@app/components/Shared/PrefectureSelect';
import { TimeSelect } from '@app/components/Shared/TimeSelect';
import { snackbarOpenState, snackbarTextState } from '@app/domain/app';
import { organizationSelector } from '@app/domain/organization';
import {
  ProductBulkForm,
  productBulkFormSchema,
  defaultProductBulkForm,
} from '@app/schemas/catalog';
import { Product, ProductUpdate } from '@app/types/catalog';
import { convertNewLine } from '@app/utils';
import {
  getCalculatedDairyPay,
  getCalculatedHourlyPay,
} from '@app/utils/catalog';
import { getMenuItems } from '@app/utils/components';
import {
  productStatusList,
  productCommutingList,
  productVariantsSkuList,
  productSelectionList,
  productCategoryList,
  notRequireClinicalDepartmentName,
} from '@app/utils/constants';
import { restoreSanitizedString } from '@app/utils/format';

const ColBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const RowBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

function FormControlItem(props: {
  checkName: FieldPath<ProductBulkForm>;
  children: ReactNode;
  control: Control<ProductBulkForm>;
  mixed?: boolean;
  required?: boolean;
  title: string;
  watch: UseFormWatch<ProductBulkForm>;
}) {
  const theme = useTheme();

  return (
    <ColBox
      gap={2}
      width={'40rem'}
      bgcolor={
        props.watch(props.checkName) ? theme.customPalette.selection : undefined
      }
      pt={2}
    >
      <RowBox gap={2} alignItems="center">
        <ColBox width="4rem" alignItems="center">
          <Controller
            name={props.checkName}
            control={props.control}
            render={({ field }) => (
              <Checkbox {...field} sx={{ height: '3rem', width: '3rem' }} />
            )}
          />
        </ColBox>
        <ColBox>
          <RowBox alignItems="center" gap={0.5}>
            <Typography>{props.title}</Typography>
            {props.required && (
              <Typography color={theme.customPalette.alert}>*</Typography>
            )}
            {props.mixed && (
              <Typography ml={2} color={'gray'} fontSize={'0.9rem'}>
                データ混在
              </Typography>
            )}
          </RowBox>
          {props.children}
        </ColBox>
      </RowBox>
      <Divider />
    </ColBox>
  );
}

type MixedValues = {
  additionalInformation?: boolean;
  attributesClinical?: boolean;
  attributesJob?: boolean;
  breakTime?: boolean;
  commuting?: boolean;
  count?: boolean;
  description?: boolean;
  orderConditions?: boolean;
  period?: boolean;
  price?: boolean;
  selection?: boolean;
  status?: boolean;
  time?: boolean;
  variantsDescription?: boolean;
  workLocation?: boolean;
};

export interface ProductBulkEditState {
  ids?: string[];
}

export function ProductBulkEdit() {
  const location = useLocation();
  const navigate = useNavigate();
  const organizationState = useRecoilValue(organizationSelector);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const locationState = location.state as ProductBulkEditState | null;
  const theme = useTheme();
  // 診療科目リストの状態
  const [clinicalDepartmentList, setClinicalDepartmentList] = useState<
    AttributeSelectItem[] | undefined
  >(undefined);
  const notRequireDepartmentId = useMemo(
    () =>
      clinicalDepartmentList
        ?.filter(
          (clinical) => clinical.name === notRequireClinicalDepartmentName
        )
        .map((cli) => cli.id) || [],
    [clinicalDepartmentList]
  );

  // 仕事種別リストの状態
  const [jobTypeList, setJobTypeList] = useState<
    AttributeSelectItem[] | undefined
  >(undefined);
  // 案件区分の状態
  const [categoryEnum, setCategoryEnum] = useState('');
  // 各求人データの混在有無の状態
  const [mixedValues, setMixedValues] = useState<MixedValues>({});
  // データ読み込み済みかどうかの状態
  const [isLoadData, setIsLoadData] = useState(false);
  // データを登録中かどうかの状態
  const [isLoadingRegister, setIsLoadingRegister] = useState(false);
  const [products, setProducts] = useState([] as Product[]);

  const commonClinicalIds = useMemo(() => {
    return (
      products[0]?.attributes
        ?.filter((a) =>
          clinicalDepartmentList?.some((i) => i.id === a.attributeId)
        )
        .map((a) => a.attributeId) ?? []
    );
  }, [clinicalDepartmentList, products]);
  const { control, handleSubmit, watch, reset, setValue, formState } =
    useForm<ProductBulkForm>({
      defaultValues: defaultProductBulkForm,
      mode: 'onChange',
      resolver: zodResolver(productBulkFormSchema),
    });

  /** APIに渡すための求人データに変換 */
  const convertProduct = useCallback(
    (formValue: ProductBulkForm) => {
      const data: ProductUpdate = {
        ...(formValue.checkPeriod || formValue.checkStatus
          ? {
              publication: {
                ...(formValue.checkPeriod
                  ? {
                      since: new Date(formValue.startPeriod).toISOString(),
                      until: new Date(formValue.endPeriod).toISOString(),
                    }
                  : {}),
                ...(formValue.checkStatus
                  ? { status: formValue.status || 'ACTIVE' }
                  : {}),
              },
            }
          : {}),
        ...(formValue.checkWorkLocation
          ? {
              locationIds: [
                formValue.workLocationOther
                  ? formValue.workPrefecture
                  : organizationState.addressLine1 ?? '',
                formValue.workLocationOther
                  ? formValue.workCity
                  : organizationState.addressLine2 ?? '',
              ],
            }
          : {}),
        ...(formValue.checkAttributesClinical || formValue.checkAttributesJob
          ? {
              attributes: [
                ...(formValue.checkAttributesClinical
                  ? formValue.attributesClinical.map((id) => ({
                      attributeId: id,
                      value:
                        clinicalDepartmentList?.find((v) => v.id === id)
                          ?.name ?? '',
                    }))
                  : []),
                ...(formValue.checkAttributesJob
                  ? formValue.attributesJob.map((id) => ({
                      attributeId: id,
                      value: jobTypeList?.find((v) => v.id === id)?.name ?? '',
                    }))
                  : []),
              ],
            }
          : {}),
        ...(formValue.checkDescription
          ? {
              description: formValue.description,
            }
          : {}),
        ...(formValue.checkAdditionalInformation
          ? { additionalInformation: formValue.additionalInformation }
          : {}),
        ...(formValue.checkPrice || formValue.checkVariantsDescription
          ? {
              variants: [
                {
                  ...(formValue.checkPrice
                    ? {
                        price: {
                          amount: formValue.variantsPriceAmount ?? 0,
                          currency: 'JPY',
                          taxIncluded: true,
                        },
                        sku: formValue.variantsSku || 'day',
                      }
                    : undefined),
                  ...(formValue.checkVariantsDescription
                    ? {
                        description: formValue.variantsDescription,
                      }
                    : undefined),
                },
              ],
            }
          : {}),
        customFields: {
          ...(formValue.checkOrderConditions
            ? {
                orderConditions: formValue.orderConditions,
              }
            : {}),
          ...(formValue.checkSelection
            ? {
                selection: formValue.selection,
              }
            : {}),
          ...(formValue.checkTime
            ? {
                endTime: formValue.endTime,
                overWorkConditions: [
                  ...(formValue.possibleTimeAdjust ? ['時間調整可'] : []),
                  ...(formValue.possibleOvertime ? ['残業あり'] : []),
                  ...(formValue.possibleOvertime && formValue.overtimeAllowance
                    ? ['残業代の追加あり']
                    : []),
                ] as NonNullable<
                  NonNullable<
                    ProductUpdate['customFields']
                  >['overWorkConditions']
                >,
                startTime: formValue.startTime,
              }
            : {}),
          ...(formValue.checkBreakTime
            ? {
                breakTime: formValue.breakTimeNoParticular
                  ? 0
                  : formValue.breakTime ?? 0,
                breakTimeNoParticular: formValue.breakTimeNoParticular,
              }
            : {}),
          ...(formValue.checkCommuting
            ? {
                commuting: formValue.commuting,
                commutingCost:
                  formValue.commuting === 'UpperLimit' ||
                  formValue.commuting === 'FixedFee' ||
                  formValue.commuting === 'Include'
                    ? formValue.commutingCost ?? 0
                    : 0,
              }
            : {}),
          ...(formValue.checkWorkLocation
            ? {
                access: formValue.workLocationOther
                  ? formValue.access
                  : organizationState.customFields.access ?? '',
                workAddress1: formValue.workLocationOther
                  ? formValue.workAddress1
                  : organizationState.addressLine3 ?? '',
                workAddress2: formValue.workLocationOther
                  ? formValue.workAddress2
                  : organizationState.customFields.addressLine4 ?? '',
                workPostalCode: formValue.workLocationOther
                  ? formValue.workPostalCode
                  : organizationState.postalCode ?? '',
              }
            : {}),
          ...(formValue.checkCount
            ? {
                count: formValue.count ?? 0,
              }
            : {}),
          ...(formValue.checkVariantsDescription
            ? {
                incentive: formValue.variantsDescription,
              }
            : {}),
        },
      };
      return data;
    },
    [organizationState, jobTypeList, clinicalDepartmentList]
  );

  const handleRegisterButtonClick: Parameters<typeof handleSubmit>[0] =
    useCallback(
      async (formValue) => {
        if (!locationState?.ids?.length) {
          return;
        }
        try {
          setIsLoadingRegister(true);
          const data = convertProduct(formValue);
          const result = await updateProducts(
            products.map((product) => {
              const variantsSku =
                data.variants?.[data.variants.length - 1].sku ??
                product.variants[product.variants.length - 1].sku;
              const variantsPriceAmount =
                data.variants?.[data.variants.length - 1].price?.amount ??
                product.variants[product.variants.length - 1].price.amount;
              const startTime =
                data.customFields?.startTime ?? product.customFields.startTime;
              const endTime =
                data.customFields?.endTime ?? product.customFields.endTime;
              const breakTime =
                data.customFields?.breakTime ?? product.customFields.breakTime;
              return {
                data: {
                  ...data,
                  customFields: {
                    ...data.customFields,
                    calculatedDairyPay:
                      variantsSku === 'day'
                        ? variantsPriceAmount // 日給の場合は報酬金額そのまま設定
                        : getCalculatedDairyPay(
                            startTime,
                            endTime,
                            breakTime,
                            variantsPriceAmount
                          ), // 時給の場合は報酬金額×勤務時間
                    calculatedHourlyPay:
                      variantsSku === 'hour'
                        ? variantsPriceAmount // 時給の場合は報酬金額そのまま設定
                        : getCalculatedHourlyPay(
                            startTime,
                            endTime,
                            breakTime,
                            variantsPriceAmount
                          ), // 日給の場合は報酬金額÷勤務時間
                  },
                },
                ids: [product.id],
              };
            }),
            organizationState.id
          );
          if (result.status !== 200) {
            throw new Error(`${result.status} ${result.statusText}`);
          }

          if (data.variants?.[0]) {
            const resultVariants = await updateVariants([
              {
                data: data.variants[0],
                ids: products.map((p) => p.variants[p.variants.length - 1].id),
              },
            ]);
            if (resultVariants.status !== 200) {
              throw new Error(
                `Variants ${resultVariants.status} ${resultVariants.statusText}`
              );
            }
          }

          navigate('/products');
        } catch (error) {
          setSnackbarText(
            `求人の更新に失敗しました, ${
              error instanceof Error ? error.message : error
            }`
          );
          setSnackbarOpen(true);
          return [[], []] as AttributeSelectItem[][];
        } finally {
          setIsLoadingRegister(false);
        }
      },
      [
        convertProduct,
        locationState,
        organizationState.id,
        products,
        navigate,
        setSnackbarText,
        setSnackbarOpen,
      ]
    );

  /** 診療科目または仕事種別の読み込み */
  const loadAttributes = useCallback(async () => {
    try {
      const result = await getAttributes({
        filter: {
          groupName: ['clinicalDepartment'],
        },
        pageNumber: 0,
        pageSize: 100,
      });
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      const clinicalList: AttributeSelectItem[] = result.data.value.map(
        (a) => ({
          group: a.items?.[0].groupName ?? '',
          id: a.id,
          name: a.items?.[0].value ?? '',
        })
      );
      setClinicalDepartmentList(clinicalList);

      const resultJob = await getAttributes({
        filter: {
          groupName: ['jobType'],
        },
        pageNumber: 0,
        pageSize: 100,
      });
      if (resultJob.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      const jobList: AttributeSelectItem[] = resultJob.data.value.map((a) => ({
        id: a.id,
        name: a.name ?? '',
      }));
      setJobTypeList(jobList);

      return [clinicalList, jobList];
    } catch (error) {
      setSnackbarText(
        `診療科目または仕事種別の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
      return [[], []] as AttributeSelectItem[][];
    }
  }, [setSnackbarText, setSnackbarOpen]);

  /** 求人データの読み込み */
  const loadProducts = useCallback(
    async (
      clinicalList: AttributeSelectItem[],
      jobList: AttributeSelectItem[]
    ) => {
      if (!locationState?.ids?.length) {
        return;
      }
      try {
        setIsLoadData(false);
        const result = await getProducts(organizationState.id, {
          ids: locationState.ids,
          order: 'createdAt desc',
          pageNumber: 0,
          pageSize: 100,
        });
        if (result.status !== 200) {
          throw new Error(`${result.status}`);
        }
        const productList = result.data.value;

        setProducts(productList);

        // データ混在をチェック
        const isMixed = <U,>(callbackfn: (value: Product) => U) => {
          return Array.from(new Set(productList.map(callbackfn))).length > 1;
        };
        const mix: MixedValues = {
          additionalInformation: isMixed((p) => p.additionalInformation),
          attributesClinical: isMixed((p) =>
            p.attributes
              ?.filter((a) => clinicalList.some((i) => i.id === a.attributeId))
              .map((a) => a.attributeId)
              .sort()
              .join(',')
          ),
          attributesJob: isMixed((p) =>
            p.attributes
              ?.filter((a) => jobList.some((i) => i.id === a.attributeId))
              .map((a) => a.attributeId)
              .sort()
              .join(',')
          ),
          breakTime: isMixed(
            (p) =>
              `${p.customFields?.breakTime ?? ''} ${
                p.customFields?.breakTimeNoParticular ?? ''
              }`
          ),
          commuting: isMixed(
            (p) =>
              `${p.customFields?.commuting ?? ''} ${
                p.customFields?.commutingCost ?? ''
              }`
          ),
          count: isMixed((p) => p.customFields?.count),
          description: isMixed((p) => p.description),
          orderConditions: isMixed((p) => p.customFields?.orderConditions),
          period: isMixed(
            (p) =>
              `${p.publication?.since ?? ''}～${p.publication?.until ?? ''}`
          ),
          price: isMixed(
            (p) =>
              `${p.variants[p.variants.length - 1]?.sku ?? ''} ${
                p.variants[p.variants.length - 1]?.price.amount ?? ''
              }`
          ),
          selection: isMixed((p) => p.customFields?.selection),
          status: isMixed((p) => p.publication?.status),
          time: isMixed(
            (p) =>
              `${p.customFields?.startTime ?? ''}～${
                p.customFields?.endTime ?? ''
              } ${p.customFields?.overWorkConditions?.join(' ') ?? ''}`
          ),
          variantsDescription: isMixed(
            (p) => p.variants[p.variants.length - 1]?.description
          ),
          workLocation: isMixed(
            (p) =>
              `${p.customFields?.workPostalCode ?? ''} ${p.locations
                ?.map((l) => l.name)
                .join(' ')} ${p.customFields?.workAddress1 ?? ''} ${
                p.customFields?.workAddress2 ?? ''
              } ${p.customFields?.access ?? ''}`
          ),
        };
        setMixedValues(mix);

        // フォーム用の求人データを生成
        const product = productList[0];
        const workPostalCode = product.customFields?.workPostalCode ?? '';
        const workCity =
          product.locations?.find((l) => l.type === 'city')?.id ?? '';
        const workPrefecture =
          product.locations?.find((l) => l.type === 'prefecture')?.id ?? '';
        const workAddress1 = product.customFields?.workAddress1 ?? '';
        const workAddress2 = product.customFields?.workAddress2 ?? '';
        const access = convertNewLine(product.customFields?.access ?? '');
        const isWorkLocationOther =
          organizationState.postalCode !== workPostalCode ||
          organizationState.addressLine1 !== workPrefecture ||
          organizationState.addressLine2 !== workCity ||
          organizationState.addressLine3 !== workAddress1 ||
          (organizationState.customFields.addressLine4 ?? '') !==
            workAddress2 ||
          convertNewLine(organizationState.customFields.access ?? '') !==
            access;
        const clinicalAtt = product.attributes?.filter((a) =>
          clinicalList.some((i) => i.id === a.attributeId)
        );
        const isNotRequireCli =
          clinicalAtt.length === 1 &&
          clinicalAtt[0].value === notRequireClinicalDepartmentName;
        const formValues: ProductBulkForm = {
          ...defaultProductBulkForm,
          access: mix.workLocation
            ? ''
            : convertNewLine(product.customFields.access ?? ''),
          additionalInformation: mix.additionalInformation
            ? ''
            : restoreSanitizedString(product.additionalInformation),
          attributesClinical:
            mix.attributesClinical || isNotRequireCli
              ? []
              : product.attributes
                  ?.filter((a) =>
                    clinicalList.some((i) => i.id === a.attributeId)
                  )
                  .map((a) => a.attributeId) ?? [],
          attributesJob: mix.attributesJob
            ? []
            : product.attributes
                ?.filter((a) => jobList.some((i) => i.id === a.attributeId))
                .map((a) => a.attributeId) ?? [],
          breakTime: mix.breakTime
            ? null
            : product.customFields?.breakTime ?? null,
          breakTimeNoParticular: mix.breakTime
            ? false
            : product.customFields?.breakTimeNoParticular ?? false,
          commuting: mix.commuting
            ? ''
            : (product.customFields?.commuting as ProductBulkForm['commuting']),
          commutingCost: mix.commuting
            ? null
            : product.customFields?.commutingCost ?? null,
          count: mix.count ? null : product.customFields?.count ?? null,
          day: productList
            .map((p) =>
              p.customFields?.day ? new Date(p.customFields?.day) : undefined
            )
            .sort((a, b) => (a?.getTime() ?? 0) - (b?.getTime() ?? 0))[0],
          description: mix.description
            ? ''
            : restoreSanitizedString(product.description),
          endPeriod: mix.period
            ? ''
            : dateFnsFormat(new Date(product.publication.until), 'yyyy/MM/dd'),
          endTime: mix.time ? '' : product.customFields?.endTime ?? '',
          isNotRequireClinicalDepartment: mix.attributesClinical
            ? false
            : !!(
                product.attributes.length === 1 &&
                product.attributes[0].value === notRequireClinicalDepartmentName
              ),
          orderConditions: mix.orderConditions
            ? ''
            : convertNewLine(product.customFields?.orderConditions ?? ''),
          overtimeAllowance: mix.time
            ? false
            : product.customFields?.overWorkConditions?.some(
                (v) => v === '残業代の追加あり'
              ) ?? false,
          possibleOvertime: mix.time
            ? false
            : product.customFields?.overWorkConditions?.some(
                (v) => v === '残業あり'
              ) ?? false,
          possibleTimeAdjust: mix.time
            ? false
            : product.customFields?.overWorkConditions?.some(
                (v) => v === '時間調整可'
              ) ?? false,
          selection: mix.selection ? '' : product.customFields?.selection ?? '',
          startPeriod: mix.period
            ? ''
            : dateFnsFormat(new Date(product.publication.since), 'yyyy/MM/dd'),
          startTime: mix.time ? '' : product.customFields?.startTime ?? '',
          status: mix.status ? '' : product.publication?.status ?? '',
          variantsDescription: mix.variantsDescription
            ? ''
            : convertNewLine(
                product.variants?.[product.variants.length - 1]?.description ??
                  ''
              ),
          variantsPriceAmount: mix.price
            ? null
            : product.variants?.[product.variants.length - 1]?.price.amount ??
              null,
          variantsSku: mix.price
            ? ''
            : ((product.variants?.[0]?.sku ??
                'day') as ProductBulkForm['variantsSku']),
          workAddress1: mix.workLocation ? '' : workAddress1,
          workAddress2: mix.workLocation ? '' : workAddress2,
          workCity: mix.workLocation ? '' : workCity,
          workLocationOther: mix.workLocation ? true : isWorkLocationOther,
          workPostalCode: mix.workLocation ? '' : workPostalCode,
          workPrefecture: mix.workLocation ? '' : workPrefecture,
        };
        setCategoryEnum(
          productCategoryList.find((c) => c.name === product.category.name)
            ?.id ?? ''
        );
        reset(formValues);
      } catch (error) {
        setSnackbarText(
          `求人の取得に失敗しました。, ${
            error instanceof Error ? error.message : error
          }`
        );
        setSnackbarOpen(true);
      } finally {
        setIsLoadData(true);
      }
    },
    [
      locationState?.ids,
      organizationState.id,
      organizationState.postalCode,
      organizationState.addressLine1,
      organizationState.addressLine2,
      organizationState.addressLine3,
      organizationState.customFields.addressLine4,
      organizationState.customFields.access,
      reset,
      setSnackbarText,
      setSnackbarOpen,
    ]
  );

  useEffect(() => {
    void (async () => {
      const [clinicalList, jobList] = await loadAttributes();
      await loadProducts(clinicalList, jobList);
    })();
  }, [loadAttributes, loadProducts]);

  // 求人データが読み込めるまで内容を表示しない
  if (!isLoadData) {
    return (
      <ProductLayout sx={{ minWidth: 1000, px: 0 }}>
        <RowBox alignItems="center" gap={1} px={2}>
          <Typography variant="h5">一括編集</Typography>
        </RowBox>
        <Divider />
        <ColBox gap={1} px={2}>
          <Typography>
            編集対象の求人ID：{locationState?.ids?.join('、')}
          </Typography>
        </ColBox>
        <CircularProgress sx={{ m: 'auto' }} />
      </ProductLayout>
    );
  }

  return (
    <ProductLayout sx={{ minWidth: 1000, px: 0 }}>
      <RowBox alignItems="center" gap={1} px={2}>
        <Typography variant="h5">一括編集</Typography>
        <RowBox alignItems="center" gap={2} ml="auto">
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            size="small"
            sx={{ width: '8rem' }}
            loading={isLoadingRegister}
            onClick={handleSubmit(handleRegisterButtonClick)}
          >
            一括編集
          </LoadingButton>
        </RowBox>
      </RowBox>

      <Divider />

      <ColBox gap={1} px={2}>
        <Typography>
          編集対象の求人ID：{locationState?.ids?.join('、')}
        </Typography>
      </ColBox>

      <Divider />

      <ColBox px={2}>
        <ColBox gap={2} mb={5}>
          <Typography sx={{ color: theme.palette.grey[600] }}>
            一括で変更したい項目にチェックを入れてください。
          </Typography>
          <Typography sx={{ color: theme.palette.grey[600] }}>
            「データ混在」となっている箇所は、選択した求人の間でデータが異なる箇所です。一括編集で選択しなければ、元のデータのままとなります。
          </Typography>
        </ColBox>
        {formState.errors.checkAll?.message && (
          <Typography color={theme.customPalette.alert}>
            {formState.errors.checkAll.message}
          </Typography>
        )}
        <FormControlItem
          title="掲載ステータス"
          required
          mixed={mixedValues.status}
          checkName="checkStatus"
          control={control}
          watch={watch}
        >
          <Controller
            name="status"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <Select
                  {...field}
                  error={!!error}
                  sx={{ bgcolor: theme.palette.common.white, width: '20rem' }}
                  displayEmpty
                  renderValue={(selected: string) => {
                    if (selected) {
                      return productStatusList.find((a) => a.id === selected)
                        ?.name;
                    }
                    return (
                      <span
                        style={{
                          color: theme.customPalette.lightGray,
                        }}
                      >
                        掲載ステータスを選択
                      </span>
                    );
                  }}
                >
                  <MenuItem value="" disabled>
                    掲載ステータスを選択
                  </MenuItem>
                  {getMenuItems(productStatusList)}
                </Select>
                {error ? (
                  <FormHelperText error sx={{ ml: 2 }}>
                    {error.message}
                  </FormHelperText>
                ) : undefined}
              </>
            )}
          />
        </FormControlItem>
        <FormControlItem
          title="掲載期間"
          required
          mixed={mixedValues.period}
          checkName="checkPeriod"
          control={control}
          watch={watch}
        >
          <RowBox alignItems={'baseline'} gap={1}>
            <Controller
              name="startPeriod"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DateField
                  {...field}
                  ref={undefined}
                  inputRef={field.ref}
                  format="yyyy/MM/dd"
                  error={!!error}
                  sx={{ width: '10rem' }}
                  InputProps={{ sx: { bgcolor: theme.palette.common.white } }}
                  helperText={error?.message}
                />
              )}
            />
            <Typography>～</Typography>
            <Controller
              name="endPeriod"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DateField
                  {...field}
                  ref={undefined}
                  inputRef={field.ref}
                  format="yyyy/MM/dd"
                  error={!!error}
                  sx={{ width: '10rem' }}
                  InputProps={{ sx: { bgcolor: theme.palette.common.white } }}
                  helperText={error?.message}
                />
              )}
            />
          </RowBox>
        </FormControlItem>
        <FormControlItem
          title="勤務地"
          required
          mixed={mixedValues.workLocation}
          checkName="checkWorkLocation"
          control={control}
          watch={watch}
        >
          <Select
            value={organizationState.name}
            disabled={watch('workLocationOther')}
            sx={{
              bgcolor: !watch('workLocationOther')
                ? theme.palette.common.white
                : theme.palette.action.disabledBackground,
              width: '25rem',
            }}
            displayEmpty
          >
            {getMenuItems([organizationState.name])}
          </Select>
          <Controller
            name="workLocationOther"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormControlLabel
                {...field}
                checked={field.value}
                onChange={(e, checked) => {
                  if (!checked) {
                    // 勤務先で選ばれている勤務地情報を設定
                    setValue('workPostalCode', organizationState.postalCode);
                    setValue('workPrefecture', organizationState.addressLine1);
                    setValue('workCity', organizationState.addressLine2);
                    setValue('workAddress1', organizationState.addressLine3);
                    setValue(
                      'workAddress2',
                      organizationState.customFields.addressLine4 ?? ''
                    );
                    setValue(
                      'access',
                      organizationState.customFields.access ?? ''
                    );
                  } else {
                    setValue('workPostalCode', '');
                    setValue('workPrefecture', '');
                    setValue('workCity', '');
                    setValue('workAddress1', '');
                    setValue('workAddress2', '');
                    setValue('access', '');
                  }
                  field.onChange(e, checked);
                }}
                control={<Checkbox />}
                label="他の勤務地情報を入力する"
              />
            )}
          />
          <RowBox alignItems="center" gap={0.5}>
            <Typography>勤務地情報</Typography>
            <Typography color={theme.customPalette.alert}>*</Typography>
          </RowBox>
          <ColBox gap={1}>
            <Controller
              name="workPostalCode"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  disabled={!watch('workLocationOther')}
                  ref={undefined}
                  inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                  sx={{
                    width: '12rem',
                  }}
                  InputProps={{
                    sx: { bgcolor: theme.palette.common.white },
                  }}
                  placeholder="郵便番号を入力"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <RowBox alignItems="center" gap={2} width="25rem">
              <Controller
                name="workPrefecture"
                control={control}
                rules={{ required: '都道府県を選択してください' }}
                render={({ field, fieldState: { error } }) => (
                  <PrefectureSelect
                    {...field}
                    disabled={!watch('workLocationOther')}
                    ref={undefined}
                    inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                    sx={{
                      '.Mui-disabled': {
                        WebkitTextFillColor: watch('workPrefecture')
                          ? `${theme.palette.text.primary} !important`
                          : undefined,
                      },
                      width: '12rem',
                    }}
                    inputProps={{
                      sx: { bgcolor: theme.palette.common.white },
                    }}
                    onChange={(e) => {
                      setValue('workCity', '');
                      field.onChange(e);
                    }}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
              <Controller
                name="workCity"
                control={control}
                rules={{ required: '市町村を選択してください' }}
                render={({ field, fieldState: { error } }) => (
                  <CitySelect
                    {...field}
                    disabled={!watch('workLocationOther')}
                    ref={undefined}
                    inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                    sx={{
                      '.Mui-disabled': {
                        WebkitTextFillColor: watch('workCity')
                          ? `${theme.palette.text.primary} !important`
                          : undefined,
                      },
                      width: '12rem',
                    }}
                    inputProps={{
                      sx: { bgcolor: theme.palette.common.white },
                    }}
                    prefecture={watch('workPrefecture')}
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </RowBox>
            <Controller
              name="workAddress1"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  disabled={!watch('workLocationOther')}
                  ref={undefined}
                  inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                  sx={{
                    width: '25rem',
                  }}
                  InputProps={{
                    sx: { bgcolor: theme.palette.common.white },
                  }}
                  error={!!error}
                  helperText={error?.message}
                  placeholder="町名・番地を入力"
                />
              )}
            />
            <Controller
              name="workAddress2"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  disabled={!watch('workLocationOther')}
                  ref={undefined}
                  inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                  sx={{
                    width: '25rem',
                  }}
                  InputProps={{
                    sx: { bgcolor: theme.palette.common.white },
                  }}
                  error={!!error}
                  helperText={error?.message}
                  placeholder="建物名・部屋番号を入力"
                />
              )}
            />
            <RowBox alignItems="center" gap={0.5}>
              <Typography>アクセス情報</Typography>
              <Typography color={theme.customPalette.alert}>*</Typography>
            </RowBox>
            <Controller
              name="access"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  disabled={!watch('workLocationOther')}
                  ref={undefined}
                  inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                  error={!!error}
                  sx={{
                    '.Mui-disabled': {
                      WebkitTextFillColor: watch('access')
                        ? `${theme.palette.text.primary} !important`
                        : `${theme.palette.grey[800]} !important`,
                    },
                    color: 'blue',
                    width: '25rem',
                  }}
                  InputProps={{
                    sx: { bgcolor: theme.palette.common.white },
                  }}
                  multiline
                  rows={2.5}
                  placeholder={`アクセス情報を入力\r例）ＪＲ山手線新宿駅から徒歩５分`}
                  helperText={error?.message}
                />
              )}
            />
          </ColBox>
        </FormControlItem>
        <FormControlItem
          title="募集科目"
          required
          mixed={mixedValues.attributesClinical}
          checkName="checkAttributesClinical"
          control={control}
          watch={watch}
        >
          <Controller
            name="isNotRequireClinicalDepartment"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                {...field}
                checked={field.value}
                label={notRequireClinicalDepartmentName}
                sx={{ width: 'fit-content' }}
                control={<Checkbox />}
                onChange={(e, checked) => {
                  if (checked) {
                    setValue('attributesClinical', notRequireDepartmentId);
                  } else {
                    setValue(
                      'attributesClinical',
                      mixedValues.attributesClinical ? [] : commonClinicalIds
                    );
                  }
                  field.onChange(e, checked);
                }}
              />
            )}
          />
          {!watch('isNotRequireClinicalDepartment') && (
            <Controller
              name="attributesClinical"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <AttributeSelect
                  {...field}
                  notRequireDepartmentId={notRequireDepartmentId[0]}
                  label="科目を選択"
                  isGroup
                  list={clinicalDepartmentList}
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          )}
        </FormControlItem>

        <FormControlItem
          title="仕事種別"
          required
          mixed={mixedValues.attributesJob}
          checkName="checkAttributesJob"
          control={control}
          watch={watch}
        >
          <Controller
            name="attributesJob"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <AttributeSelect
                {...field}
                label="仕事種別を選択"
                list={jobTypeList}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FormControlItem>
        <FormControlItem
          title="仕事内容"
          required
          mixed={mixedValues.description}
          checkName="checkDescription"
          control={control}
          watch={watch}
        >
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                ref={undefined}
                inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                error={!!error}
                sx={{ width: '22rem' }}
                InputProps={{
                  sx: { bgcolor: theme.palette.common.white },
                }}
                multiline
                rows={2.5}
                placeholder="仕事内容を入力"
                helperText={error?.message}
              />
            )}
          />
        </FormControlItem>
        <FormControlItem
          title="応募条件"
          mixed={mixedValues.orderConditions}
          checkName="checkOrderConditions"
          control={control}
          watch={watch}
        >
          <Controller
            name="orderConditions"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                ref={undefined}
                inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                error={!!error}
                sx={{ width: '22rem' }}
                InputProps={{
                  sx: { bgcolor: theme.palette.common.white },
                }}
                multiline
                rows={2.5}
                placeholder="応募条件を入力"
                helperText={error?.message}
              />
            )}
          />
        </FormControlItem>
        {categoryEnum === 'regularPartTime' && (
          <FormControlItem
            title="選考方法"
            mixed={mixedValues.selection}
            checkName="checkSelection"
            control={control}
            watch={watch}
          >
            <Controller
              name="selection"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Select
                    {...field}
                    error={!!error}
                    sx={{ width: '12rem' }}
                    inputProps={{
                      sx: { bgcolor: theme.palette.common.white },
                    }}
                    displayEmpty
                    renderValue={(selected: string) => {
                      if (selected) {
                        return selected;
                      }
                      return (
                        <span
                          style={{
                            color: theme.customPalette.lightGray,
                          }}
                        >
                          選考方法を選択
                        </span>
                      );
                    }}
                  >
                    {getMenuItems(productSelectionList)}
                  </Select>
                  {error ? (
                    <FormHelperText error sx={{ ml: 2 }}>
                      {error.message}
                    </FormHelperText>
                  ) : undefined}
                </>
              )}
            />
          </FormControlItem>
        )}
        <FormControlItem
          title="その他情報"
          mixed={mixedValues.additionalInformation}
          checkName="checkAdditionalInformation"
          control={control}
          watch={watch}
        >
          <Controller
            name="additionalInformation"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                ref={undefined}
                inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                error={!!error}
                sx={{ width: '22rem' }}
                InputProps={{
                  sx: { bgcolor: theme.palette.common.white },
                }}
                multiline
                rows={2.5}
                placeholder="その他情報を入力"
                helperText={error?.message}
              />
            )}
          />
        </FormControlItem>
        <FormControlItem
          title="勤務時間"
          required
          mixed={mixedValues.time}
          checkName="checkTime"
          control={control}
          watch={watch}
        >
          <RowBox alignItems="baseline" gap={2}>
            <Controller
              name="startTime"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TimeSelect
                  {...field}
                  startTime="00:00"
                  endTime="23:55"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
            <Typography>～</Typography>
            <Controller
              name="endTime"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TimeSelect
                  {...field}
                  startTime="00:00"
                  endTime="96:55"
                  error={!!error}
                  helperText={error?.message}
                />
              )}
            />
          </RowBox>
          <ColBox my={1} width="15rem">
            <Controller
              name="possibleTimeAdjust"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  {...field}
                  checked={field.value}
                  control={<Checkbox />}
                  label="時間調整可"
                  sx={{ my: -0.5 }}
                />
              )}
            />
            <Controller
              name="possibleOvertime"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  {...field}
                  checked={field.value}
                  control={<Checkbox />}
                  label="残業あり"
                  sx={{ my: -0.5 }}
                />
              )}
            />
            <Controller
              name="overtimeAllowance"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  {...field}
                  checked={field.value}
                  disabled={!watch('possibleOvertime')}
                  control={<Checkbox />}
                  label="残業代の追加あり"
                  sx={{ my: -0.5 }}
                />
              )}
            />
          </ColBox>
        </FormControlItem>
        <FormControlItem
          title="休憩時間"
          required
          mixed={mixedValues.breakTime}
          checkName="checkBreakTime"
          control={control}
          watch={watch}
        >
          <Controller
            name="breakTimeNoParticular"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormControlLabel
                {...field}
                checked={field.value}
                control={<Checkbox />}
                label="随時・なし"
                sx={{ my: -0.5 }}
              />
            )}
          />
          <RowBox alignItems="baseline" gap={2}>
            <Controller
              name="breakTime"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <NumberField
                  {...field}
                  ref={undefined}
                  inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                  disabled={watch('breakTimeNoParticular') ?? false}
                  error={!!error}
                  sx={{ width: '10rem' }}
                  InputProps={{
                    sx: { bgcolor: theme.palette.common.white },
                  }}
                  placeholder="休憩時間を入力"
                  helperText={error?.message}
                />
              )}
            />
            <Typography>分</Typography>
          </RowBox>
        </FormControlItem>
        <FormControlItem
          title="給与"
          required
          mixed={mixedValues.price}
          checkName="checkPrice"
          control={control}
          watch={watch}
        >
          <RowBox alignItems="baseline" gap={2}>
            <Controller
              name="variantsSku"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  sx={{ width: '8rem' }}
                  inputProps={{
                    sx: { bgcolor: theme.palette.common.white },
                  }}
                  displayEmpty
                  renderValue={(selected: string) => {
                    if (selected) {
                      return productVariantsSkuList.find(
                        (a) => a.id === selected
                      )?.name;
                    }
                    return (
                      <span
                        style={{
                          color: theme.customPalette.lightGray,
                        }}
                      >
                        給与を選択
                      </span>
                    );
                  }}
                >
                  <MenuItem value="" disabled>
                    給与を選択
                  </MenuItem>
                  {getMenuItems(productVariantsSkuList)}
                </Select>
              )}
            />
            <Controller
              name="variantsPriceAmount"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <NumberField
                  {...field}
                  ref={undefined}
                  inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                  error={!!error}
                  sx={{ width: '10rem' }}
                  InputProps={{
                    sx: { bgcolor: theme.palette.common.white },
                  }}
                  placeholder="金額を入力"
                  helperText={error?.message}
                />
              )}
            />
            <Typography>円</Typography>
          </RowBox>
        </FormControlItem>
        <FormControlItem
          title="インセンティブ（ある場合は具体的な条件をご記載ください）"
          mixed={mixedValues.variantsDescription}
          checkName="checkVariantsDescription"
          control={control}
          watch={watch}
        >
          <Controller
            name="variantsDescription"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                ref={undefined}
                inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                error={!!error}
                sx={{ width: '22rem' }}
                InputProps={{
                  sx: { bgcolor: theme.palette.common.white },
                }}
                multiline
                rows={2.5}
                placeholder="入院1件5000円、救急車1件5000円"
                helperText={error?.message}
              />
            )}
          />
        </FormControlItem>
        <FormControlItem
          title="交通費"
          required
          mixed={mixedValues.commuting}
          checkName="checkCommuting"
          control={control}
          watch={watch}
        >
          <RowBox alignItems="baseline" gap={2}>
            <Controller
              name="commuting"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  sx={{ width: '15rem' }}
                  inputProps={{
                    sx: { bgcolor: theme.palette.common.white },
                  }}
                  displayEmpty
                  renderValue={(selected: string) => {
                    if (selected) {
                      return productCommutingList.find((a) => a.id === selected)
                        ?.name;
                    }
                    return (
                      <span
                        style={{
                          color: theme.customPalette.lightGray,
                        }}
                      >
                        交通費を選択
                      </span>
                    );
                  }}
                >
                  <MenuItem value="" disabled>
                    交通費を選択
                  </MenuItem>
                  {getMenuItems(productCommutingList)}
                </Select>
              )}
            />
            {(watch('commuting') === 'UpperLimit' ||
              watch('commuting') === 'FixedFee' ||
              watch('commuting') === 'Include') && (
              <>
                <Controller
                  name="commutingCost"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <NumberField
                      {...field}
                      ref={undefined}
                      inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                      error={!!error}
                      sx={{
                        width: '10rem',
                      }}
                      InputProps={{
                        sx: { bgcolor: theme.palette.common.white },
                      }}
                      placeholder="金額を入力"
                      helperText={error?.message}
                    />
                  )}
                />
                <Typography>円</Typography>
              </>
            )}
          </RowBox>
        </FormControlItem>
        <FormControlItem
          title="募集人数"
          required
          mixed={mixedValues.count}
          checkName="checkCount"
          control={control}
          watch={watch}
        >
          <Controller
            name="count"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <NumberField
                {...field}
                ref={undefined}
                inputRef={field.ref} // NOTE:バリデーション時にフォーカスされる
                sx={{ width: '10rem' }}
                InputProps={{
                  sx: { bgcolor: theme.palette.common.white },
                }}
                placeholder="募集人数を入力"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        </FormControlItem>
      </ColBox>
    </ProductLayout>
  );
}
