import { ProductTypeKey } from '@app/types/catalog';
import { User } from '@app/types/user';

export interface OrderStats {
  count: number;
  organization: string;
  productId: string;
}

export interface OrderResponse {
  '@nextLink': string;
  count: number;
  total: number;
  value: Array<Order>;
}

export interface AttachmentFiles {
  id: string;
  isPublic: boolean;
  ownerId: string;
  ownerType: string;
  type: string;
  url: string;
}
export interface Order {
  cancelReason: SupportedCancelReason | null;
  canceledAt: string;
  cart: string;
  checkout: string;
  closedAt: string;
  createdAt: string;
  currency: string;
  customFields?: OrderCustomFields;
  customer: OrderCustomer;
  gender: string;
  id: string;
  lineItems: Array<OrderLineItem>;
  name: string;
  organization: string;
  status: SupportedOrderStatus;
  subTotalPrice: number;
  totalTax: number;
  updatedAt: string;
}

export interface OrderCustomFields {
  cancelMessage?: string;
  cancelRequestAt?: string;
  cancelType?: OrderCancelTypeKey;
  orderAfterFiles?: AttachmentFiles[];
  orderMatchedFiles?: AttachmentFiles[];
  pendingNoAlertFlag?: boolean;
  productDay?: string;
  productType?: ProductTypeKey;
  workResult?: string;
  workResultAt?: string;
}

export interface OrderLocalized {
  attachment?: {
    orderAfterFiles?: AttachmentFiles[];
    orderMatchedFiles?: AttachmentFiles[];
  };
  cancelReason: string;
  createdAt: string;
  currency: string;
  customFields?: OrderCustomFields;
  customer: OrderCustomer;
  gender: string;
  id: string;
  lineItem: OrderLineItem;
  localizedStatus: string;
  name: string;
  organization: string;
  status: SupportedOrderStatus;
  subTotalPrice: number;
  totalTax: number;
}

export interface OrderLineItem {
  price: OrderLineItemPrice;
  product: string;
  productName: string;
  quantity: number;
  sku: string;
  variant: string;
  variantTitle: string;
}

export interface OrderLineItemPrice {
  amount: number;
  taxable: boolean;
}

export interface OrderCustomer {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  age: string;
  department: string;
  email: string;
  name: string;
  nameKana: string;
  phone: string;
  prefecture: string;
  preferredContactMethod: string;
  preferredTimeToContact: string;
  rate: string;
  user: User;
}

export interface OrderUpdate {
  cancelReason?: SupportedCancelReason | null;
  canceledAt?: string;
  customFields?: OrderCustomFields;
  status?: SupportedOrderStatus;
}

export const OrderStatus = {
  ACCEPTED: 'ACCEPTED',
  CANCELED: 'CANCELED',
  CLOSED: 'CLOSED',
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
} as const;
export type SupportedOrderStatus =
  (typeof OrderStatus)[keyof typeof OrderStatus];

export const CancelReason = {
  CUSTOMER: 'customer',
  DECLINED: 'declined',
  INVENTORY: 'inventory',
  OTHER: 'other',
} as const;
export type SupportedCancelReason =
  (typeof CancelReason)[keyof typeof CancelReason];

export const OrderCancelType = {
  DEMAND_STATUS_ACCEPTED: 2, //ドクターがACCEPTEDで辞退
  DEMAND_STATUS_PENDING: 1, //ドクターがPENDINGで辞退
  NOT_CANCEL: 0,
  SUPPLY_STATUS_ACCEPTED: 3, //クリニックがACCEPTEDでキャンセル
  SUPPLY_STATUS_NOT_ACCEPTED: 99, //クリニックによる不採用
  TIME_UP: 98, //時間切れによる自動不採用
} as const;
export type OrderCancelTypeKey =
  (typeof OrderCancelType)[keyof typeof OrderCancelType];
