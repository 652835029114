import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Modal,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';

import { getAttributes } from '@app/adapter/catalog-service';
import { Attribute } from '@app/types/catalog';

export const EquipmentCheckModal = ({
  onUpdate,
  selectedItems = [],
}: {
  onUpdate: (equipments: Array<string>) => void;
  selectedItems?: Array<string>;
}) => {
  const [attributes, setAttributes] = useState<Attribute[]>([]);

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const response = await getAttributes({
          filter: {
            groupName: 'equipments',
          },
          order: 'createdAt_desc',
        });
        setAttributes(response.data.value || []);
      } catch (error) {
        console.error('Error fetching attributes:', error);
      }
    };

    void fetchAttributes();
  }, []);

  const checkboxKeys = attributes.map((attr) => attr.name);
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] =
    useState<Array<string>>(selectedItems);

  const handleClickOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setCheckedItems((prev) => {
      const { checked: isChecked, value } = event.target;
      return isChecked ? [...prev, value] : prev.filter((key) => key !== value);
    });

  const handleCancel = () => {
    setCheckedItems(selectedItems); // モーダル閉じるときに状態戻す
    handleClose();
  };
  const handleConfirm = () => {
    onUpdate(checkedItems);
    handleClose();
  };

  const handleRemoveItem = (key: string) => {
    const nextSelected = selectedItems.filter((item) => item !== key);
    setCheckedItems(nextSelected);
    onUpdate(nextSelected);
  };

  const modalStyle = {
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: '12px',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    height: '386px',
    left: '50%',
    p: '40px 32px',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '616px',
  };

  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {checkboxKeys.map(
          (key) =>
            selectedItems.includes(key) && (
              <div
                style={{
                  alignItems: 'center',
                  border: `1px solid ${theme.customPalette.borderColor}`,
                  borderRadius: '50px',
                  display: 'inline-flex',
                  flexDirection: 'row',
                  margin: '5px',
                  paddingLeft: '15px',
                }}
                key={key}
              >
                {key}
                <IconButton onClick={() => handleRemoveItem(key)}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            )
        )}
      </div>

      <Button
        variant="contained"
        onClick={handleClickOpen}
        size="small"
        style={{
          backgroundColor: 'white',
          border: `1px solid ${theme.customPalette.borderColor}`,
          boxShadow: 'none',
          color: theme.palette.text.primary,
          fontSize: '0.9rem',
          marginTop: '10px',
          padding: '5px 15px',
        }}
      >
        設備を選択
      </Button>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography sx={{ fontSize: '1.2rem', fontWeight: 600 }}>
            検査設備を選択
          </Typography>
          <Grid container direction="row" sx={{ pt: 4 }}>
            {checkboxKeys.map((key: string) => (
              <Grid item key={key}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedItems.includes(key)}
                      onChange={handleCheckboxChange}
                      value={key}
                    />
                  }
                  label={key}
                />
              </Grid>
            ))}
          </Grid>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 'auto',
            }}
          >
            <Button
              variant="outlined"
              onClick={handleCancel}
              color="secondary"
              sx={{
                fontWeight: 300,
                marginRight: 2,
                padding: '5px 15px',
              }}
            >
              キャンセル
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirm}
              sx={{
                pl: 5,
                pr: 5,
              }}
            >
              決定
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
