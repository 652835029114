import { ReactElement, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { userAuthInfoSelector } from '@app/domain/app';

export function LineCallback(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const setUserAuthInfo = useSetRecoilState(userAuthInfoSelector);

  useEffect(() => {
    if (location.pathname !== '/line/callback') {
      return;
    }

    const hash = location.hash.slice(1);
    const params = new URLSearchParams(hash);
    const id = params.get('userId');
    const token = params.get('token');
    if (!id || !token) {
      // TODO: Do we need to display error messages?
      navigate('/login#line=failed');
      return;
    }

    // TODO: UPDATE THIS WHEN LINE IS INTEGRATED
    // setUserAuthInfo({ id, token });
    navigate('/');
  }, [setUserAuthInfo, navigate, location]);

  return <></>;
}
