import { AxiosPromise } from 'axios';

import { REVIEW_SERVICE, axiosInstance } from '@app/adapter/axios';
import { ReviewInput, ReviewResponse, ReviewUpdate } from '@app/types/review';
import { getError } from '@app/utils/error';

export function getUserReviews(
  userId: string,
  options?: {
    order?: string;
    pageNumber?: number;
    pageSize?: number;
  }
): AxiosPromise<ReviewResponse> {
  const urlParams = [] as string[][];

  if (options?.pageSize !== undefined && options?.pageNumber !== undefined) {
    urlParams.push([`$top`, options.pageSize.toString()]);
    urlParams.push([
      `$skip`,
      (options.pageNumber * options.pageSize).toString(),
    ]);
  }

  if (options?.order) {
    urlParams.push(['$orderBy', options?.order]);
  }

  return axiosInstance
    .get(
      `${REVIEW_SERVICE}/users/${userId}/reviews-by-user?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .catch((error) => {
      throw getError(error);
    });
}

export function getUserReviewsByOrg(
  userId: string,
  options?: {
    filter?: {
      id?: string;
    };
    order?: string;
    pageNumber?: number;
    pageSize?: number;
  }
): AxiosPromise<ReviewResponse> {
  const urlParams = [] as string[][];

  const filterParams = [];
  if (options?.filter?.id) {
    filterParams.push(`id eq '${options.filter.id}'`);
  }

  if (filterParams.length > 0) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }

  if (options?.pageSize !== undefined && options?.pageNumber !== undefined) {
    urlParams.push([`$top`, options.pageSize.toString()]);
    urlParams.push([
      `$skip`,
      (options.pageNumber * options.pageSize).toString(),
    ]);
  }

  if (options?.order) {
    urlParams.push(['$orderBy', options?.order]);
  }

  return axiosInstance
    .get(
      `${REVIEW_SERVICE}/users/${userId}/reviews-by-org?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .catch((error) => {
      throw getError(error);
    });
}

export async function createReviewByOrg(userId: string, data: ReviewInput) {
  try {
    return await axiosInstance.post<{ id: string }>(
      `${REVIEW_SERVICE}/users/${userId}/reviews-by-org`,
      data
    );
  } catch (error) {
    throw getError(error);
  }
}

export async function updateReviewByOrg(
  organizationId: string,
  reviewId: string,
  data: ReviewUpdate
) {
  try {
    return await axiosInstance.patch<{ id: string }>(
      `${REVIEW_SERVICE}/orgs/${organizationId}/reviews-by-org/${reviewId}`,
      data
    );
  } catch (error) {
    throw getError(error);
  }
}
