import { Box, Button, Paper, Typography, Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { getUserInfoById } from '@app/adapter/user-service';
import { PageTitle } from '@app/components/Shared/PageTitle';
import {
  loggedInUserState,
  userAuthInfoSelector,
  snackbarOpenState,
} from '@app/domain/app';
import { theme } from '@app/theme';
import { UserInfoResponse } from '@app/types/user';

export function AccountSetting() {
  const navigate = useNavigate();
  const loggedInUser = useRecoilValue(loggedInUserState);
  const setUserAuthInfo = useSetRecoilState(userAuthInfoSelector);
  const isSnackbarOpen = useRecoilValue(snackbarOpenState);
  const [userInfo, setUserInfo] = useState<UserInfoResponse | null>(null);

  useEffect(() => {
    if (loggedInUser) {
      getUserInfoById(loggedInUser.id)
        .then((data) => setUserInfo(data))
        .catch((error) => console.error(error));
    }
  }, [loggedInUser]);

  const handleClose = useCallback(() => {
    setUserAuthInfo({
      accessToken: '',
      fingerprint: '',
      id: '',
    });
    navigate('/login');
  }, [navigate, setUserAuthInfo]);

  return (
    <>
      <Box sx={{ mb: 3, mt: isSnackbarOpen ? 7 : 1 }}>
        <Grid container justifyContent="space-between">
          <Grid item xs>
            <PageTitle title="アカウント設定" />
          </Grid>

          <Grid item xs={2} container justifyContent="flex-end">
            <Button
              variant="text"
              disableRipple
              sx={{
                color: theme.palette.text.primary,
                fontWeight: 400,
                textDecoration: 'underline',
              }}
              onClick={handleClose}
            >
              ログアウトする
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Paper sx={{ p: 4 }}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography
                color="textPrimary"
                variant="subtitle2"
                fontWeight={600}
                sx={{ mb: 1, textAlign: 'left' }}
              >
                名前
              </Typography>
              {userInfo?.customFields?.familyName}{' '}
              {userInfo?.customFields?.firstName}
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                sx={{ mt: 1, pl: 4, pr: 4 }}
                variant="outlined"
                size="small"
                onClick={() => navigate('/account-setting/name-change')}
              >
                変更
              </Button>
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between" sx={{ mt: 3 }}>
            <Grid item>
              <Typography
                color="textPrimary"
                variant="subtitle2"
                fontWeight={600}
                sx={{ mb: 1, textAlign: 'left' }}
              >
                メールアドレス
              </Typography>
              {userInfo?.email}
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                sx={{ mt: 1, pl: 4, pr: 4 }}
                variant="outlined"
                size="small"
                onClick={() => navigate('/account-setting/email-change')}
              >
                変更
              </Button>
            </Grid>
          </Grid>

          <Grid container justifyContent="space-between" sx={{ mt: 3 }}>
            <Grid item>
              <Typography
                color="textPrimary"
                variant="subtitle2"
                fontWeight={600}
                sx={{ mb: 1, textAlign: 'left' }}
              >
                パスワード
              </Typography>
              <Typography>********</Typography>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                sx={{ pl: 4, pr: 4 }}
                variant="outlined"
                size="small"
                onClick={() => navigate('/account-setting/password-change')}
              >
                変更
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
