import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Box,
  CircularProgress,
  Grid,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  styled,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { z } from 'zod';

import { getSingleProduct } from '@app/adapter/catalog-service';
import { updateOrderStatus } from '@app/adapter/order-service';
import {
  createReviewByOrg,
  getUserReviewsByOrg,
  updateReviewByOrg,
} from '@app/adapter/review-service';
import { getUser } from '@app/adapter/user-service';
import { StarsRating } from '@app/components/Shared/StarsRating';
import { snackbarOpenState, snackbarTextState } from '@app/domain/app';
import { Product } from '@app/types/catalog';
import { Review, ReviewInput } from '@app/types/review';
import { User } from '@app/types/user';
import { convertNewLine } from '@app/utils';
import { getProductDayWeekTimeTitle } from '@app/utils/catalog';
import { getMenuItems } from '@app/utils/components';

const ColBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const RowBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

export const reviewFormSchema = z.object({
  comment: z.string().nonempty('コメントを入力してください'),
  rate: z.number(),
  rateAttendance: z.number(),
  rateCommunication: z.number(),
  rateFollowRule: z.number(),
  rateQuality: z.number(),
  rateReception: z.number(),
  rateSpeed: z.number(),
  reviewBy: z.string().nonempty('あなたの立場を選択してください'),
});

export type ReviewFormValue = z.infer<typeof reviewFormSchema>;

export interface ReviewFormProps {
  isAdmin?: boolean;
  onClose: (result: 'confirm' | 'cancel' | 'decline', review?: Review) => void;
  orderId?: string;
  productId?: string;
  review?: Review;
  userId?: string;
}

export function ReviewForm({
  onClose,
  userId,
  productId,
  review,
  orderId,
  isAdmin,
}: ReviewFormProps) {
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isLoadingButtonDecline, setIsLoadingButtonDecline] = useState(false);
  const theme = useTheme();
  const [user, setUser] = useState(undefined as User | undefined);
  const [product, setProduct] = useState(undefined as Product | undefined);
  const [isLoadData, setIsLoadData] = useState(false);
  const targetUserId = userId || review?.targetId;
  const targetProductId = productId || review?.customFields?.productId;

  const { control, handleSubmit, reset, formState } = useForm<ReviewFormValue>({
    defaultValues: {
      comment: '',
      rate: 3,
      rateAttendance: 3,
      rateCommunication: 3,
      rateFollowRule: 3,
      rateQuality: 3,
      rateReception: 3,
      rateSpeed: 3,
      reviewBy: '',
    },
    mode: 'onChange',
    resolver: zodResolver(reviewFormSchema),
  });

  const loadUser = useCallback(async () => {
    try {
      if (!targetUserId) {
        return;
      }
      const result = await getUser(targetUserId);
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      setUser(result.data);
    } catch (error) {
      setSnackbarText(
        `医師の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    } finally {
      setIsLoadData(true);
    }
  }, [targetUserId, setSnackbarText, setSnackbarOpen]);

  useEffect(() => {
    void loadUser();
  }, [loadUser]);

  const loadProduct = useCallback(async () => {
    try {
      if (!targetProductId) {
        return;
      }
      const result = await getSingleProduct(targetProductId);
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      const product = result.data;
      setProduct(product);
    } catch (error) {
      setSnackbarText(
        `求人の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [targetProductId, setSnackbarText, setSnackbarOpen]);

  useEffect(() => {
    void loadProduct();
  }, [loadProduct]);

  useEffect(() => {
    reset({
      comment: convertNewLine(review?.comment ?? ''),
      rate: review?.rate ?? 3,
      rateAttendance: review?.subRates?.attendance ?? 3,
      rateCommunication: review?.subRates?.communication ?? 3,
      rateFollowRule: review?.subRates?.followRule ?? 3,
      rateQuality: review?.subRates?.quality ?? 3,
      rateReception: review?.subRates?.reception ?? 3,
      rateSpeed: review?.subRates?.speed ?? 3,
      reviewBy: review?.customFields?.reviewBy ?? '',
    });
  }, [reset, review]);

  const handleClickDecline = useCallback(async () => {
    try {
      if (!orderId) {
        throw new Error('応募IDが未設定です');
      }
      if (!product) {
        throw new Error('求人が不明です');
      }
      setIsLoadingButtonDecline(true);
      await updateOrderStatus(product.organizationId, orderId, 'CLOSED');
      onClose('decline');
      reset();
    } catch (error) {
      setSnackbarText(
        `応募の更新に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    } finally {
      setIsLoadingButtonDecline(false);
    }
  }, [orderId, product, onClose, reset, setSnackbarText, setSnackbarOpen]);

  const handleClickCancel = useCallback(() => {
    onClose('cancel');
    reset();
  }, [reset, onClose]);

  const handleClickConfirm: Parameters<typeof handleSubmit>[0] = useCallback(
    async (formValue) => {
      try {
        if (!user) {
          throw new Error('ユーザーが不明です');
        }
        if (!product) {
          throw new Error('求人が不明です');
        }

        setIsLoadingButton(true);
        const newReview = {
          comment: formValue.comment,
          customFields: {
            productId: product.id,
            reviewBy: formValue.reviewBy,
          },
          isAnonymous: false,
          rate: formValue.rate,
          reviewedBy: product.organizationId,
          source: 'organization',
          subRates: {
            attendance: formValue.rateAttendance,
            communication: formValue.rateCommunication,
            followRule: formValue.rateFollowRule,
            quality: formValue.rateQuality,
            reception: formValue.rateReception,
            speed: formValue.rateSpeed,
          },
          target: 'user',
        } as ReviewInput;
        if (review) {
          const { isAnonymous, reviewedBy, source, target, ...updateReview } = // eslint-disable-line
            newReview;
          await updateReviewByOrg(
            product.organizationId,
            review.id,
            updateReview
          );
          onClose('confirm', {
            ...review,
            ...(newReview as ReviewInput),
          });
        } else {
          const result = await createReviewByOrg(user.id, newReview);
          const resultReviews = await getUserReviewsByOrg(user.id, {
            filter: { id: result.data.id },
            pageSize: 1,
          });
          onClose('confirm', resultReviews.data.value[0]);
          if (orderId) {
            await updateOrderStatus(product.organizationId, orderId, 'CLOSED');
          }
        }
        reset();
      } catch (error) {
        setSnackbarText(
          `評価の登録に失敗しました, ${
            error instanceof Error ? error.message : error
          }`
        );
        setSnackbarOpen(true);
      } finally {
        setIsLoadingButton(false);
      }
    },
    [
      user,
      product,
      review,
      orderId,
      onClose,
      reset,
      setSnackbarText,
      setSnackbarOpen,
    ]
  );

  if (!user || !isLoadData) {
    return (
      <form>
        <DialogTitle sx={{ minWidth: 450 }} fontWeight={600}>
          評価
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', minWidth: 450 }}>
          {!isLoadData ? (
            <CircularProgress sx={{ m: 'auto' }} />
          ) : !user ? (
            <Typography>ID:{userId}の医師を取得できませんでした</Typography>
          ) : undefined}
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ width: '10rem' }}
            onClick={handleClickCancel}
          >
            キャンセル
          </Button>
        </DialogActions>
      </form>
    );
  }

  return (
    <form>
      <DialogTitle sx={{ minWidth: 450 }} fontWeight={600}>
        {user.customFields.familyName}
        {user.customFields.firstName}さんの評価
      </DialogTitle>
      <DialogContent sx={{ minWidth: 450 }}>
        <ColBox gap={4}>
          <ColBox gap={1}>
            <Typography>勤務日時</Typography>
            <Link
              to={
                !isAdmin
                  ? `/products/${product?.id}`
                  : `/org/${product?.organization.id}/products/${product?.id}`
              }
              style={{ color: theme.palette.text.primary }}
            >
              <Typography>
                {getProductDayWeekTimeTitle(product)} /{' '}
                {product?.organization.name}
              </Typography>
            </Link>
          </ColBox>
          <Divider />
          <ColBox>
            {!review && !isAdmin && (
              <>
                <Typography>
                  上記のドクター・勤務のご評価をお願い致します。
                </Typography>
                <Typography>
                  評価内容は、ドクターご自身が閲覧することができますが、どの医療機関による評価かを特定することはできませんので、率直なご意見をお願い致します。
                </Typography>
              </>
            )}
            <Typography>
              <Typography component="span" color="error">
                *
              </Typography>
              は必須事項です
            </Typography>
          </ColBox>
          <Grid container rowSpacing={3}>
            <Grid item sm={6}>
              <Typography fontSize="1.1rem">総合評価</Typography>
              <Controller
                name="rate"
                control={control}
                render={({ field }) => (
                  <StarsRating
                    rate={field.value}
                    onChange={field.onChange}
                    input
                    iconProps={{ sx: { fontSize: '3rem' } }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6}></Grid>
            <Grid item sm={6}>
              <Typography fontSize="1.1rem">医療の質</Typography>
              <Controller
                name="rateQuality"
                control={control}
                render={({ field }) => (
                  <StarsRating
                    rate={field.value}
                    onChange={field.onChange}
                    input
                    iconProps={{ sx: { fontSize: '3rem' } }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6}>
              <Typography fontSize="1.1rem">診察の早さ</Typography>
              <Controller
                name="rateSpeed"
                control={control}
                render={({ field }) => (
                  <StarsRating
                    rate={field.value}
                    onChange={field.onChange}
                    input
                    iconProps={{ sx: { fontSize: '3rem' } }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6}>
              <Typography fontSize="1.1rem">接遇・清潔感</Typography>
              <Controller
                name="rateReception"
                control={control}
                render={({ field }) => (
                  <StarsRating
                    rate={field.value}
                    onChange={field.onChange}
                    input
                    iconProps={{ sx: { fontSize: '3rem' } }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6}>
              <Typography fontSize="1.1rem">
                職員間コミュニケーション
              </Typography>
              <Controller
                name="rateCommunication"
                control={control}
                render={({ field }) => (
                  <StarsRating
                    rate={field.value}
                    onChange={field.onChange}
                    input
                    iconProps={{ sx: { fontSize: '3rem' } }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6}>
              <Typography fontSize="1.1rem">クリニック方針の遵守</Typography>
              <Controller
                name="rateFollowRule"
                control={control}
                render={({ field }) => (
                  <StarsRating
                    rate={field.value}
                    onChange={field.onChange}
                    input
                    iconProps={{ sx: { fontSize: '3rem' } }}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6}>
              <Typography fontSize="1.1rem">勤怠（遅刻・遅延）</Typography>
              <Controller
                name="rateAttendance"
                control={control}
                render={({ field }) => (
                  <StarsRating
                    rate={field.value}
                    onChange={field.onChange}
                    input
                    iconProps={{ sx: { fontSize: '3rem' } }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <ColBox>
            <Typography>
              コメント
              <Typography component="span" color="error">
                *
              </Typography>
            </Typography>

            <Controller
              name="comment"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  multiline
                  rows={5}
                  placeholder="コメントを入力してください"
                  helperText={error?.message}
                />
              )}
            />
          </ColBox>
          <RowBox alignItems="baseline" gap={1}>
            <Typography>
              あなたの立場
              <Typography component="span" color="error">
                *
              </Typography>
            </Typography>
            <Controller
              name="reviewBy"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Select
                    {...field}
                    error={!!error}
                    sx={{ width: '14rem' }}
                    size="small"
                    displayEmpty
                    renderValue={(value: string) => {
                      if (value) {
                        return value;
                      }
                      return (
                        <span
                          style={{
                            color: theme.customPalette.lightGray,
                          }}
                        >
                          選択してください
                        </span>
                      );
                    }}
                  >
                    {getMenuItems([
                      '院長',
                      '同僚医師',
                      '看護師',
                      '医療事務',
                      '他',
                    ])}
                  </Select>
                  {error ? (
                    <FormHelperText error sx={{ ml: 2 }}>
                      {error.message}
                    </FormHelperText>
                  ) : undefined}
                </>
              )}
            />
          </RowBox>
        </ColBox>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        {!review && (
          <LoadingButton
            loading={isLoadingButtonDecline}
            variant="text"
            color="primary"
            sx={{
              color: theme.palette.text.primary,
              fontWeight: 1,
              textDecoration: 'underline',
              width: '10rem',
            }}
            onClick={handleClickDecline}
          >
            評価を辞退する
          </LoadingButton>
        )}
        <Button
          variant="outlined"
          color="secondary"
          sx={{ width: '10rem' }}
          onClick={handleClickCancel}
        >
          キャンセル
        </Button>
        <LoadingButton
          loading={isLoadingButton}
          variant="contained"
          sx={{ width: '10rem' }}
          onClick={handleSubmit(handleClickConfirm)}
          disabled={!formState.isValid}
        >
          送信
        </LoadingButton>
      </DialogActions>
    </form>
  );
}
