import { Box, Button } from '@mui/material';
import { Stack } from '@mui/system';
import {
  startOfMonth,
  endOfMonth,
  startOfDay,
  format as dateFnsFormat,
} from 'date-fns';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { getProducts } from '@app/adapter/catalog-service';
import { getOrdersListWithFilter } from '@app/adapter/order-service';
import { getPublishedAds } from '@app/adapter/user-service';
import { Calendar } from '@app/components/Home/Calendar';
import { CalendarItemProps } from '@app/components/Home/CalendarItem';
import { OrderInfo, OrderInfoGroup } from '@app/components/Home/OrderInfo';
import { OrderNotificationModal } from '@app/components/Home/OrderNotificationModal';
import { Advertising } from '@app/components/Shared/Advertising';
import { MonthSelect } from '@app/components/Shared/MonthSelect';
import { snackbarOpenState, snackbarTextState } from '@app/domain/app';
import { organizationSelector } from '@app/domain/organization';
import { Ad } from '@app/types/ad';
import { Product, ProductPublicationStatus } from '@app/types/catalog';
import { Order, OrderStatus } from '@app/types/order';
import { findByGroupOrDefault } from '@app/utils/ad';
import { isError } from '@app/utils/error';

export function Home() {
  const today = new Date();
  const startMonth = dateFnsFormat(startOfMonth(today), 'yyyy/MM');
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const organizationState = useRecoilValue(organizationSelector);
  const [monthProductList, setMonthProductList] = useState<
    Product[] | undefined
  >(undefined);
  const [monthOrderList, setMonthOrderList] = useState<Order[] | undefined>(
    undefined
  );
  const [selectMonth, setSelectMonth] = useState(startMonth);
  const [dayList, setDayList] = useState<CalendarItemProps[]>([]);
  const [selectDate, setSelectDate] = useState<string>(
    dateFnsFormat(today, 'yyyy/MM/dd')
  );
  const [orderInfoList, setOrderInfoList] = useState<OrderInfoGroup[]>([]);
  const [orderInfoDate, setOrderInfoDate] = useState('');
  const [isLoadingCalendar, setIsLoadingCalendar] = useState(false);
  const [calendarErrorMessage, setCalendarErrorMessage] = useState('');
  // データ取得に使用するタイマー（無駄な検索が走らないための対策用）
  const loadTimerRef = useRef({
    ms: 0,
    timeoutId: undefined as NodeJS.Timeout | undefined,
  });

  const loadMonthProductList = useCallback(
    async (month: string): Promise<[Product[], string]> => {
      try {
        setMonthProductList(undefined);
        // NOTE:300件までを上限に取得
        const startDate = new Date(month + '/1');
        const endDate = endOfMonth(startDate);
        const list: Product[] = [];
        const pageSize = 100;
        for (let pageNumber = 0; pageNumber < 3; pageNumber++) {
          const result = await getProducts(organizationState.id, {
            dateRange: {
              end: endDate.toISOString(),
              start: startDate.toISOString(),
            },
            order: 'customFields.day,customFields.startTime,',
            pageNumber,
            pageSize,
            statuses: [
              ProductPublicationStatus.ACTIVE,
              ProductPublicationStatus.ARCHIVED,
            ],
          });
          if (result.status !== 200) {
            throw new Error(`${result.status} ${result.statusText}`);
          }
          if (result.data.total > 300) {
            return [[], '求人件数が300件を超えているため表示できません'];
          }
          result.data.value.forEach((v) => list.push(v));
          if (result.data.total <= (pageNumber + 1) * pageSize) {
            break;
          }
        }
        setMonthProductList(list);
        return [list, ''];
      } catch (error: unknown) {
        throw new Error(
          `求人の取得に失敗しました` + (isError(error) ? error.message : '')
        );
      }
    },
    [organizationState.id]
  );

  const loadMonthOrderList = useCallback(
    async (month: string): Promise<[Order[], string]> => {
      try {
        setMonthOrderList(undefined);
        // NOTE:300件までを上限に取得
        const startDate = new Date(month + '/1');
        const endDate = endOfMonth(startDate);
        const list: Order[] = [];
        const pageSize = 100;
        for (let pageNumber = 0; pageNumber < 3; pageNumber++) {
          const result = await getOrdersListWithFilter(organizationState.id, {
            dateRange: {
              end: endDate.toISOString(),
              start: startDate.toISOString(),
            },
            order: 'customFields.productDay,createdAt',
            pageNumber,
            pageSize,
          });
          if (result.status !== 200) {
            throw new Error(`${result.status} ${result.statusText}`);
          }
          if (result.data.total > 300) {
            return [[], '応募件数が300件を超えているため表示できません'];
          }
          result.data.value.forEach((v) => list.push(v));
          if (result.data.total <= (pageNumber + 1) * pageSize) {
            break;
          }
        }
        setMonthOrderList(list);
        return [list, ''];
      } catch (error: unknown) {
        throw new Error(
          `応募の取得に失敗しました` + (isError(error) ? error.message : '')
        );
      }
    },
    [organizationState.id]
  );

  const loadCalendar = useCallback(
    async (month: string) => {
      try {
        setIsLoadingCalendar(true);
        setCalendarErrorMessage('');
        setDayList([]);
        const [productList, productError] = await loadMonthProductList(month);
        if (productError) {
          setCalendarErrorMessage(productError);
          return;
        }
        const [orderList, orderError] = await loadMonthOrderList(month);
        if (orderError) {
          setCalendarErrorMessage(orderError);
          return;
        }
        const list: CalendarItemProps[] = [];
        // AIPで取得したデータをCalendarコンポーネントに渡せる形に成型する
        productList.forEach((product) => {
          const date = new Date(product.customFields.day);
          const item = list.find(
            (e) =>
              e.date &&
              startOfDay(new Date(e.date)).getTime() ===
                startOfDay(date).getTime()
          );
          if (item) {
            return;
          }
          list.push({
            date: dateFnsFormat(date, 'yyyy/MM/dd'),
            isProduct: true,
          });
        });
        orderList.forEach((order) => {
          const product = productList.find(
            (p) => p.id === order.lineItems[0]?.product
          );
          if (!product) {
            return;
          }
          const item = list.find(
            (e) =>
              e.date &&
              startOfDay(new Date(e.date)).getTime() ===
                startOfDay(new Date(product.customFields.day)).getTime()
          );
          if (!item) {
            return;
          }
          if (order.status === 'PENDING') {
            item.mailCount = (item.mailCount ?? 0) + 1;
            return;
          }
          if (
            order.status === 'ACCEPTED' ||
            order.status === 'PROCESSING' ||
            order.status === 'CLOSED'
          ) {
            if (!item.orderList) {
              item.orderList = [];
            }
            item.orderList?.push({
              familyName: order.customer.user.customFields?.familyName,
              time: product.customFields.startTime,
            });
          }
        });
        setDayList(list);
      } catch (error: unknown) {
        if (isError(error)) {
          setSnackbarText(error.message);
        } else {
          setSnackbarText(`取得が失敗しました`);
        }
        setSnackbarOpen(true);
      } finally {
        setIsLoadingCalendar(false);
      }
    },
    [setSnackbarText, setSnackbarOpen, loadMonthProductList, loadMonthOrderList]
  );

  const loadOrderInfo = useCallback(
    async (date: string) => {
      // NOTE:別の月が選択された時クリアされないよう日付が読み込み済みのものと変わらなければ何もしない
      if (date === orderInfoDate) {
        return;
      }
      setOrderInfoList([]);
      if (!date || !monthProductList || !monthOrderList) {
        return;
      }
      const list: OrderInfoGroup[] = [];
      // AIPで取得したデータをOrderInfoコンポーネントに渡せる形に成型する
      monthProductList.forEach((product) => {
        if (
          startOfDay(new Date(product.customFields.day)).getTime() !==
          startOfDay(new Date(date)).getTime()
        ) {
          return;
        }
        list.push({
          orderList: [
            {
              endTime: product.customFields.endTime,
              startTime: product.customFields.startTime,
            },
          ],
          productId: product.id,
          productName: product.name,
        });
      });
      monthOrderList.forEach((order) => {
        const product = monthProductList.find(
          (p) => p.id === order.lineItems[0]?.product
        );
        if (!product) {
          return;
        }
        const item = list.find((e) => e.productId === product.id);
        if (!item) {
          return;
        }
        if (order.status === 'CANCELED') {
          return;
        }
        if (!item.orderList || !item.orderList[0]?.status) {
          item.orderList = [];
        }
        item.orderList?.push({
          birthday: order.customer.user.customFields?.birthday,
          endTime: product.customFields.endTime,
          familyName: order.customer.user.customFields?.familyName,
          firstName: order.customer.user.customFields?.firstName,
          gender: order.customer.user.customFields?.gender,
          orderId: order.id,
          startTime: product.customFields.startTime,
          status: order.status,
        });
      });
      setOrderInfoList(list);
      setOrderInfoDate(date);
    },
    [monthProductList, monthOrderList, orderInfoDate]
  );

  const [isOrderNotificationModal, setIsOrderNotificationModal] =
    useState(false);
  const [pendingOrders, setPendingOrders] = useState<Order[]>([]);
  const fetchOrdersWithPendingStatus = useCallback(async () => {
    try {
      const result = await getOrdersListWithFilter(organizationState.id, {
        expand: ['lineItems.product'],
        notPendingNoAlertFlag: true,
        pageSize: 100,
        statuses: [OrderStatus.PENDING],
      });
      setPendingOrders(result.data.value || []);
      if (result.data.value.length > 0) {
        setIsOrderNotificationModal(true);
      }
    } catch (e) {
      if (isError(e)) {
        throw new Error(e.message);
      }
    }
  }, [organizationState]);

  const adGroups = useMemo(() => {
    return ['202'];
  }, []);

  const [ads, setAds] = useState<Ad[]>([]);
  const fetchAds = useCallback(async () => {
    try {
      const result = await getPublishedAds({
        filter: { publicationGroup: adGroups },
        top: 1,
      });
      setAds(result.data.value || []);
    } catch (e) {
      if (isError(e)) {
        throw new Error(e.message);
      }
    }
  }, [adGroups]);

  const refFirstRef = useRef(true);
  useEffect(() => {
    if (refFirstRef.current) {
      refFirstRef.current = false;
      void fetchAds();
      void fetchOrdersWithPendingStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMonthSelectChange: NonNullable<
    ComponentProps<typeof MonthSelect>['onChange']
  > = useCallback((value) => {
    loadTimerRef.current = { ...loadTimerRef.current, ms: 300 }; //NOTE:無駄な検索防止に一定時間検索待機
    setSelectMonth(value);
  }, []);

  const handleCalendarClick: ComponentProps<typeof Calendar>['onClick'] =
    useCallback(async (value) => {
      setSelectDate(value.date ?? '');
    }, []);

  useEffect(() => {
    // NOTE:無駄な検索防止に一定時間検索待機
    clearTimeout(loadTimerRef.current.timeoutId);
    const timeoutId = setTimeout(() => {
      void loadCalendar(selectMonth);
    }, loadTimerRef.current.ms);
    loadTimerRef.current = { ms: 0, timeoutId };
  }, [loadCalendar, selectMonth]);

  useEffect(() => {
    void loadOrderInfo(selectDate);
  }, [loadOrderInfo, selectDate]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      width="fit-content"
      alignItems="end"
    >
      <Box display="inline-flex" gap={2} alignItems="center" width="100%">
        <MonthSelect
          isBack
          isForward
          value={selectMonth}
          onChange={handleMonthSelectChange}
        />
        <Button
          sx={{
            marginLeft: 'auto',
            width: '12rem',
          }}
          size="small"
          variant="contained"
          component={Link}
          to="/products/register"
        >
          求人の新規登録
        </Button>
      </Box>
      <Box display="inline-flex" gap={1}>
        <Calendar
          loading={isLoadingCalendar}
          month={selectMonth}
          selectDate={selectDate}
          dayValueList={dayList}
          errorMessage={calendarErrorMessage}
          onClick={handleCalendarClick}
        />
        <OrderInfo date={selectDate} orderGroupList={orderInfoList} />
      </Box>
      <Stack justifyContent="end" flexDirection="row">
        <Advertising item={findByGroupOrDefault(ads, adGroups[0])} />
      </Stack>
      <OrderNotificationModal
        items={pendingOrders}
        isOpen={isOrderNotificationModal}
        onClose={() => setIsOrderNotificationModal(false)}
        setItems={setPendingOrders}
      />
    </Box>
  );
}
