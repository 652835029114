import { format as dateFnsFormat } from 'date-fns';
import dateFnsJa from 'date-fns/locale/ja';

import {
  Order,
  OrderCancelType,
  OrderCancelTypeKey,
  OrderLocalized,
  OrderStatus,
  SupportedOrderStatus,
} from '@app/types/order';
import { localizedOrderStatus } from '@app/utils/constants';

export function convertOrderLocalized(item: Order) {
  return {
    attachment: {
      orderAfterFiles: item.customFields?.orderAfterFiles || [],
      orderMatchedFiles: item.customFields?.orderMatchedFiles || [],
    },
    cancelReason: item.cancelReason || '',
    closedAt: item.closedAt,
    createdAt: dateFnsFormat(
      new Date(item.createdAt),
      'yyyy年M月d日(E) HH:mm',
      { locale: dateFnsJa }
    ),
    currency: item.currency,
    customFields: item.customFields,
    customer: item.customer,
    gender: item.gender,
    id: item.id,
    lineItem: item.lineItems[0],
    localizedStatus: localizedOrderStatus[item.status],
    name: item.name,
    organization: item.organization,
    status: item.status,
    subTotalPrice: item.subTotalPrice,
    totalTax: item.totalTax,
  } as OrderLocalized;
}

export function getUserFullName(item: Order): string {
  return [
    item.customer.user.customFields.familyName,
    item.customer.user.customFields.firstName,
  ].join('');
}

export function getOrderStatusLabel(
  status: SupportedOrderStatus,
  cancelType?: OrderCancelTypeKey
): string {
  switch (status) {
    case OrderStatus.PENDING:
      return '応募中';
    case OrderStatus.ACCEPTED:
      return '採用';
    case OrderStatus.PROCESSING:
      return '評価待ち';
    case OrderStatus.CLOSED:
      return '評価済み';
    case OrderStatus.CANCELED:
      return getOrderCancelTypeLabel(cancelType);
  }
}
export function getOrderCancelTypeLabel(
  cancelType?: OrderCancelTypeKey
): string {
  switch (cancelType) {
    case OrderCancelType.DEMAND_STATUS_PENDING:
      return '応募辞退';
    case OrderCancelType.DEMAND_STATUS_ACCEPTED:
      return '採用辞退';
    case OrderCancelType.SUPPLY_STATUS_ACCEPTED:
      return '採用キャンセル';
    case OrderCancelType.TIME_UP:
      return '時間切れキャンセル';
    case OrderCancelType.SUPPLY_STATUS_NOT_ACCEPTED:
      return '不採用';
  }
  return '不明';
}
