import { AxiosPromise, AxiosRequestConfig, CanceledError } from 'axios';

import { axiosInstance, ORGANIZATION_SERVICE } from '@app/adapter/axios';
import { Paginated } from '@app/types/common';
import {
  ChangeRequestInput,
  Organization,
  OrganizationInput,
  OrganizationUpdateInput,
  OrganizationUser,
} from '@app/types/organization';
import { User } from '@app/types/user';

export function getOrganizationDetails(
  userId: string,
  config?: AxiosRequestConfig
): AxiosPromise<Paginated<Organization>> {
  return axiosInstance
    .get(`${ORGANIZATION_SERVICE}/users/${userId}/organizations`, config)
    .catch((error) => {
      if (error instanceof CanceledError) {
        throw error;
      } else if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * blocks-5f90
 *
 * Fetch organizations.
 */
export function getOrganizations(
  config?: AxiosRequestConfig
): AxiosPromise<Paginated<Organization>> {
  return axiosInstance
    .get(`${ORGANIZATION_SERVICE}/organizations`, config)
    .catch((error) => {
      if (error instanceof CanceledError) {
        throw error;
      } else if (error.response && 'message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}
export function getOrganizationById(
  organizationId: string,
  config?: AxiosRequestConfig
): AxiosPromise<Organization> {
  return axiosInstance
    .get(`${ORGANIZATION_SERVICE}/organizations/${organizationId}`, config)
    .catch((error) => {
      if (error instanceof CanceledError) {
        throw error;
      } else if (error.response && 'message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getUsersForOrganization(
  organizationId: string,
  config?: AxiosRequestConfig
): AxiosPromise<Paginated<User>> {
  const urlParams = [['$expand', 'user']];
  return axiosInstance
    .get(
      `${ORGANIZATION_SERVICE}/organizations/${organizationId}/users?${new URLSearchParams(
        urlParams
      ).toString()}`,
      config
    )
    .catch((error) => {
      if (error instanceof CanceledError) {
        throw error;
      } else if (error.response && 'message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * blocks-5f90
 *
 * Create an organization.
 */
export function createOrganization(
  organization: OrganizationInput
): AxiosPromise<Organization> {
  return axiosInstance
    .post(`${ORGANIZATION_SERVICE}/organizations`, organization)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Update organization information throw organization service
 *
 * @param id
 * @param organizationInfo
 * @returns
 */
export function updateOrganizationInfo(
  id: string,
  organizationInfo: OrganizationUpdateInput
): AxiosPromise<Organization> {
  return axiosInstance
    .patch(`${ORGANIZATION_SERVICE}/organizations/${id}`, {
      ...organizationInfo,
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * POST /organizations/{orgId}/change_request
 * @param orgId
 * @param changeRequest
 * @returns
 * blocks-b9dc
 */
export function createChangeRequest(
  orgId: string,
  changeRequest: ChangeRequestInput
): AxiosPromise<void> {
  return axiosInstance
    .post(`${ORGANIZATION_SERVICE}/organizations/${orgId}/change_requests`, {
      ...changeRequest,
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getOrganizationUsers(
  orgId: string
): AxiosPromise<Paginated<OrganizationUser>> {
  return axiosInstance.get(
    `${ORGANIZATION_SERVICE}/organizations/${orgId}/users?$expand=user`
  );
}

export function getOrganizationUploadSignedUrl(
  orgId: string,
  type: string,
  size: number
): Promise<string> {
  return axiosInstance
    .get(
      `${ORGANIZATION_SERVICE}/organizations/${orgId}/upload_url?contentLength=${size}&contentType=${type}`
    )
    .then((response) => response.data);
}

export function postOrganizationAttachment(
  orgId: string,
  attachment: { objectId: string; type: string }
): AxiosPromise<{ id: string; url: string }> {
  return axiosInstance.post(
    `${ORGANIZATION_SERVICE}/organizations/${orgId}/attachments`,
    attachment
  );
}

export function getUserInvolvedOrganizations(
  userId: string | null
): AxiosPromise<Paginated<OrganizationUser>> {
  return axiosInstance.get(
    `${ORGANIZATION_SERVICE}/users/${userId}/organizations`
  );
}

/**
 * 組織を削除状態に更新する。（論理削除）
 * @param orgId
 * @returns
 */
export function deleteUpdateOrganization(
  orgId: string
): AxiosPromise<Organization> {
  const data: OrganizationUpdateInput = {
    // address: '',
    branchName: '',
    customFields: {
      access: '',
      addressByCity: '',
      addressLine4: '',
      chatContact: '',
      clinicAppealMessage: '',
      clinicUrl: '',
      deleted_at: new Date().toISOString(),
      department: '',
      electronicChartMaker: '',
      electronicCharts: [],
      equipmentOther: '',
      equipments: [],
      mainImages: '',
      ownerName: '',
      ownerUserId: '',
    },
    description: '',
    iconImageData: '',
    name: '',
    phoneNumber: '',
    pictureUrl: '',
    postalCode: '',
  };
  return axiosInstance
    .patch(`${ORGANIZATION_SERVICE}/organizations/${orgId}`, data)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}
