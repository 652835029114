import PersonIcon from '@mui/icons-material/Person';
import {
  Box,
  Button,
  Avatar,
  Typography,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import { ComponentProps, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { getUser } from '@app/adapter/user-service';
import { FollowButton } from '@app/components/Shared/FollowButton';
import { UserLayout } from '@app/components/Users/UserLayout';
import { UserProfile } from '@app/components/Users/UserProfile';
import { UserReviews } from '@app/components/Users/UserReviews';
import {
  loggedInUserState,
  snackbarOpenState,
  snackbarTextState,
} from '@app/domain/app';
import { useProductsAdd } from '@app/hooks/catalog';
import { useReviewsAdd } from '@app/hooks/review';
import { User } from '@app/types/user';

const ColBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const RowBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

export function UserDetails() {
  const navigate = useNavigate();
  const pageLocation = useLocation();
  const userId = pageLocation.pathname.split('/')[2] ?? '';
  const loggedInUser = useRecoilValue(loggedInUserState);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [user, setUser] = useState(undefined as User | undefined);
  const [isLoadData, setIsLoadData] = useState(false);
  const reviewsAddCount = 10;
  const [reviewsDisplayCount, setReviewsDisplayCount] =
    useState(reviewsAddCount);
  /** 評価リスト、評価件数 */
  const { reviews, reviewsTotal, setReviews } = useReviewsAdd(
    userId,
    reviewsDisplayCount,
    100
  );
  /** 評価に割りついた求人リスト */
  const reviewProducts = useProductsAdd(
    reviews?.map((r) => r.customFields?.productId)
  );

  const handleClickChatButton: ComponentProps<typeof Button>['onClick'] =
    useCallback(() => {
      navigate('/chats');
    }, [navigate]);

  const handleClickNextUserReview: ComponentProps<
    typeof UserReviews
  >['onClickNext'] = useCallback(() => {
    setReviewsDisplayCount((prev) => prev + reviewsAddCount);
  }, [setReviewsDisplayCount]);

  const handleChangeUserReview: NonNullable<
    ComponentProps<typeof UserReviews>['onChange']
  > = useCallback(
    (reviews) => {
      setReviews(reviews);
    },
    [setReviews]
  );

  const loadUser = useCallback(async () => {
    try {
      if (!userId) {
        return;
      }
      const result = await getUser(userId);
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      setUser(result.data);
    } catch (error) {
      setSnackbarText(
        `医師の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    } finally {
      setIsLoadData(true);
    }
  }, [userId, setSnackbarOpen, setSnackbarText]);

  useEffect(() => {
    void loadUser();
  }, [loadUser]);

  if (!isLoadData) {
    return (
      <UserLayout>
        <CircularProgress sx={{ m: 'auto' }} />
      </UserLayout>
    );
  }

  if (!user) {
    return (
      <UserLayout>
        <Typography sx={{ m: 'auto' }}>
          ID:{userId}の医師を取得できませんでした
        </Typography>
      </UserLayout>
    );
  }

  const isDeleteUser = !!user.customFields.deleted_at;

  return (
    <UserLayout>
      <ColBox gap={2} mb={4}>
        <RowBox gap={2}>
          <Typography fontSize="1.8rem">医師</Typography>
          <RowBox gap={2} ml="auto">
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{ width: '7rem' }}
              onClick={handleClickChatButton}
            >
              メッセージ
            </Button>
            <FollowButton
              variant="outlined"
              color="secondary"
              size="small"
              sx={{ width: '7rem' }}
              userId={loggedInUser?.id ?? ''}
              followUserId={userId}
            />
          </RowBox>
        </RowBox>
        <RowBox gap={2} alignItems="center">
          <Avatar
            sx={{ backgroundColor: '#53BAC8', height: 32, width: 32 }}
            src={user.avatar}
          >
            <PersonIcon />
          </Avatar>
          {isDeleteUser && <Typography>（退会済ユーザー）</Typography>}
          <Typography>{user.customFields?.familyName}</Typography>
          <Typography>{user.customFields?.firstName}</Typography>
          <Typography>/</Typography>
          <Typography>{user.customFields?.familyNameKana}</Typography>
          <Typography>{user.customFields?.firstNameKana}</Typography>
        </RowBox>
      </ColBox>

      <UserProfile user={user} />

      <UserReviews
        reviewProducts={reviewProducts}
        reviews={reviews}
        reviewsDisplayCount={reviewsDisplayCount}
        reviewsTotal={reviewsTotal}
        loading={reviewsTotal === undefined}
        onClickNext={handleClickNextUserReview}
        onChange={handleChangeUserReview}
      />
    </UserLayout>
  );
}
