import { AxiosPromise } from 'axios';

import { axiosInstance, AUTH_SERVICE } from '@app/adapter/axios';

export interface LoginResponse {
  accessToken: string;
  userId: string;
}

/**
 * blocks-5f90
 *
 * Send login request.
 */
export function login(
  email: string,
  password: string,
  fingerprint: string
): AxiosPromise<LoginResponse> {
  return axiosInstance
    .post(`${AUTH_SERVICE}/login`, { email, fingerprint, password })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function checkToken(
  token: string,
  fingerprint: string,
  target?: string
): AxiosPromise<{ userId: string }> {
  return axiosInstance.post(`${AUTH_SERVICE}/check-token`, {
    fingerprint,
    target,
    token,
  });
}
