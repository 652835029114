import { Box, Button } from '@mui/material';
import { isEmpty, uniq } from 'lodash';
import { useCallback } from 'react';
import { UseFormSetError } from 'react-hook-form';

import {
  handleFileFactory,
  handleDeleteFactory,
} from '@app/components/UploadFile/Util';
import { AdInputEdit } from '@app/types/ad';
import { isError } from '@app/utils/error';

const acceptTypePdf = 'application/pdf';
export type ExtraExtension = typeof acceptTypePdf;

export const FILE_MAX_SIZE = 5000000;

function getExtraAcceptList(extraExtensions: ExtraExtension[]): string[] {
  return uniq(extraExtensions.filter((v) => !isEmpty(v)));
}

function getAccept(extraAcceptList: string[]) {
  const extraAccepts = extraAcceptList.join(',');
  return extraAccepts && extraAccepts.length > 0
    ? 'image/*,' + extraAccepts
    : 'image/*';
}

type Props = {
  addImageButtonText?: string | React.ReactNode;
  buttonStyle?: React.CSSProperties;
  deleteImageButtonText?: string;
  extraExtensions?: ExtraExtension[];
  fileData: string;
  maxSize?: number;
  name?: string;
  setError?: UseFormSetError<AdInputEdit>;
  setImageUrl: (data: string) => void;
  shouldShowDeleteButton?: boolean;
};

export function InputFile(props: Props) {
  const {
    fileData,
    setImageUrl,
    addImageButtonText,
    deleteImageButtonText,
    shouldShowDeleteButton,
    extraExtensions = [],
    setError,
    name,
    maxSize = FILE_MAX_SIZE,
  } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFile = useCallback(
    (function () {
      return handleFileFactory(setImageUrl);
    })(),
    [setImageUrl]
  );

  const handleChange = async function (e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    try {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        if (file.size > maxSize) {
          throw new Error(
            `${maxSize / 1000000}MBを超えるファイルはアップロードできません`
          );
        }
        if (extraExtensions.length < 1 && !file.type.startsWith('image/')) {
          throw new Error('画像ファイルを指定して下さい');
        }
        if (
          !extraAcceptList.includes(file.type) &&
          !file.type.startsWith('image/')
        ) {
          throw new Error(`${accept}形式のファイルを指定して下さい`);
        }
        await handleFile(file);
      }
    } catch (e) {
      if (isError(e) && name && setError) {
        setError(name as keyof AdInputEdit, { message: e.message });
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDelete = useCallback(
    (function () {
      return handleDeleteFactory(setImageUrl);
    })(),
    [setImageUrl]
  );

  const extraAcceptList = getExtraAcceptList(extraExtensions);
  const accept = getAccept(extraAcceptList);
  return (
    <Box>
      {fileData && shouldShowDeleteButton ? (
        <Button
          fullWidth
          variant="outlined"
          onClick={handleDelete}
          style={{
            borderColor: '#B0B7C3',
            color: '#2F2F2F',
            fontWeight: 400,
          }}
        >
          {deleteImageButtonText || '画像を削除'}
        </Button>
      ) : (
        <Button
          fullWidth
          variant="outlined"
          component="label"
          sx={{
            borderColor: '#B0B7C3',
            color: '#2F2F2F',
            fontWeight: 400,
            justifyContent: 'flex-start',
          }}
        >
          {addImageButtonText || '画像を追加'}
          <input
            hidden
            accept={accept}
            multiple
            type="file"
            onChange={handleChange}
          />
        </Button>
      )}
    </Box>
  );
}
