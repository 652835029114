import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { isValidUserAuthInfoState } from '@app/domain/app';

/**
 * blocks-5f90
 *
 * A private route to limit unconfirmed users' accesses.
 */
export function UnconfirmedUserRoute({
  children,
}: {
  children?: React.ReactNode;
}): ReactElement {
  const navigate = useNavigate();
  const [loadable, setLoadable] = useState<'loading' | 'error' | 'hasValue'>(
    'loading'
  );
  const isValidUserAuthInfo = useRecoilValue(isValidUserAuthInfoState);

  useEffect(() => {
    if (loadable === 'hasValue') return;
    if (loadable === 'error') {
      const href = globalThis.location.href.split('#');
      const searchParams = new URLSearchParams(href[1]);
      // Plush any data in memory if the user doesn't have valid auth information.
      navigate(`/login#next=email-verification&${searchParams.toString()}`);
      return;
    }

    const execute = async () => {
      // Check the user's auth information.
      if (!isValidUserAuthInfo) {
        setLoadable('error');
        return;
      }
      setLoadable('hasValue');
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    execute();
  }, [loadable, isValidUserAuthInfo, navigate]);

  return <>{loadable === 'hasValue' ? children : undefined}</>;
}
