import { login } from '@app/adapter/auth-service';
import { verifyEmail } from '@app/adapter/user-service';
import { generateFingerPrint } from '@app/domain/fingerprint';

export async function handleLogin(email: string, password: string) {
  const fingerprint = await generateFingerPrint();
  const { data } = await login(email, password, fingerprint);
  return {
    accessToken: data.accessToken,
    fingerprint,
    id: data.userId,
  };
}

export function getVerifyToken() {
  const token = new URLSearchParams(globalThis.location.search).get('token');
  return token || '';
}

export function getInvitationId() {
  const invitationId = new URLSearchParams(globalThis.location.search).get(
    'invitationId'
  );
  return invitationId || '';
}

export async function verifyEmailToken(token: string) {
  const data = await verifyEmail(token);
  return data;
}
