import { Typography, Box, useTheme, Grid } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

import { theme } from '@app/theme';

const steps = ['ユーザー情報', '医療機関情報', '確認'];

interface ProgressStepperProps {
  activeStep: number;
}

const CustomTypography = styled(Typography)({
  fontSize: '0.8rem',
  paddingTop: 8,
});

const CustomBox = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'whiteColor' && prop !== 'active' && prop !== 'primaryColor',
})<{
  active: boolean;
  primaryColor: string;
  whiteColor: string;
}>(({ active, primaryColor, whiteColor }) => ({
  alignItems: 'center',
  backgroundColor: active ? primaryColor : theme.customPalette.lightGray,
  borderRadius: '50%',
  color: whiteColor,
  display: 'flex',
  fontSize: '1.3rem',
  fontWeight: 600,
  height: 35,
  justifyContent: 'center',
  paddingRight: 1,
  width: 35,
}));

const DottedLine = styled('div')({
  backgroundImage: 'radial-gradient(#949494 2.5px, transparent 1px)',
  backgroundSize: '7.4px 9px',
  height: '10px',
  marginBottom: '25px',
  marginRight: '10px',
  marginTop: 5,
  width: '30px',
});

export const ProgressStepper: React.FC<ProgressStepperProps> = ({
  activeStep,
}) => {
  const theme = useTheme();

  return (
    <Grid
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        left: '50%',
        position: 'absolute',
        top: 100,
        transform: 'translateX(-50%)',
      }}
    >
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {steps.map((label, index) => (
          <Grid sx={{ alignItems: 'center', display: 'flex' }} key={index}>
            <Grid
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CustomBox
                whiteColor={theme.customPalette.white}
                active={index === activeStep}
                primaryColor={theme.palette.primary.main}
              >
                {index + 1}
              </CustomBox>
              <CustomTypography>{label}</CustomTypography>
            </Grid>
            {index !== steps.length - 1 && <DottedLine />}
          </Grid>
        ))}
      </Grid>
      <Grid sx={{ height: '50px' }} />
    </Grid>
  );
};
