import ClearIcon from '@mui/icons-material/Clear';
import {
  Button,
  Dialog,
  Box,
  BoxProps,
  Tabs,
  Tab,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  IconButton,
  FormHelperText,
  useTheme,
} from '@mui/material';
import { useState, forwardRef, useMemo } from 'react';

import { notRequireClinicalDepartmentName } from '@app/utils/constants';

export interface AttributeSelectItem {
  group?: string;
  id: string;
  name: string;
}

export interface AttributeSelectProps extends Omit<BoxProps, 'onChange'> {
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  isGroup?: boolean;
  label?: string;
  list?: AttributeSelectItem[];
  notRequireDepartmentId?: string;
  onChange?: (value: string[]) => void;
  value?: string[];
}

export const AttributeSelect = forwardRef(function (
  {
    value,
    label,
    error,
    isGroup,
    helperText,
    list,
    onChange,
    disabled,
    notRequireDepartmentId,
    ...boxProps
  }: AttributeSelectProps,
  ref
) {
  const listToRender = useMemo(() => {
    return list?.filter(
      (item) => item.name !== notRequireClinicalDepartmentName
    );
  }, [list]);
  const groupList = Array.from(
    new Set(listToRender?.map((d) => d.group))
  ).filter((id) => id !== notRequireDepartmentId);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [selectValues, setSelectValues] = useState<string[]>([]);
  const [selectTab, setSelectTab] = useState(groupList[0]);
  const theme = useTheme();
  return (
    <Box {...boxProps} display="flex" flexDirection="column" gap={1}>
      <Box display="flex" gap={1} flexWrap="wrap">
        {listToRender
          ?.filter((d) => value?.some((v) => v === d.id))
          .map((d) => {
            return (
              <Box
                display="flex"
                alignItems="center"
                border={`solid 1px ${theme.palette.grey[400]}`}
                px={1}
                py={0.5}
                borderRadius={4}
                key={d.id}
                color={disabled ? theme.palette.grey[500] : undefined}
                bgcolor={theme.palette.common.white}
              >
                {d.name}
                <IconButton
                  onClick={() => {
                    onChange?.(value?.filter((s) => s !== d.id) ?? []);
                  }}
                  sx={{ p: 0.2 }}
                  disabled={disabled}
                >
                  <ClearIcon sx={{ fontSize: '1rem' }} />
                </IconButton>
              </Box>
            );
          })}
      </Box>
      <Box display="flex" flexDirection="column">
        <Box display="flex" gap={1}>
          <Button
            ref={ref as React.RefObject<HTMLButtonElement>}
            variant="outlined"
            color="secondary"
            sx={{
              border: error
                ? `solid 1px ${theme.palette.error.main}`
                : undefined,
              minWidth: '10rem',
            }}
            disabled={disabled}
            onClick={() => {
              setSelectValues(value ?? []);
              setSelectTab(selectTab ?? groupList[0]);
              setIsOpenDialog(true);
            }}
          >
            {label}
          </Button>
        </Box>
        <FormHelperText error={error} sx={{ ml: 2 }}>
          {helperText}
        </FormHelperText>
      </Box>
      <Dialog
        open={isOpenDialog}
        maxWidth={false}
        PaperProps={{ sx: { borderRadius: 4 } }}
      >
        <Box display="flex" flexDirection="column" gap={2} width="45rem" p={5}>
          <Typography variant="h6">{label}</Typography>
          {isGroup && (
            <Box>
              <Tabs
                value={selectTab}
                onChange={(e, v) => setSelectTab(v)}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                {groupList.map((g) => (
                  <Tab
                    label={
                      <Box width="10rem" fontSize="1rem">
                        {g}
                      </Box>
                    }
                    value={g}
                    key={g}
                  />
                ))}
              </Tabs>
            </Box>
          )}
          <Box
            height={isGroup ? '18rem' : 'fix-content'}
            maxHeight={'18rem'}
            overflow="auto"
          >
            <Grid container spacing={0.5}>
              {listToRender
                ?.filter((d) => (isGroup ? d.group === selectTab : true))
                .map((d) => {
                  return (
                    <Grid item xs={3} key={d.id}>
                      <FormControlLabel
                        sx={{ ml: 0 }}
                        control={
                          <Checkbox
                            checked={selectValues.some((v) => v === d.id)}
                            onChange={(e, checked) => {
                              if (checked) {
                                setSelectValues([
                                  ...selectValues.filter((s) => s !== d.id),
                                  d.id,
                                ]);
                              } else {
                                setSelectValues(
                                  selectValues.filter((s) => s !== d.id)
                                );
                              }
                            }}
                          />
                        }
                        label={d.name}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            gap={2}
            width="100%"
            mt="auto"
          >
            <Button
              variant="outlined"
              color="secondary"
              sx={{ ml: 'auto', width: '10rem' }}
              onClick={() => {
                setIsOpenDialog(false);
              }}
            >
              キャンセル
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: '10rem' }}
              onClick={() => {
                setIsOpenDialog(false);
                onChange?.(selectValues);
              }}
            >
              決定
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
});
