import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { TemplateInputEdit } from '@app/types/chats';

export const TemplateInputFormDefaultValues: TemplateInputEdit = {
  content: '',
  orgId: '',
  permissions: [],
  title: '',
};

const TemplateInputFormSchema = z.object({
  content: z.string().min(1, { message: 'メッセージを入力してください' }),
  title: z
    .string()
    .min(1, { message: 'テンプレートタイトルを入力してください' }),
});

export const TemplateInputForm = {
  defaultValues: TemplateInputFormDefaultValues,
  resolver: zodResolver(TemplateInputFormSchema),
  schema: TemplateInputFormSchema,
} as const;
