import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

export interface OrganizationFormData {
  access: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  assigneeEmail: string;
  assigneeName: string;
  certificateImageUri: string;
  customFields: {
    access: string;
    addressByCity: string;
    addressLine4: string;
    clinicAppealMessage: string;
    clinicUrl: string;
    department: string;
    electronicChartMaker: string;
    electronicCharts: string[];
    equipmentOther: string;
    equipments: string[];
    mainImages: string;
    ownerName: string;
    ownerUserId: string;
    scoutCount: number;
    scoutExpirationDate?: string;
    scoutMaxCount: number;
  };
  description: string;
  iconImageUri: string;
  name: string;
  ownerId: string;
  phoneNumber: string;
  postalCode: string;
  privacyPolicy: boolean;
  scoutCountReset: boolean;
  shouldSkipValidation: boolean;
  userAgreement: boolean;
}
export const ORGANIZATION_FORM_DEFAULT_VALUES: OrganizationFormData = {
  access: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  assigneeEmail: '',
  assigneeName: '',
  certificateImageUri: '',
  customFields: {
    access: '',
    addressByCity: '',
    addressLine4: '',
    clinicAppealMessage: '',
    clinicUrl: '',
    department: '',
    electronicChartMaker: '',
    electronicCharts: [],
    equipmentOther: '',
    equipments: [],
    mainImages: '',
    ownerName: '',
    ownerUserId: '',
    scoutCount: 0,
    scoutExpirationDate: '',
    scoutMaxCount: 0,
  },
  description: '',
  iconImageUri: '',
  name: '',
  ownerId: '0000',
  phoneNumber: '',
  postalCode: '',
  privacyPolicy: false,
  scoutCountReset: false,
  shouldSkipValidation: false,
  userAgreement: false,
};

export enum Gender {
  Female = 'female',
  Male = 'male',
  None = '',
}

export enum GenderSelf {
  Female = '女性',
  Male = '男性',
  NoAnswer = '無回答',
  None = '',
  Other = 'その他',
}

export interface UserInfoFormData {
  customFields: {
    birthday: string;
    familyName: string;
    familyNameKana: string;
    firstName: string;
    firstNameKana: string;
    gender: Gender;
    genderSelf: GenderSelf;
  };
}

const UserInfoDefaultValues: UserInfoFormData = {
  customFields: {
    birthday: '',
    familyName: '',
    familyNameKana: '',
    firstName: '',
    firstNameKana: '',
    gender: Gender.None,
    genderSelf: GenderSelf.None,
  },
};

export const OrganizationFormValidator = z.object({
  addressLine1: z.string().min(1, { message: '都道府県を入力してください' }),
  addressLine2: z.string().min(1, { message: '市町村区を入力してください' }),
  addressLine3: z.string().min(1, { message: '町名・番地を入力してください' }),
  customFields: z.object({
    access: z.string().min(1, { message: 'アクセス情報を入力してください' }),
    addressByCity: z.string(),
    addressLine4: z.string(),
    clinicAppealMessage: z
      .string()
      .min(1, { message: '医療機関の一言アピールは必須です' })
      .max(200, {
        message: '医療機関の一言アピールは200文字以内で入力してください',
      }),
    clinicUrl: z.string(),
    department: z.string().min(1, { message: '標榜科目を入力してください' }),
    electronicChartMaker: z.string(),
    electronicCharts: z
      .array(z.string())
      .refine((value) => value.length, { message: 'カルテを選択してください' }),
    equipmentOther: z.string(),
    equipments: z.array(z.string()),
    mainImages: z.string(),
    ownerName: z.string().min(1, { message: 'オーナー名を入力してください' }),
  }),
  description: z.string(),
  iconImageUri: z.string(),
  name: z.string().min(1, { message: '医療機関名を入力してください' }),
  ownerId: z.string().min(1, { message: 'ownerIdを入力してください' }),
  phoneNumber: z
    .string()
    .min(1, { message: '担当者連絡先電話番号を入力してください' })
    .regex(/^0[0-9]{9,12}$/, {
      message: '担当者連絡先電話番号はハイフンなしの数字を入力してください',
    }),
  postalCode: z
    .string()
    .regex(/^\d{7}$/, { message: '郵便番号を入力してください' }),
  privacyPolicy: z.boolean().refine((value) => value, {
    message: 'プライバシーポリシーに同意する必要があります',
  }),
  userAgreement: z
    .boolean()
    .refine((value) => value, { message: '利用規約に同意する必要があります' }),
});

export const OrganizationAdminFormValidator = OrganizationFormValidator.extend({
  customFields: z.object({
    ...OrganizationFormValidator.shape.customFields.shape,
    scoutExpirationDate: z.string().optional(),
    scoutMaxCount: z.preprocess(
      (v) => String(v),
      z
        .string()
        .nonempty({ message: '上限スカウト数を入力してください' })
        .regex(/^\d+$/, {
          message: '上限スカウト数は数値を入力してください',
        })
        .transform(Number)
    ),
  }),
  scoutCountReset: z.boolean(),
}).refine(
  (value) =>
    !(
      value.customFields.scoutMaxCount > 0 &&
      !value.customFields.scoutExpirationDate
    ),
  {
    message: '上限スカウト有効期限を入力してください',
    path: ['customFields.scoutExpirationDate'],
  }
);

const UserInfoFormValidator = z.object({
  customFields: z.object({
    birthday: z.string().refine(
      (value) => {
        const parts = value.split('/');
        return parts.length === 3 && parts.every((part) => part !== '');
      },
      {
        message: '生年月日を入力してください',
      }
    ),
    familyName: z.string().min(1, { message: '姓を入力してください' }),
    familyNameKana: z
      .string()
      .min(1, { message: '姓(かな)を入力してください' })
      .refine((value) => /^[ぁ-んー\s]+$/u.test(value), {
        message: '姓(かな)はひらがなのみで入力してください',
      }),
    firstName: z.string().min(1, { message: '名を入力してください' }),
    firstNameKana: z
      .string()
      .min(1, { message: '名(かな)を入力してください' })
      .refine((value) => /^[ぁ-んー\s]+$/u.test(value), {
        message: '名(かな)はひらがなのみで入力してください',
      }),
    gender: z.string().min(1, { message: '性別を選択してください' }),
    genderSelf: z.string(),
  }),
});

export const RegistrationForm = {
  defaultValues: ORGANIZATION_FORM_DEFAULT_VALUES,
  resolver: zodResolver(OrganizationFormValidator),
  schema: OrganizationFormValidator,
} as const;

export const UseInfoForm = {
  defaultValues: UserInfoDefaultValues,
  resolver: zodResolver(UserInfoFormValidator),
  schema: UserInfoFormValidator,
} as const;
