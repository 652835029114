import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import {
  GridColDef,
  GridPaginationModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSetRecoilState } from 'recoil';

import { getOrder, updateOrder } from '@app/adapter/order-service';
import {
  INITIAL_PAGINATE_MODEL,
  StandardStyleDataGrid,
} from '@app/components/Shared/StandardStyleDataGrid';
import {
  snackbarOpenState,
  snackbarSeverityState,
  snackbarTextState,
} from '@app/domain/app';
import { ProductType } from '@app/types/catalog';
import { Order } from '@app/types/order';
import { isError } from '@app/utils/error';
import { formatDate, formatWeek } from '@app/utils/format';
import { getAge } from '@app/utils/index';
import { getUserFullName } from '@app/utils/order';

interface OrderNotificationModalProps {
  isOpen: boolean;
  items: Order[];
  onClose: () => void;
  setItems: (items: Order[]) => void;
}

export function OrderNotificationModal({
  isOpen,
  items,
  onClose,
  setItems,
}: OrderNotificationModalProps): ReactElement {
  const navigate = useNavigate();
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const setSnackbarState = useSetRecoilState(snackbarSeverityState);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPaginationModel, setCurrentPaginationModel] =
    useState<GridPaginationModel>(INITIAL_PAGINATE_MODEL);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    []
  );
  const columns: Array<GridColDef> = [
    {
      field: 'workDay',
      headerName: '勤務日時',
      renderCell: (item) => {
        const product = item.row.lineItems[0].product;
        return (
          <Typography variant="caption" component="div">
            <Box>
              {item.row.customFields?.productType === ProductType.SPOT
                ? formatDate(new Date(item.row.customFields.productDay))
                : formatWeek(product.customFields?.repeatWeek.sort(), ',')}
            </Box>
            <Box>
              {`${product.customFields?.startTime}-${product.customFields?.endTime}`}
            </Box>
          </Typography>
        );
      },
      sortable: false,
      width: 210,
    },
    {
      field: 'user',
      headerName: '氏名 / 年齢・性別',
      renderCell: (item) => (
        <Typography variant="body2">
          {getUserFullName(item.row) +
            ` / ${getAge(item.row.customer.user.customFields.birthday)}歳` +
            `・${item.row.customer.user.customFields.gender}`}
        </Typography>
      ),
      sortable: false,
      width: 290,
    },
  ];

  const orders = useMemo(() => {
    return items.filter((i) => i.customFields?.pendingNoAlertFlag !== true);
  }, [items]);

  const handleClickUpdateNotifications = useCallback(async () => {
    try {
      setIsLoading(true);
      const updatedItems = await Promise.all(
        selectionModel.map(async (id) => {
          const item = items.find((i) => i.id === id);
          if (!item) return item;

          const order = await getOrder(item.organization, item.id);
          if (order.data.customFields) {
            const result = await updateOrder(item.organization, item.id, {
              customFields: {
                ...order.data.customFields,
                pendingNoAlertFlag: true,
              },
            });
            return result.data;
          }
          return item;
        })
      );
      setItems(
        items.map((i) => {
          const customFields =
            updatedItems.find((u) => u?.id === i.id)?.customFields ||
            i.customFields;
          return { ...i, customFields };
        })
      );
      setSnackbarState('info');
      setSnackbarText('指定した応募の通知を停止しました');
    } catch (e) {
      setSnackbarText('処理に問題が発生しました');
      if (isError(e)) {
        console.error(e.message);
      }
    } finally {
      setSnackbarOpen(true);
      setIsLoading(false);
    }
  }, [
    items,
    selectionModel,
    setItems,
    setSnackbarOpen,
    setSnackbarState,
    setSnackbarText,
  ]);

  const handleClickRouteToOrders = useCallback((): void => {
    onClose();
    navigate('/orders');
  }, [navigate, onClose]);

  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') onClose();
      }}
    >
      <DialogTitle id="alert-dialog-title">未対応の応募者がいます</DialogTitle>
      <DialogContent>
        <Typography>
          採用・不採用が未定の応募者がおります。
          <br />
          早めに合否を連絡さしあげるようにお願い致します。
        </Typography>
        <Box pt={3}>
          <Typography variant="body2">未対応 応募者一覧</Typography>
          <StandardStyleDataGrid
            paginationModel={currentPaginationModel}
            columns={columns}
            rows={orders}
            rowCount={orders.length}
            onRowSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            rowSelectionModel={selectionModel}
            checkboxSelection
            disableColumnMenu
            disableRowSelectionOnClick
            hideFooter
            onPaginationModelChange={setCurrentPaginationModel}
            sx={{
              '.MuiDataGrid-cell': {
                cursor: 'inherit',
              },
              mb: 1,
            }}
          />
          <LoadingButton
            variant="outlined"
            color="secondary"
            size="small"
            loading={isLoading}
            disabled={selectionModel.length < 1}
            onClick={handleClickUpdateNotifications}
          >
            DMにて調整中のため、通知を止める
          </LoadingButton>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleClickRouteToOrders}
        >
          応募一覧へ
        </Button>
      </DialogActions>
    </Dialog>
  );
}
