import { AxiosPromise, AxiosResponse } from 'axios';

import {
  axiosInstance,
  CATALOG_SERVICE,
  CHAT_SERVICE,
} from '@app/adapter/axios';
import {
  Attachment,
  AttachmentCreation,
  Message,
  MessageCategory,
  MessageCreation,
  MessageSenderType,
  MessageType,
  PaginatedTemplates,
  SubscriptionCreationRequest,
  SubscriptionCreationResponse,
  SubscriptionResponse,
  Template,
  TemplateInputEdit,
  Topic,
  TopicList,
  TopicOwnerType,
} from '@app/types/chats';
import { Paginated } from '@app/types/common';
import { filterSyntaxGen } from '@app/utils';
import { getUploadedFileUrl } from '@app/utils/file_upload';

export function getMessageListWithFilter(
  userId: string
): AxiosPromise<Paginated<Message>> {
  const urlParams = [['$orderBy', 'createdAt desc']];

  return axiosInstance.get(
    `${CATALOG_SERVICE}/users/${userId}/messages?${new URLSearchParams(
      urlParams
    ).toString()}`
  );
}

// サブスクリプションしているTopic取得
export function getUserChatTopicListWithFilter(
  userId: string,
  options?: {
    expandUser?: boolean;
    filter?: {
      ownerIds?: string[];
    };
    pageNumber?: number;
    pageSize?: number;
  }
): AxiosPromise<Paginated<Topic>> {
  const urlParams = [['$orderBy', 'lastMessageAt desc']];

  const filterParams = [];
  if (options?.filter?.ownerIds?.length) {
    filterParams.push(
      `ownerIds in ${filterSyntaxGen(options.filter.ownerIds)}`
    );
  }
  if (filterParams.length > 0) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }
  if (options?.expandUser) {
    urlParams.push(['$expand', 'user message']);
  }
  if (options?.pageSize !== undefined) {
    urlParams.push(['$top', options.pageSize.toString()]);
  }
  if (options?.pageNumber !== undefined && options?.pageSize !== undefined) {
    urlParams.push([
      '$skip',
      (options.pageNumber * options.pageSize).toString(),
    ]);
  }

  return axiosInstance.get(
    `${CHAT_SERVICE}/users/${userId}/topics?${new URLSearchParams(
      urlParams
    ).toString()}`
  );
}

export async function createNewTopic(
  userId: string,
  demandUserId: string,
  name: string
): Promise<AxiosResponse<Topic>> {
  const ownerIds = [userId, demandUserId];
  const requestBody = {
    app: 'user',
    isPublic: false,
    name,
    ownerIds,
    ownerType: TopicOwnerType.USER,
    typeId: 'channel',
  };

  return axiosInstance.post(`${CHAT_SERVICE}/topics`, requestBody);
}

export function getTopic(
  topicId: string,
  options?: {
    expand?: string;
  }
): AxiosPromise<Topic> {
  const urlParams = [];

  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }

  return axiosInstance.get(
    `${CHAT_SERVICE}/topics/${topicId}?${new URLSearchParams(
      urlParams
    ).toString()}`
  );
}

// Topicのサブスクリプション取得
export const getTopicSubscriptions = async (
  topicId: string
): Promise<SubscriptionResponse> => {
  try {
    const response = await axiosInstance.get(
      `${CHAT_SERVICE}/topics/${topicId}/subscriptions?$expand=user`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching topic subscriptions:', error);
    throw error;
  }
};

export const createSubscription = async (
  requestData: SubscriptionCreationRequest
): Promise<SubscriptionCreationResponse> => {
  try {
    const url = `${CHAT_SERVICE}/subscriptions`;
    const response = await axiosInstance.post<SubscriptionCreationResponse>(
      url,
      requestData
    );
    return response.data;
  } catch (error) {
    console.error('Error creating subscription:', error);
    throw error;
  }
};

export function getTopicMessageList(
  topicId: string
): AxiosPromise<Paginated<Message>> {
  const urlParams = [['$orderBy', 'createdAt desc']];

  return axiosInstance.get(
    `${CHAT_SERVICE}/topics/${topicId}/messages?${new URLSearchParams(
      urlParams
    ).toString()}`
  );
}

export function postChatMessage(userId: string, option: MessageCreation) {
  const payload = {
    ...option,
    category: MessageCategory.SUPPLY,
    messageType: MessageType.CHANNEL,
    senderId: userId,
    senderType: MessageSenderType.NORMAL_USER,
  };
  return axiosInstance.post(`${CHAT_SERVICE}/messages`, payload);
}

function convertFileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export async function postFile(userId: string, topicId: string, content: File) {
  const base64Content = await convertFileToBase64(content);
  let typeId = 'filedata';
  if (content.type.startsWith('image/')) {
    typeId = 'imagedata';
  }
  const data = {
    category: 'supply',
    content: base64Content,
    messageType: 'channel',
    senderId: userId,
    senderType: 'normal-user',
    title: content.name,
    topicId,
    typeId,
  };
  const response = await axiosInstance.post(`${CHAT_SERVICE}/messages`, data);
  return response;
}

export function getAllMessagesToOneTopic(
  topicId: string,
  options?: {
    expand?: string;
    limit?: number;
    nextLink?: string;
    orderBy?: string;
  }
): AxiosPromise<TopicList> {
  if (options?.nextLink) {
    return axiosInstance.get(options?.nextLink);
  }
  const urlParams = [['$top', String(options?.limit || 5)]];
  if (options?.orderBy) {
    urlParams.push(['$orderBy', options.orderBy]);
  }
  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  return axiosInstance.get(
    `${CHAT_SERVICE}/topics/${topicId}/messages?${new URLSearchParams(
      urlParams
    ).toString()}`
  );
}

export function getAllMessagesToOneUser(
  userId: string
): AxiosPromise<TopicList> {
  const urlParams = [
    ['$orderBy', 'createdAt desc'],
    ['$top', '50'],
  ];
  return axiosInstance.get(
    `${CHAT_SERVICE}/users/${userId}/messages?${new URLSearchParams(
      urlParams
    ).toString()}`
  );
}

export function deleteMessage(
  messageId: string,
  updatedContent: string
): AxiosPromise<unknown> {
  return axiosInstance.patch(`${CHAT_SERVICE}/messages/${messageId}`, {
    content: updatedContent,
  });
}

export function markMessageAsRead(
  messageId: string,
  userId: string
): AxiosPromise<unknown> {
  const requestData = {
    messageId,
    userId,
  };
  return axiosInstance.post(`${CHAT_SERVICE}/messages:mark-read`, requestData);
}

/**
 * Message Templates
 */
export function getMessageTemplatesByOrgId(
  orgId: string,
  options?: {
    expand?: string;
    filter?: {
      keyword?: string;
    };
    orderBy?: string;
    skip?: number;
    top?: number;
  }
): AxiosPromise<PaginatedTemplates> {
  const filterParams = [];
  const urlParams = [['$top', String(options?.top ?? 30)]];

  if (options?.filter?.keyword) {
    const keywordFilters = [
      `title co '${options.filter.keyword}'`,
      `content co '${options.filter.keyword}'`,
    ];
    filterParams.push(`(${keywordFilters.join(' or ')})`);
  }

  if (filterParams.length > 0) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }
  if (options?.skip) {
    urlParams.push(['$skip', String(options.skip)]);
  }
  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  if (options?.orderBy) {
    urlParams.push(['$orderBy', options.orderBy]);
  }

  return axiosInstance.get(
    `${CHAT_SERVICE}/organizations/${orgId}/message-templates?${new URLSearchParams(
      urlParams
    ).toString()}`
  );
}

export function getMessageTemplate(id: string): AxiosPromise<Template> {
  return axiosInstance.get(`${CHAT_SERVICE}/message-templates/${id}`);
}

export function postMessageTemplate(
  payload: TemplateInputEdit
): AxiosPromise<Template> {
  return axiosInstance.post(`${CHAT_SERVICE}/message-templates`, payload);
}

export function updateMessageTemplate(
  id: string,
  payload: TemplateInputEdit
): AxiosPromise<Template> {
  return axiosInstance.patch(
    `${CHAT_SERVICE}/message-templates/${id}`,
    payload
  );
}

export function deleteMessageTemplate(
  messageTemplateId: string
): AxiosPromise<undefined> {
  return axiosInstance.delete(
    `${CHAT_SERVICE}/message-templates/${messageTemplateId}`
  );
}

/**
 * attachment
 */
export function postAttachment(
  topicId: string,
  payload: AttachmentCreation
): AxiosPromise<{ id: string; url: string }> {
  return axiosInstance.post(
    `${CHAT_SERVICE}/topics/${topicId}/attachments`,
    payload
  );
}

export function deleteAttachment(
  topicId: string,
  id: string
): AxiosPromise<Paginated<Attachment[]>> {
  return axiosInstance
    .delete(`${CHAT_SERVICE}/topics/${topicId}/attachments/${id}`)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export async function uploadBlob(
  topicId: string,
  file: Blob
): Promise<Attachment> {
  if (!file) {
    throw new Error('blob is not defined');
  }
  const signedUrl = await getTopicUploadSignedUrl(topicId, file);
  const uploadedUrl = await getUploadedFileUrl(file, signedUrl);

  const objectId = new URL(uploadedUrl).pathname
    .split('?')[0]
    .replace(/^\/[^/]+\//, '');
  if (!objectId) {
    throw new Error('objectId is not undefined, upload may got error');
  }

  const objectSplits = objectId?.split('.');
  let extension = 'jpg';
  if (objectSplits && objectSplits.length > 1) {
    extension = objectSplits[objectSplits.length - 1];
  }
  const response = await postAttachment(topicId, {
    objectId,
    type: extension,
  });
  return response.data;
}

export function getTopicUploadSignedUrl(
  topicId: string,
  blob: Blob
): Promise<string> {
  return axiosInstance
    .get(
      `${CHAT_SERVICE}/topics/${topicId}/attachment-upload-url?contentLength=${blob.size}&contentType=${blob.type}`
    )
    .then((response) => response.data);
}
