import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { getOrder, updateOrder } from '@app/adapter/order-service';
import { OrderCancelInput, OrderCancelForm } from '@app/schemas/order';
import {
  Order,
  OrderCancelType,
  OrderLocalized,
  OrderStatus,
} from '@app/types/order';
import { useSetSnackbar } from '@app/utils/useSetSnackbar';

const cancelReasons = [
  '他の人で枠が埋まったため',
  '必要がなくなったため',
  'ドクターが条件に合わないため',
  'スキルが足りないため',
  '評価が低いため',
  '他',
] as const;

interface CancelDialogProps {
  onClose: () => void;
  onSubmit?: () => void;
  open: boolean;
  orders?: Order[] | OrderLocalized[];
}

export function CancelDialog({
  onClose,
  onSubmit,
  open,
  orders,
}: CancelDialogProps): ReactElement {
  const setSnackbar = useSetSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const userName =
    orders?.length === 1
      ? `${orders[0].customer?.user?.customFields?.familyName} ${orders[0].customer?.user?.customFields?.firstName}さん`
      : `チェックした${orders?.length}名`;
  const { control, formState, watch, handleSubmit, reset } =
    useForm<OrderCancelInput>(OrderCancelForm);

  const handleClose = useCallback((): void => {
    onClose();
    reset();
  }, [onClose, reset]);

  const updateOrders = async (data: OrderCancelInput) => {
    if (!orders?.length) return;
    setIsLoading(true);

    if (data.cancelReason === '他') {
      data.cancelReason = data.otherReason || '';
    }
    await Promise.all(
      orders.map(async (order) => {
        const latestOrder = await getOrder(order.organization, order.id);
        if (latestOrder.data.status !== order.status) {
          return latestOrder.data;
        }
        const result = await updateOrder(order.organization, order.id, {
          canceledAt: new Date().toISOString(),
          customFields: {
            ...latestOrder.data.customFields,
            cancelMessage: data.cancelReason,
            cancelType:
              order.status === OrderStatus.PENDING
                ? OrderCancelType.SUPPLY_STATUS_NOT_ACCEPTED
                : OrderCancelType.SUPPLY_STATUS_ACCEPTED,
          },
          status: OrderStatus.CANCELED,
        });
        return result.data;
      })
    );
    setSnackbar(true, `${userName}を不採用にしました。`, 'success');
    onSubmit && onSubmit();
  };

  useEffect(() => {
    if (!open) {
      reset();
      setIsLoading(false);
    }
  }, [open, reset]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <Stack spacing={3}>
          <Typography variant="h6">{`${userName}を不採用にしますか？`}</Typography>
          <Typography variant="body2">
            不採用の連絡が対象者へ送付されます。
          </Typography>
          <Stack spacing={1}>
            <Typography variant="caption">
              不採用理由は本人には通知されません
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body2">
                不採用理由
                <Typography component="span" color="error">
                  *
                </Typography>
              </Typography>
              <Controller
                name="cancelReason"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <FormControl>
                    <Select
                      {...field}
                      error={!!error}
                      size="small"
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        選択してくだい
                      </MenuItem>
                      {cancelReasons.map((reason, index) => (
                        <MenuItem key={index} value={reason}>
                          {reason}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            </Stack>
          </Stack>
          {watch('cancelReason') === '他' && (
            <Stack>
              <Typography variant="body2">補足コメント</Typography>
              <Controller
                name="otherReason"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    margin="dense"
                    placeholder="コメントを入力してください"
                    rows={3}
                    multiline
                    fullWidth
                  />
                )}
              />
            </Stack>
          )}
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Button
              color="secondary"
              variant="outlined"
              size="small"
              onClick={handleClose}
              sx={{ minWidth: '120px' }}
            >
              キャンセル
            </Button>
            <LoadingButton
              variant="contained"
              size="small"
              loading={isLoading}
              disabled={!formState.isValid}
              onClick={handleSubmit(updateOrders)}
              sx={{ minWidth: '120px' }}
            >
              不採用にする
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
