// Copyright 2022 Co-Lift Inc.
import _, { isNil } from 'lodash';
import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { resetPassword } from '@app/adapter/user-service';
import { ResetPassword } from '@app/components/WaitingResetPassword/ResetPassword';
import { WaitingReset } from '@app/components/WaitingResetPassword/WaitingReset';
import {
  snackbarSeverityState,
  snackbarOpenState,
  snackbarTextState,
} from '@app/domain/app';
import { getVerifyToken } from '@app/domain/network-actions';

export function WaitingForResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const setSnackbarSeverity = useSetRecoilState(snackbarSeverityState);
  const onSubmitCallBack = useCallback(
    async (newPassword: string) => {
      const token = getVerifyToken();
      await resetPassword(newPassword, token)
        .then((data) => {
          if (data.status === 204) {
            setSnackbarSeverity('success');
            setSnackbarText(
              '新しいパスワードを登録しました！ログインしてください'
            );
            navigate('/login');
          } else {
            setSnackbarSeverity('error');
            setSnackbarText('パスワードの登録に失敗しました');
          }
          setSnackbarOpen(true);
        })
        .catch(() => {
          setSnackbarSeverity('error');
          setSnackbarText('パスワードの登録に失敗しました');
          setSnackbarOpen(true);
        });
    },
    [navigate, setSnackbarOpen, setSnackbarSeverity, setSnackbarText]
  );
  const token = getVerifyToken();

  return typeof location.state === 'object' &&
    !isNil(location.state) &&
    location.state?.waiting &&
    _.isEmpty(token) ? (
    <WaitingReset />
  ) : (
    <ResetPassword token={token} onSubmitCallBack={onSubmitCallBack} />
  );
}
