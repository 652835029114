export interface PasswordRule {
  lowercaseChar?: 'ALLOWED' | 'REQUIRED';
  maxLength: number;
  minLength: number;
  numberChar?: 'ALLOWED' | 'REQUIRED';
  symbolChar?: 'ALLOWED' | 'REQUIRED';
  uppercaseChar?: 'ALLOWED' | 'REQUIRED';
}

export const DEFAULT_PASSWORD_RULE: PasswordRule = {
  lowercaseChar: 'REQUIRED',
  maxLength: 24,
  minLength: 8,
  numberChar: 'REQUIRED',
  uppercaseChar: 'ALLOWED',
};

/**
 * return regex for password validation
 * @param passwordRule password rule options
 * @returns regex for password validation
 */
export function generatePasswordPattern(
  passwordRule: PasswordRule = DEFAULT_PASSWORD_RULE
): RegExp {
  const { uppercaseChar, lowercaseChar, numberChar, symbolChar } = passwordRule;

  return new RegExp(
    `^${uppercaseChar === 'REQUIRED' ? '(?=.*[A-Z])' : ''}${
      lowercaseChar === 'REQUIRED' ? '(?=.*[a-z])' : ''
    }${numberChar === 'REQUIRED' ? '(?=.*[0-9])' : ''}${
      symbolChar === 'REQUIRED' ? '(?=.*[.?/-])' : ''
    }[${uppercaseChar ? 'A-Z' : ''}${lowercaseChar ? 'a-z' : ''}${
      numberChar ? '0-9' : ''
    }${symbolChar ? '.?/-_' : ''}]{${passwordRule.minLength},${
      passwordRule.maxLength
    }}$`,
    'g'
  );
}

export function unescapeHtml(value: string): string {
  return value
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'")
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&');
}

export function unescapeSql(value: string): string {
  return value
    .replace(/\\0/g, '\0')
    .replace(/\\n/g, '\n')
    .replace(/\\r/g, '\r')
    .replace(/\\Z/g, '\x1a')
    .replace(/\\'/g, "'")
    .replace(/\\"/g, '"')
    .replace(/\\\\/g, '\\');
}

export function unescapeAll(value: string): string {
  return unescapeHtml(unescapeSql(value));
}

export function truncateString(value: string, maxLength = 100): string {
  let len = 0;
  let truncated = '';

  for (const char of value) {
    if (char.match(/[ -~]/)) {
      len += 0.5;
    } else {
      len += 1;
    }

    if (len > maxLength) {
      return truncated + '...';
    }

    truncated += char;
  }

  return truncated;
}
