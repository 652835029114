import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { GlobalStyles } from '@mui/system';
import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridRowId,
} from '@mui/x-data-grid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  createNewTopic,
  getTopicSubscriptions,
  getUserChatTopicListWithFilter,
} from '@app/adapter/chat-service';
import { getUserFollowList } from '@app/adapter/user-service';
import { FollowButton } from '@app/components/Shared/FollowButton';
import { StarsRating } from '@app/components/Shared/StarsRating';
import {
  loggedInUserState,
  snackbarOpenState,
  snackbarTextState,
} from '@app/domain/app';
import { organization } from '@app/domain/organization';
import {
  useAttributesClinicalDepartment,
  useProductsAdd,
} from '@app/hooks/catalog';
import { Product } from '@app/types/catalog';
import { OrderCancelType, OrderLocalized, OrderStatus } from '@app/types/order';
import { User } from '@app/types/user';
import { getAge } from '@app/utils';
import { getProductDayWeekTitle } from '@app/utils/catalog';
import { formatDate, formatTime, formatWeek } from '@app/utils/format';

export type OrderItem = Omit<
  Partial<OrderLocalized>,
  'customer' | 'lineItem'
> & {
  customer?: Partial<OrderLocalized['customer']>;
  lineItem?: Partial<OrderLocalized['lineItem']>;
};

type OrderTableComponentProps = {
  loading?: boolean;
  mode?: 'order' | 'user' | 'admin';
  onPaginationModelChange?: DataGridProps['onPaginationModelChange'];
  orders: OrderItem[];
  paginationModel?: DataGridProps['paginationModel'];
  rowCount?: number;
  selectionIDs?: string[];
  setSelectedIDs?: React.Dispatch<React.SetStateAction<string[]>>;
  tab?: string;
};

export const OrderTableComponent: React.FC<OrderTableComponentProps> = ({
  setSelectedIDs,
  selectionIDs,
  paginationModel,
  rowCount,
  loading: loadingProp,
  onPaginationModelChange,
  orders,
  mode,
  tab,
}) => {
  const theme = useTheme();
  const navigateTo = useNavigate();
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const loggedInUser: User | null = useRecoilValue(loggedInUserState);
  const organizationState = useRecoilValue(organization);
  const [followList, setFollowList] = useState([] as string[]);
  const [isLoading, setIsLoading] = useState(false);
  const clinicalDepartmentList = useAttributesClinicalDepartment();
  const productIds = useMemo(
    () => orders.map((o) => o.lineItem?.product as string).filter((p) => p),
    [orders]
  );
  const products = useProductsAdd(productIds, mode === 'admin');

  const loadFollowing = useCallback(async () => {
    if (!loggedInUser?.id || !orders.length) {
      return;
    }
    try {
      const userIds = orders
        .map((o) => o.customer?.user?.id ?? '')
        .filter((id) => id);
      if (!userIds.length) {
        return;
      }
      const result = await getUserFollowList(loggedInUser.id, 'user', {
        followIds: userIds,
      });
      setFollowList(result.value.map((f) => f.followId));
    } catch (error) {
      setSnackbarText(
        `フォローの取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [loggedInUser, orders, setSnackbarOpen, setSnackbarText]);

  useEffect(() => {
    void loadFollowing();
  }, [loadFollowing]);

  // メッセージボタン押下時にTopicがあればチャット画面へ、なければTopicとサブスク作成した上でチャット画面へ
  const handleChatButtonClick = async (userId: string) => {
    if (!loggedInUser?.id) return;
    setIsLoading(true);
    try {
      // サブスクしている全Topic取得
      const response = await getUserChatTopicListWithFilter(loggedInUser.id, {
        expandUser: true,
      });
      const topics = response.data.value;

      // Topicのサブスク取得
      const subscriptionsPromises = topics.map((topic) =>
        getTopicSubscriptions(topic.id)
      );
      const subscriptionsResponses = await Promise.all(subscriptionsPromises);
      const allSubscriptions = subscriptionsResponses.flatMap(
        (response) => response.value
      );

      // 応募者のuserIdとサブスクしているuserIdが一致するTopicが存在するかチェック
      const matchingTopic = topics.find((topic) =>
        allSubscriptions.some(
          (sub) => topic.id === sub.topicId && sub.userId === userId
        )
      );

      // 存在すればチャット詳細画面遷移
      if (matchingTopic) {
        navigateTo(`/chats/${matchingTopic.id}`);
      } else {
        try {
          // なければTopicとサブスクを作成
          const newTopicResponse = await createNewTopic(
            loggedInUser.id,
            userId,
            organizationState.name
          );
          navigateTo(`/chats/${newTopicResponse.data.id}`);
          setIsLoading(false);
        } catch (createError) {
          console.error('トピックの作成中にエラーが発生しました:', createError);
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.error('Error fetching topics:', error);
      setIsLoading(false);
    }
  };

  const getStatusLabel = (status: string, cancelType: number) => {
    switch (status) {
      case OrderStatus.PENDING:
        return '応募中';
      case OrderStatus.ACCEPTED:
        return '勤務予定';
      case OrderStatus.PROCESSING:
        return '評価待ち';
      case OrderStatus.CLOSED:
        return '評価済み';
      case OrderStatus.CANCELED:
        if (cancelType === OrderCancelType.DEMAND_STATUS_PENDING) {
          return '応募辞退';
        } else if (cancelType === OrderCancelType.DEMAND_STATUS_ACCEPTED) {
          return '採用辞退';
        } else if (cancelType === OrderCancelType.SUPPLY_STATUS_ACCEPTED) {
          return '採用キャンセル';
        } else if (
          cancelType === OrderCancelType.TIME_UP ||
          cancelType === OrderCancelType.SUPPLY_STATUS_NOT_ACCEPTED
        ) {
          return '不採用';
        }
    }
    return '不明';
  };

  const rows = useMemo(() => {
    return orders.map((order, i) => {
      const departmentValue =
        order.customer?.user?.customFields?.clinicalDepartments?.join('、');
      // TODO:idが入っているのか名前が入っているのかによって要修正
      const department =
        clinicalDepartmentList
          ?.find((c) => c.id === departmentValue)
          ?.items?.join('、') ||
        departmentValue ||
        '-';
      return {
        birthday: order.customer?.user?.customFields?.birthday,
        cancelType: order.customFields?.cancelType || 0,
        createdAt: order.createdAt,
        department,
        gender: order.customer?.user?.customFields?.gender,
        id: order.id,
        name: `${order.customer?.user?.customFields?.familyName ?? ''} ${
          order.customer?.user?.customFields?.firstName ?? ''
        }`,
        orderId: order.id,
        product: products?.find((p) => p.id === order.lineItem?.product),
        rate: undefined as number | undefined, // TODO:仕様が確定したら総合評価を設定する
        status: order.status,
        userId: order.customer?.user?.id,
      };
    });
  }, [orders, products, clinicalDepartmentList]);

  const columns: GridColDef<(typeof rows)[0]>[] = [
    ...(mode === 'user'
      ? []
      : mode === 'admin'
      ? [
          {
            field: 'org',
            headerName: '応募先クリニック',
            renderCell: (params) => {
              const product = params.row.product;
              if (!product?.organization?.name) {
                return <Typography>-</Typography>;
              }
              return <Typography>{product.organization.name}</Typography>;
            },
            width: 180,
          } as GridColDef<(typeof rows)[0]>,
          {
            field: 'product',
            headerName: '応募案件',
            renderCell: (params) => {
              const product = params.row.product;
              if (!product) {
                return <Typography>-</Typography>;
              }
              return (
                <Box whiteSpace="pre-wrap">
                  <Box
                    fontSize="0.7rem"
                    bgcolor={theme.palette.grey[200]}
                    width="fit-content"
                    color={theme.palette.info.dark}
                    px={1}
                    py={0.1}
                    borderRadius={1}
                  >
                    {product.id}
                  </Box>
                  <Box>
                    <Box>
                      {product.category?.name === 'スポット'
                        ? formatDate(product.customFields?.day)
                        : product.category?.name === '定期非常勤'
                        ? formatWeek(product.customFields?.repeatWeek)
                        : ''}
                    </Box>
                    <Box display="inline-block">
                      {formatTime(product.customFields?.startTime)}-
                      {formatTime(product.customFields?.endTime)}
                    </Box>
                  </Box>
                </Box>
              );
            },
            width: 180,
          } as GridColDef<(typeof rows)[0]>,
        ]
      : [
          {
            field: 'product',
            headerName: '勤務日時',
            renderCell: (params) => {
              const product = params.value as Product;
              return product ? (
                <Box whiteSpace="pre-wrap">
                  <Chip
                    label={product.category.name}
                    size="small"
                    sx={{
                      borderRadius: 1,
                      color: theme.palette.info.dark,
                      fontSize: '0.7rem',
                      height: '18px',
                    }}
                  />
                  <Typography variant="body2" component="div">
                    <Box>
                      {product.customFields.day
                        ? formatDate(product.customFields.day)
                        : formatWeek(product.customFields.repeatWeek)}
                    </Box>
                    <Box>
                      {formatTime(product.customFields.startTime)}-
                      {formatTime(product.customFields.endTime)}
                    </Box>
                  </Typography>
                </Box>
              ) : (
                <Typography>-</Typography>
              );
            },
            width: 200,
          } as GridColDef<(typeof rows)[0]>,
        ]),
    {
      field: 'name',
      headerName: '氏名 / 年齢・性別',
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Avatar
              sx={{ backgroundColor: 'lightblue', height: 32, width: 32 }}
            >
              <PersonOutlineIcon />
            </Avatar>
            <Typography variant="body2" component="div">
              <Box>{params.row.name}</Box>
              <Box>
                /{getAge(params.row.birthday)}歳・{params.row.gender}
              </Box>
            </Typography>
          </Stack>
        );
      },
      width: 200 + (mode === 'user' ? 40 : 0),
    },
    tab === 'all'
      ? {
          field: 'status',
          headerName: 'ステータス',
          renderCell: (params) => (
            <Typography variant="body2">
              {getStatusLabel(params.value, params.row.cancelType)}
            </Typography>
          ),
          width: 150 + (mode === 'user' ? 40 : 0),
        }
      : {
          field: 'department',
          headerName: '専門領域',
          renderCell: (params) => (
            <Typography
              sx={{
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '2',
                display: '-webkit-box',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'wrap',
              }}
              variant="body2"
            >
              {params.value}
            </Typography>
          ),
          width: 150 + (mode === 'user' ? 40 : 0),
        },
    {
      field: 'rate',
      headerName: '総合評価',
      renderCell: (params) => {
        const rate = params.row.rate;
        return (
          <Grid
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={0.5}
          >
            {rate === undefined || isNaN(rate) ? (
              <Typography variant="body2">評価データなし</Typography>
            ) : (
              <>
                <Typography component={'span'}>{rate.toFixed(1)}</Typography>
                <StarsRating
                  rate={rate}
                  iconProps={{ sx: { fontSize: 'medium' } }}
                />
              </>
            )}
          </Grid>
        );
      },
      width: 150 + (mode === 'user' ? 40 : 0),
    },
    ...(mode === 'admin'
      ? [
          {
            field: 'status',
            headerName: 'ステータス',
            renderCell: (params) => {
              return (
                <Typography>
                  {getStatusLabel(params.value, params.row.cancelType)}
                </Typography>
              );
            },
            width: 140,
          } as GridColDef<(typeof rows)[0]>,
        ]
      : [
          {
            field: 'createdAt',
            headerName: mode === 'user' ? '最終勤務日時' : '応募日時',
            renderCell: (params) => {
              if (mode === 'user') {
                const product = params.row.product;
                if (
                  !product ||
                  params.row.status === 'CANCELED' ||
                  params.row.status === 'PENDING'
                ) {
                  return <Typography>-</Typography>;
                }
                return (
                  <Grid
                    style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                  >
                    <Typography fontSize="100%">
                      {getProductDayWeekTitle(product)}
                    </Typography>
                    <Typography fontSize="100%">
                      {formatTime(product.customFields.startTime)}~
                      {formatTime(product.customFields.endTime)}
                    </Typography>
                  </Grid>
                );
              }
              return params.value ? (
                <Typography
                  variant="body2"
                  sx={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                >
                  {params.value}
                </Typography>
              ) : (
                <Typography>無効な日付</Typography>
              );
            },
            width: 150 + (mode === 'user' ? 40 : 0),
          } as GridColDef<(typeof rows)[0]>,
        ]),
    {
      field: 'actions',
      headerName: '',
      renderCell: (params) => {
        const isFollowing = followList.some((f) => {
          return f === params.row.userId;
        });
        return (
          <Stack direction="row" spacing={1}>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                const userId = params.row.userId;
                if (userId) {
                  void handleChatButtonClick(userId);
                }
              }}
              sx={{
                fontSize: '0.8rem',
              }}
            >
              メッセージ
            </Button>
            <FollowButton
              variant="outlined"
              color="secondary"
              size="small"
              userId={loggedInUser?.id ?? ''}
              followUserId={params.row.userId ?? ''}
              following={isFollowing}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
              onChangeFollow={(isFollowing) => {
                if (isFollowing) {
                  setFollowList((prev) => [...prev, params.row.userId ?? '']);
                } else {
                  setFollowList((prev) =>
                    prev.filter((f) => f !== (params.row.userId ?? ''))
                  );
                }
              }}
              sx={{
                backgroundColor: isFollowing
                  ? `${theme.customPalette.borderColor}`
                  : `${theme.customPalette.white}`,
                display: mode === 'admin' ? 'none' : undefined,
                fontSize: '0.8rem',
                paddingLeft: isFollowing ? '8px' : '14px',
                paddingRight: isFollowing ? '8px' : '14px',
              }}
            />
          </Stack>
        );
      },
      width: 200 + (mode === 'admin' ? -90 : 0),
    },
  ];

  return (
    <>
      <GlobalStyles
        styles={{
          /* cspell:disable-next-line */
          '.MuiBox-root.css-19kzrtu': {
            padding: 0,
          },
        }}
      ></GlobalStyles>
      <Grid
        container
        sx={{
          '& .MuiDataGrid-root .MuiDataGrid-columnHeaders': {
            backgroundColor: theme.customPalette.borderColor,
            p: '0px !important',
          },
          border: 'none',
          height: 550,
          padding: 0,
          width: '100%',
        }}
      >
        <DataGrid
          rows={rows}
          rowCount={rowCount}
          rowHeight={90}
          columns={columns}
          isRowSelectable={() => !!setSelectedIDs}
          rowSelectionModel={selectionIDs}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 20, 50]}
          localeText={{ noRowsLabel: 'データがありません' }}
          slotProps={{
            pagination: {
              labelRowsPerPage: (
                <label style={{ fontSize: '0.8rem' }}>1ページの表示数</label>
              ),
              showFirstButton: true,
              showLastButton: true,
            },
          }}
          paginationMode={paginationModel ? 'server' : undefined}
          loading={isLoading || loadingProp}
          paginationModel={paginationModel}
          pagination
          hideFooterSelectedRowCount
          disableColumnMenu
          checkboxSelection
          onPaginationModelChange={onPaginationModelChange}
          onRowSelectionModelChange={(newSelection: GridRowId[]) => {
            setSelectedIDs?.(newSelection as string[]);
          }}
          onRowClick={(param) => {
            if (mode === 'admin') {
              navigateTo(`/orders/${param.row.orderId}`);
              return;
            }
            if (mode === 'user') {
              navigateTo(`/users/${param.row.userId}`);
              return;
            }
            navigateTo(`/orders/${param.row.orderId}`);
          }}
          sx={{
            '--unstable_DataGrid-radius': 0,
            '.MuiDataGrid-cell': {
              ':focus-within': { outline: 'none' },
              cursor: 'pointer',
            },
            '.MuiDataGrid-columnHeader': {
              ':focus-within': { outline: 'none' },
            },
            border: 'none',
          }}
        />
      </Grid>
    </>
  );
};
