import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
  Box,
  Button,
  Typography,
  Divider,
  useTheme,
  styled,
  CircularProgress,
} from '@mui/material';
import { useCallback, useEffect, useState, ComponentProps } from 'react';
import { useParams } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';

import { deleteProduct, getSingleProduct } from '@app/adapter/catalog-service';
import { getOrdersListWithFilter } from '@app/adapter/order-service';
// useNavigate を置き換え
import { useNavigateAdminDelegatable as useNavigate } from '@app/administrator/utils/useNavigateAdminDelegatable';
import { ProductDetailComponent } from '@app/components/Catalog/ProductDetailComponent';
import { ProductLayout } from '@app/components/Layout/ProductLayout';
import { OrderTableComponent } from '@app/components/Orders/OrderTableComponent';
import { ConfirmDialog } from '@app/components/Shared/ConfirmDialog';
import {
  snackbarOpenState,
  snackbarSeverityState,
  snackbarTextState,
} from '@app/domain/app';
import { organizationSelector } from '@app/domain/organization';
import {
  useAttributesClinicalDepartment,
  useAttributesJobType,
} from '@app/hooks/catalog';
import { Product, ProductDetailProps } from '@app/types/catalog';
import { OrderLocalized } from '@app/types/order';
import { getProductDayWeekTimeTitle } from '@app/utils/catalog';
import { defaultProductValue } from '@app/utils/constants';
import { convertOrderLocalized } from '@app/utils/order';
import { ProductCreationState } from '@app/views/ProductCreation';

const ColBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const RowBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

const StatusBox = styled(Box)({
  borderRadius: 4,
  borderStyle: 'solid',
  borderWidth: 1,
  height: '1.7rem',
  minWidth: '4.2rem',
  paddingInline: 5,
  textAlign: 'center',
});

export function ProductDetail({
  previewMode,
  onClose,
  productInput,
}: ProductDetailProps) {
  const navigate = useNavigate();
  const organizationState = useRecoilValue(organizationSelector);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const setSnackbarState = useSetRecoilState(snackbarSeverityState);
  const [orders, setOrders] = useState<OrderLocalized[]>([]);
  const theme = useTheme();
  const [shouldShowDeleteDialog, setShouldShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [product, setProduct] = useState<Product | undefined>(undefined);
  const attributeClinicalDepartmentList = useAttributesClinicalDepartment();
  const attributeJobTypeList = useAttributesJobType();
  const clinicalDepartmentList =
    attributeClinicalDepartmentList
      ?.filter((c) => product?.attributes.some((a) => a.attributeId === c.id))
      .map((c) => c.items?.[0].value ?? '') ?? [];
  const jobTypeList =
    attributeJobTypeList
      ?.filter((c) => product?.attributes.some((a) => a.attributeId === c.id))
      .map((c) => c.name) ?? [];
  const { productId = '' } = useParams();
  const [isLoadingOrder, setIsLoadingOrder] = useState<boolean>(false);
  const [orderRowCount, setOrderRowCount] = useState(0);
  const [orderPaginationModel, setOrderPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const handleDeleteClick: NonNullable<
    ComponentProps<typeof Button>['onClick']
  > = useCallback(async () => {
    setShouldShowDeleteDialog(() => true);
  }, []);

  const handleCopyClick: NonNullable<ComponentProps<typeof Button>['onClick']> =
    useCallback(async () => {
      navigate('/products/register', {
        state: { id: productId, mode: 'copy' } as ProductCreationState,
      });
    }, [navigate, productId]);

  const handleClickPreview: NonNullable<
    ComponentProps<typeof Button>['onClick']
  > = useCallback(() => {
    // TODO:ドクター画面から見た時のデザインでの確認画面に遷移する
    alert('Coming soon 👷🏼‍♂️');
  }, []);

  const handleClickEdit: NonNullable<ComponentProps<typeof Button>['onClick']> =
    useCallback(() => {
      if (orders.length) {
        navigate(`/products/${productId}/edit/status`);
      } else {
        navigate(`/products/${productId}/edit`);
      }
    }, [navigate, productId, orders]);

  const handleCloseDeleteDialog: ComponentProps<
    typeof ConfirmDialog
  >['onClose'] = useCallback(
    async (confirm) => {
      if (!confirm) {
        setShouldShowDeleteDialog(false);
        return;
      }
      try {
        if (!product) {
          return;
        }
        setIsDeleting(true);
        await deleteProduct(product.id);
        setSnackbarText(`${product.id}の求人を削除しました。`);
        setSnackbarState('info');
        setSnackbarOpen(true);
        navigate('/products');
      } catch (error) {
        setSnackbarText(
          `求人の削除に失敗しました。, ${
            error instanceof Error ? error.message : error
          }`
        );
        setSnackbarOpen(true);
      } finally {
        setIsDeleting(false);
        setShouldShowDeleteDialog(false);
      }
    },
    [product, navigate, setSnackbarOpen, setSnackbarState, setSnackbarText]
  );

  const loadProduct = useCallback(async () => {
    if (productInput) {
      const product: Product = {
        ...defaultProductValue,
        ...productInput,
        geoCoordinatesField: defaultProductValue.geoCoordinatesField,
        id: '求人ID',
        publication: {
          ...defaultProductValue.publication,
          ...productInput.publication,
        },
        variants: [
          {
            ...productInput.variants[0],
            id: '',
            title: '',
          },
        ],
      };
      setProduct(product);
      return product;
    }
    try {
      const result = await getSingleProduct(productId);
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      setProduct(result.data);
      return result.data;
    } catch (error) {
      setSnackbarText(
        `求人の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [productId, productInput, setSnackbarText, setSnackbarOpen]);

  const loadOrders = useCallback(
    async (productId: string | undefined) => {
      if (!productId) {
        return;
      }
      try {
        setIsLoadingOrder(true);
        const result = await getOrdersListWithFilter(organizationState.id, {
          order: 'createdAt',
          pageNumber: orderPaginationModel.page,
          pageSize: orderPaginationModel.pageSize,
          productId,
        });
        if (result.status !== 200) {
          throw new Error(`${result.status} ${result.statusText}`);
        }
        const orders: OrderLocalized[] = result.data.value.map((order) =>
          convertOrderLocalized(order)
        );

        setOrders(orders);
        setOrderRowCount(result.data.total);
      } catch (error: unknown) {
        setSnackbarText(
          `応募の取得に失敗しました, ${
            error instanceof Error ? error.message : error
          }`
        );
        setSnackbarOpen(true);
      } finally {
        setIsLoadingOrder(false);
      }
    },
    [
      orderPaginationModel,
      organizationState,
      setOrders,
      setOrderRowCount,
      setSnackbarOpen,
      setSnackbarText,
    ]
  );

  // データ読み込み済みかどうかの状態
  const [isLoadData, setIsLoadData] = useState(false);

  useEffect(() => {
    void (async () => {
      const product = await loadProduct();
      await loadOrders(product?.id);
      setIsLoadData(true);
    })();
  }, [loadProduct, loadOrders]);

  if (!isLoadData) {
    return (
      <ProductLayout>
        <CircularProgress sx={{ m: 'auto' }} />
      </ProductLayout>
    );
  }

  if (!product) {
    return (
      <ProductLayout>
        <Typography sx={{ m: 'auto' }}>
          ID:{productId}の求人を取得できませんでした
        </Typography>
      </ProductLayout>
    );
  }

  return (
    <>
      <ProductLayout>
        <RowBox gap={1}>
          <ColBox>
            <ColBox color={theme.palette.grey[600]}>
              <Typography>{product.id}/</Typography>
              <Typography>{product.name}</Typography>
            </ColBox>

            <RowBox alignItems="baseline" gap={2}>
              {previewMode ? (
                <StatusBox
                  color={theme.palette.primary.main}
                  borderColor={theme.palette.primary.main}
                >
                  プレビュー
                </StatusBox>
              ) : product.publication?.status === 'ACTIVE' ? (
                <StatusBox
                  color={theme.palette.primary.main}
                  borderColor={theme.palette.primary.main}
                >
                  掲載中
                </StatusBox>
              ) : product.publication?.status === 'ARCHIVED' ? (
                <StatusBox
                  color={theme.customPalette.alert}
                  border={theme.customPalette.alert}
                >
                  終了
                </StatusBox>
              ) : product.publication?.status === 'DRAFT' ? (
                <StatusBox
                  color={theme.palette.grey[500]}
                  border={theme.palette.grey[500]}
                >
                  下書き
                </StatusBox>
              ) : undefined}
              <ColBox>
                <Typography fontSize={'1.8rem'}>
                  {getProductDayWeekTimeTitle(product)}
                </Typography>
                <Typography fontSize={'1.8rem'}>
                  ￥{product.customFields?.calculatedDairyPay?.toLocaleString()}
                </Typography>
                <Typography>
                  {clinicalDepartmentList.join('・')}/{jobTypeList.join('・')}
                </Typography>
              </ColBox>
            </RowBox>
          </ColBox>

          <RowBox gap={2} ml="auto" py={1}>
            {previewMode ? (
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                sx={{ width: '8rem' }}
                onClick={onClose}
              >
                <RowBox alignItems="center" gap={1}>
                  <HighlightOffIcon />
                  閉じる
                </RowBox>
              </Button>
            ) : (
              <>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    bgcolor: theme.palette.common.white,
                    border: `solid 1px ${theme.customPalette.alert}`,
                    color: theme.customPalette.alert,
                    width: '5rem',
                  }}
                  onClick={handleDeleteClick}
                  disabled={!!orders.length}
                >
                  削除
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  sx={{ width: '8rem' }}
                  onClick={handleCopyClick}
                >
                  複製
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  sx={{
                    display: 'none', // TODO:プレビュー機能が実装されたら削除する
                    width: '8rem',
                  }}
                  onClick={handleClickPreview}
                >
                  プレビュー
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  sx={{ width: '8rem' }}
                  onClick={handleClickEdit}
                  // disabled={!!orders.length}
                >
                  編集
                </Button>
              </>
            )}
          </RowBox>
        </RowBox>

        {!previewMode && orders.length ? (
          <OrderTableComponent
            orders={orders}
            rowCount={orderRowCount}
            paginationModel={orderPaginationModel}
            onPaginationModelChange={setOrderPaginationModel}
            loading={isLoadingOrder}
          />
        ) : (
          <ColBox
            alignItems="center"
            justifyContent="center"
            height="5rem"
            bgcolor={theme.palette.grey[100]}
            border={`solid 2px ${theme.palette.grey[300]}`}
            borderRadius={2}
          >
            <Typography fontWeight={600}>まだ応募がありません</Typography>
          </ColBox>
        )}

        <Divider />

        <ColBox gap={4} pb={4}>
          <Typography variant="h5">募集詳細</Typography>
          <ProductDetailComponent
            product={product}
            attributeClinicalDepartmentList={attributeClinicalDepartmentList}
            attributeJobTypeList={attributeJobTypeList}
          />
        </ColBox>
      </ProductLayout>
      <ConfirmDialog
        title="本当に削除しますか？"
        okButtonText="削除"
        okButtonLoading={isDeleting}
        open={shouldShowDeleteDialog}
        onClose={handleCloseDeleteDialog}
      />
    </>
  );
}
