import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { AsYouType } from 'libphonenumber-js';
import React, { ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { PageTitle } from '@app/components/Shared/PageTitle';
import { loggedInUserState } from '@app/domain/app';
import { organization as organizationAtom } from '@app/domain/organization';
import { ORGANIZATION_STATUS_LABELS } from '@app/types/organization';
import { useSetSnackbar } from '@app/utils/useSetSnackbar';
import { OrganizationListLocationState } from '@app/views/OrganizationList';

const RowItem: React.FC<{
  children?: ReactNode;
  title: string;
}> = ({ children = null, title }) => {
  return (
    <>
      <Stack direction="row" alignItems="center">
        <Typography variant="body2" width={180}>
          {title}
        </Typography>
        {children}
      </Stack>
    </>
  );
};

export function OrganizationDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const setSnackbar = useSetSnackbar();
  const loggedInUser = useRecoilValue(loggedInUserState);
  const organizationInfo = useRecoilValue(organizationAtom);

  useEffect(() => {
    const locationState = location.state as OrganizationListLocationState;
    if (!locationState) {
      return;
    }
    // 編集画面から戻るとき
    if (locationState?.success) {
      setSnackbar(true, '医療機関情報を保存しました', 'success');
    }
  }, [location, setSnackbar]);

  //
  // Render
  //
  if (!loggedInUser || !organizationInfo) {
    return null;
  }

  const formattedPhoneNumber = // 電話番号format (とりあえず日本限定)
    (organizationInfo?.phoneNumber &&
      new AsYouType('JP').input(organizationInfo.phoneNumber)) ||
    '';
  const joinedAddress = [
    organizationInfo.customFields?.addressByCity,
    organizationInfo.addressLine3,
    organizationInfo.customFields?.addressLine4,
  ]
    .filter((item) => !!item)
    .join('');

  return (
    <>
      <Stack direction="row" spacing={2} mb={2}>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => navigate('/organizations')}
          sx={{ height: '40px', width: '40px' }}
        >
          <ArrowBackIcon />
        </Button>
        <PageTitle title="医療機関管理" />
      </Stack>
      <Card>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          m={2}
        >
          <Typography variant="h5">{organizationInfo.name || ''}</Typography>
          <Stack>
            <Button
              size="small"
              color="secondary"
              variant="outlined"
              onClick={() =>
                navigate(`/organizations/${organizationInfo.id}/edit`)
              }
              sx={{ width: 96 }}
            >
              編集
            </Button>
          </Stack>
        </Stack>
        <Divider />
        <Typography m={2}>
          {`ステータス ${
            (organizationInfo.status &&
              ORGANIZATION_STATUS_LABELS[organizationInfo.status]) ||
            ''
          }`}
        </Typography>
        <Divider />
        <CardContent>
          {organizationInfo.iconImageUri && (
            <div style={{ height: '300px', width: '100%' }}>
              <img
                alt="CLINIC"
                style={{
                  height: '100%',
                  objectFit: 'contain',
                  width: 'auto',
                }}
                src={organizationInfo.iconImageUri}
              />
            </div>
          )}
          <Stack spacing={2.5} divider={<Divider />} mt={2.5} width="80%">
            <RowItem title="医療機関ID">
              <Typography>{organizationInfo.id}</Typography>
            </RowItem>
            <RowItem title="法人名">
              <Typography>{organizationInfo.description || ''}</Typography>
            </RowItem>
            <RowItem title="医療機関名">
              <Typography>{organizationInfo.name}</Typography>
            </RowItem>
            <RowItem title="担当者連絡先電話番号">
              <Typography>{formattedPhoneNumber}</Typography>
            </RowItem>
            <RowItem title="住所">
              <Typography>{joinedAddress}</Typography>
            </RowItem>
            <RowItem title="アクセス情報">
              <Typography>
                {organizationInfo.customFields?.access || ''}
              </Typography>
            </RowItem>
            <RowItem title="標榜科目">
              <Typography>
                {organizationInfo.customFields?.department}
              </Typography>
            </RowItem>
            <RowItem title="院長名">
              <Typography>
                {organizationInfo.customFields?.ownerName?.trim() ||
                  loggedInUser.name}
              </Typography>
            </RowItem>
            <RowItem title="担当者メールアドレス">
              <Typography>{loggedInUser.email}</Typography>
            </RowItem>
            <RowItem title="検査設備">
              <Typography>
                {Array.isArray(organizationInfo.customFields?.equipments) &&
                  organizationInfo.customFields?.equipments.join(', ')}
              </Typography>
            </RowItem>
            <RowItem title="検査設備（その他）">
              <Typography>
                {organizationInfo.customFields?.equipmentOther}
              </Typography>
            </RowItem>
            <RowItem title="カルテ">
              <Typography>
                {organizationInfo.customFields?.electronicCharts?.join(', ') ||
                  ''}
              </Typography>
            </RowItem>
            <RowItem title="電子カルテメーカー">
              <Typography>
                {organizationInfo.customFields?.electronicChartMaker}
              </Typography>
            </RowItem>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
