import { Link, SxProps, Theme } from '@mui/material';

import { adClick } from '@app/adapter/user-service';
import { Ad } from '@app/types/ad';
import { isError } from '@app/utils/error';

interface AdvertisingProps {
  item?: Ad;
  sx?: SxProps<Theme>;
}
export function Advertising({ item, sx }: AdvertisingProps) {
  const handleClickAd = async () => {
    if (!item?.id) return;
    try {
      await adClick(item.id);
    } catch (e) {
      if (isError(e)) {
        throw new Error(e.message);
      }
    }
  };

  return (
    <>
      {item && (
        <Link
          target="_blank"
          rel="nofollow"
          href={item?.destinationUrl || undefined}
          onClick={handleClickAd}
          sx={{ ...sx }}
        >
          <img src={item.imageUrl} alt={item?.name} />
        </Link>
      )}
    </>
  );
}
