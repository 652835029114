import { atom, selector } from 'recoil';

import { Paginated } from '@app/types/common';
import { Organization, OrganizationUser } from '@app/types/organization';

/**
 * blocks-5f90
 *
 * Stores all organizations.
 */
export const organizationsState = atom<Paginated<Organization[]>>({
  default: {
    '@nextLink': '',
    count: 1,
    total: 0,
    value: [],
  },
  key: 'organizationsState',
});

// not paginated
export const allOrganizationsAtom = atom<Array<Organization>>({
  default: [],
  key: 'allOrganizationsAtom',
});

/**
 * blocks-5f90
 *
 * Stores organizations response.
 */
export const organizationSelector = selector<Organization>({
  get: ({ get }) => {
    const organizations = get(organization);
    // TODO: We don't know about the sort. Just return the first element for now.
    return organizations;
  },
  key: 'organizationSelector',
});

export const ORGANIZATION_DEFAULT = {
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  certificateImageUri: '',
  customFields: {},
  iconImageUri: '',
  id: '',
  name: '',
  phoneNumber: '',
  postalCode: '',
};
export const organization = atom<Organization>({
  default: ORGANIZATION_DEFAULT,
  key: 'organization',
});

export const organizationUsers = atom<Paginated<OrganizationUser>>({
  default: {
    count: 0,
    total: 0,
    value: [],
  },
  key: 'organizationUsersState',
});
