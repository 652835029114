import _ from 'lodash';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { checkToken } from '@app/adapter/auth-service';
import {
  setRequestInterceptor,
  setResponseInterceptor,
} from '@app/adapter/axios';
import { getOrganizationDetails } from '@app/adapter/organization-service';
import { getUser } from '@app/adapter/user-service';
import { LoadingSpinner } from '@app/components/Shared/LoadingSpinner';
import {
  loggedInUserState,
  isValidUserAuthInfoState,
  userAuthInfoSelector,
  useClearAuthStateAndStorage,
} from '@app/domain/app';
import { generateFingerPrint } from '@app/domain/fingerprint';
import { organization, allOrganizationsAtom } from '@app/domain/organization';
import { Organization } from '@app/types/organization';
import { getStoredAccessToken, getStoredUserId } from '@app/utils/auth';
import { isVendorUser } from '@app/utils/is_vendor_user';
import { ERROR_MESSAGE } from '@app/utils/message';
import { useSetSnackbar } from '@app/utils/useSetSnackbar';

const MAX_FETCH_ORGANIZATION_SIZE = 50;

/**
 * blocks-5f90
 */
export function OrganizationUserRoute({
  children,
}: {
  children?: React.ReactNode;
}): ReactElement {
  const navigate = useNavigate();
  const [loadable, setLoadable] = useState<'loading' | 'error' | 'hasValue'>(
    'loading'
  );
  const isValidUserAuthInfo = useRecoilValue(isValidUserAuthInfoState);
  const setUserAuthInfo = useSetRecoilState(userAuthInfoSelector);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);
  const setOrganization = useSetRecoilState(organization);
  const setAllOrganizations = useSetRecoilState(allOrganizationsAtom);
  const clearAuthStateAndStorage = useClearAuthStateAndStorage();
  const setSnackbar = useSetSnackbar();

  const validateToken = useCallback(async () => {
    try {
      const accessToken = getStoredAccessToken();
      if (!accessToken) {
        return false;
      }
      const fingerprint = await generateFingerPrint();
      const checkTokenResponse = await checkToken(accessToken, fingerprint);

      const userId = checkTokenResponse.data.userId;
      if (userId !== getStoredUserId()) {
        return false;
      }
      setRequestInterceptor({ accessToken, fingerprint });

      setUserAuthInfo({
        accessToken,
        fingerprint,
        id: userId,
      });
      const userResponse = await getUser(userId);
      if (!isVendorUser(userResponse.data.typeId)) {
        throw new Error(ERROR_MESSAGE.INVALID_USER_TYPE);
      }

      setLoggedInUser(userResponse.data);

      const orgResponse = await getOrganizationDetails(userId, {
        params: { $skip: 0, $top: MAX_FETCH_ORGANIZATION_SIZE }, // 先頭から取れるだけ取る
      });

      const { total, value } = orgResponse.data;
      // Organization未登録
      if (total === 0) {
        return false;
      }
      // 複数の Organization を切り替えられるようにするため
      if (total > MAX_FETCH_ORGANIZATION_SIZE) {
        console.warn(
          '医療機関が多すぎるため、すべてを取得出来ません',
          orgResponse.data
        ); // TODO: 仕様として登録可能な上限を決めるか, or paginateするか
      }
      if (Array.isArray(value)) {
        const allOrganizations = orgResponse?.data?.value;
        setAllOrganizations(allOrganizations);
      }

      if (!_.isEmpty(_.get(orgResponse, 'data.value', ''))) {
        //TODO: UPDATE SHAPE OF ORGANIZATION AND STATE
        // TODO: resolve as
        setOrganization(
          _.get(orgResponse, 'data.value[0]') as unknown as Organization
        );
      }
      return true;
    } catch (error) {
      return false;
    }
  }, [setUserAuthInfo, setLoggedInUser, setOrganization, setAllOrganizations]);

  useEffect(() => {
    if (loadable === 'hasValue') return;
    if (loadable === 'error') {
      clearAuthStateAndStorage();
      navigate('/login');
      return;
    }

    const execute = async () => {
      // Step 1: Check the user's auth information.
      if (!isValidUserAuthInfo) {
        setLoadable('error');
      }

      const isValid = await validateToken();
      if (isValid) {
        setResponseInterceptor(() => {
          // on unauthorized
          clearAuthStateAndStorage();
          setTimeout(() => setSnackbar(true, ERROR_MESSAGE.TOKEN_EXPIRED), 300); // 他のsnackbarと競合対策で遅延させる
          navigate('/login');
        });

        setLoadable('hasValue');
        return;
      }
      setLoadable('error');
      return;
    };
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    execute();
  }, [
    loadable,
    isValidUserAuthInfo,
    validateToken,
    navigate,
    clearAuthStateAndStorage,
    setSnackbar,
  ]);
  return loadable === 'hasValue' ? <>{children}</> : <LoadingSpinner />;
}
