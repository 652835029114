import {
  ArrowBack as ArrowBackIcon,
  Person as PersonIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ComponentProps, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
  getSingleProduct,
  setProductsStatus,
} from '@app/adapter/catalog-service';
import { getSingleOrder, updateOrderStatus } from '@app/adapter/order-service';
import { CancelDialog } from '@app/components/Orders/CancelDialog';
import { ReviewDialog } from '@app/components/Review/ReviewDialog';
import { ConfirmDialog } from '@app/components/Shared/ConfirmDialog';
import { FollowButton } from '@app/components/Shared/FollowButton';
import { PageTitle } from '@app/components/Shared/PageTitle';
import { UserProfile } from '@app/components/Users/UserProfile';
import { UserReviews } from '@app/components/Users/UserReviews';
import { loggedInUserState } from '@app/domain/app';
import { organizationSelector } from '@app/domain/organization';
import {
  useAttributesClinicalDepartment,
  useAttributesJobType,
  useProductsAdd,
} from '@app/hooks/catalog';
import { useReviewsAdd } from '@app/hooks/review';
import { Product, ProductPublicationStatus } from '@app/types/catalog';
import {
  OrderStatus,
  OrderLocalized,
  SupportedOrderStatus,
} from '@app/types/order';
import { getProductDayWeekTimeTitle } from '@app/utils/catalog';
import { isError } from '@app/utils/error';
import { restoreSanitizedString } from '@app/utils/format';
import { convertOrderLocalized, getOrderStatusLabel } from '@app/utils/order';
import { useSetSnackbar } from '@app/utils/useSetSnackbar';

const reviewsAddCount = 10;

export function OrderDetails() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id: orderId } = useParams();
  const setSnackbar = useSetSnackbar();
  const organizationState = useRecoilValue(organizationSelector);
  const loggedInUser = useRecoilValue(loggedInUserState);
  const attributeClinicalDepartmentList = useAttributesClinicalDepartment();
  const attributeJobTypeList = useAttributesJobType();
  const [order, setOrder] = useState<OrderLocalized>();
  const [product, setProduct] = useState<Product>();
  const [reviewsDisplayCount, setReviewsDisplayCount] =
    useState(reviewsAddCount);
  /** 評価リスト、評価件数 */
  const { reviews, reviewsTotal, setReviews, setReviewsTotal } = useReviewsAdd(
    order?.customer?.user?.id,
    reviewsDisplayCount,
    100
  );
  /** 評価に割りついた求人リスト */
  const reviewProducts = useProductsAdd(
    reviews?.map((r) => r.customFields?.productId)
  );
  const [isOpenReviewDialog, setIsOpenReviewDialog] = useState(false);
  const [isOpenAcceptedDialog, setIsOpenAcceptedDialog] = useState(false);
  const [isOpenCanceledDialog, setIsOpenCanceledDialog] = useState(false);
  const [isLoadingDialog, setIsLoadingDialog] = useState(false);
  const [isProductArchived, setIsProductArchived] = useState(false);
  const userName = `${order?.customer?.user?.customFields?.familyName} ${order?.customer?.user?.customFields?.firstName}`;
  const clinicalDepartmentList =
    attributeClinicalDepartmentList
      ?.filter((c) => product?.attributes.some((a) => a.attributeId === c.id))
      .map((c) => c.items?.[0].value ?? '') ?? [];
  const jobTypeList =
    attributeJobTypeList
      ?.filter((c) => product?.attributes.some((a) => a.attributeId === c.id))
      .map((c) => c.name) ?? [];

  const loadOrder = useCallback(async () => {
    try {
      if (!orderId) return;
      const result = await getSingleOrder(organizationState.id, orderId);
      setOrder(convertOrderLocalized(result.data));
    } catch (e) {
      if (isError(e)) {
        console.error(e);
      }
      setSnackbar(true, '応募の取得に失敗しました', 'error');
    }
  }, [organizationState.id, orderId, setSnackbar]);

  useEffect(() => {
    void loadOrder();
  }, [loadOrder]);

  const loadProduct = useCallback(async () => {
    try {
      if (!order?.lineItem?.product) return;
      const result = await getSingleProduct(order.lineItem.product);
      const product = result.data;
      setProduct(product);
    } catch (e) {
      if (isError(e)) {
        console.error(e);
      }
      setSnackbar(true, '求人の取得に失敗しました', 'error');
    }
  }, [order, setSnackbar]);

  useEffect(() => {
    void loadProduct();
  }, [loadProduct]);

  const handleClickReviewsButton: ComponentProps<typeof Button>['onClick'] =
    useCallback(() => {
      setIsOpenReviewDialog(true);
    }, []);

  const handleClickChatButton: ComponentProps<typeof Button>['onClick'] =
    useCallback(() => {
      navigate('/chats');
    }, [navigate]);

  const statusChange = useCallback(
    async (status: SupportedOrderStatus) => {
      try {
        if (!order) return;
        await updateOrderStatus(
          organizationState.id,
          order.id,
          status as SupportedOrderStatus
        );
        setOrder({ ...order, status });
      } catch (e) {
        if (isError(e)) {
          console.error(e);
        }
        setSnackbar(true, '応募の更新に失敗しました', 'error');
      }
    },
    [organizationState.id, order, setSnackbar]
  );

  const updateProductArchived = useCallback(async () => {
    if (!product?.id) return;
    try {
      await setProductsStatus(
        organizationState.id,
        [product.id],
        ProductPublicationStatus.ARCHIVED
      );
    } catch (e) {
      if (isError(e)) {
        console.error(e);
      }
      setSnackbar(true, '求人の更新に失敗しました', 'error');
    }
  }, [product, organizationState, setSnackbar]);

  const handleClickAcceptedButton: ComponentProps<typeof Button>['onClick'] =
    useCallback(() => {
      setIsOpenAcceptedDialog(true);
    }, []);

  const handleChangeProductArchived: NonNullable<
    ComponentProps<typeof Checkbox>['onChange']
  > = useCallback((e, checked) => {
    setIsProductArchived(checked);
  }, []);

  const handleClickNextUserReview: ComponentProps<
    typeof UserReviews
  >['onClickNext'] = useCallback(() => {
    setReviewsDisplayCount((prev) => prev + reviewsAddCount);
  }, [setReviewsDisplayCount]);

  const handleChangeUserReview: NonNullable<
    ComponentProps<typeof UserReviews>['onChange']
  > = useCallback(
    (reviews) => {
      setReviews(reviews);
      setSnackbar(true, `${userName}さんを評価しました。`, 'info');
      void loadOrder();
    },
    [setReviews, userName, setSnackbar, loadOrder]
  );

  const handleClosReviewDialog: ComponentProps<typeof ReviewDialog>['onClose'] =
    useCallback(
      (result, review) => {
        setIsOpenReviewDialog(false);
        if (result === 'confirm' && review) {
          setReviewsTotal((prev) => (prev ?? 0) + 1);
          setReviews((prev) => [review, ...(prev ?? [])]);
        }
        if (result === 'confirm' || result === 'decline') {
          setOrder((prev) =>
            prev ? { ...prev, status: OrderStatus.CLOSED } : prev
          );
          setSnackbar(
            true,
            result === 'confirm'
              ? `${userName}さんを評価しました。`
              : `${userName}さんの評価を辞退しました。`,
            'info'
          );
          void loadOrder();
        }
      },
      [setReviewsTotal, setReviews, userName, setSnackbar, loadOrder]
    );

  const handleCloseAcceptedDialog: ComponentProps<
    typeof ConfirmDialog
  >['onClose'] = useCallback(
    async (confirm) => {
      if (!confirm) {
        setIsOpenAcceptedDialog(false);
        return;
      }
      setIsLoadingDialog(true);

      if (product?.category.name === 'スポット') {
        await statusChange(OrderStatus.ACCEPTED);
      } else if (product?.category.name === '定期非常勤') {
        await statusChange(OrderStatus.PROCESSING);
      }

      if (isProductArchived) {
        await updateProductArchived();
      }
      // TODO: 採用メールを送信する
      setIsLoadingDialog(false);
      setIsOpenAcceptedDialog(false);
      setSnackbar(true, `${userName}さんを採用しました。`, 'info');
    },
    [
      product?.category.name,
      isProductArchived,
      userName,
      setSnackbar,
      statusChange,
      updateProductArchived,
    ]
  );

  const handleClickCanceledButton: ComponentProps<typeof Button>['onClick'] =
    useCallback(() => {
      setIsOpenCanceledDialog(true);
    }, []);

  const handleSubmitCancelDialog = async () => {
    setIsOpenCanceledDialog(false);
    void loadOrder();
  };

  return (
    <>
      <Box display="flex" mb={3}>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => navigate(-1)}
          sx={{ height: '40px', mr: 2, width: '40px' }}
        >
          <ArrowBackIcon />
        </Button>
        <PageTitle title="応募" />
      </Box>
      {order ? (
        <Card sx={{ p: 3 }}>
          <Stack spacing={3} divider={<Divider />}>
            {product && (
              <Stack direction="row" justifyContent="space-between">
                <Box>
                  <Typography variant="body2" color={theme.palette.grey[500]}>
                    {product.id}
                  </Typography>
                  <Typography fontSize={20}>
                    {getProductDayWeekTimeTitle(product)}
                  </Typography>
                  <Typography>
                    ￥
                    {product.customFields?.calculatedDairyPay?.toLocaleString()}
                  </Typography>
                  <Typography>
                    {clinicalDepartmentList.join('・')}/{jobTypeList.join('・')}
                  </Typography>
                </Box>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={() => {
                    navigate(`/products/${product.id}`);
                  }}
                  sx={{ width: '100px' }}
                >
                  案件詳細
                </Button>
              </Stack>
            )}
            <Box>
              <Stack direction="row" justifyContent="space-between">
                <Typography fontSize={20}>応募者</Typography>
                <Stack direction="row" spacing={1}>
                  <FollowButton
                    variant="outlined"
                    color="secondary"
                    size="small"
                    userId={loggedInUser?.id ?? ''}
                    followUserId={order?.customer?.user?.id ?? ''}
                    sx={{ width: '100px' }}
                  />
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    disabled={
                      order.status !== 'PROCESSING' && order.status !== 'CLOSED'
                    }
                    onClick={handleClickReviewsButton}
                    sx={{ width: '100px' }}
                  >
                    評価を書く
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={handleClickChatButton}
                    sx={{
                      display: 'none', // TODO:正式実装されたら削除する
                      width: '100px',
                    }}
                  >
                    メッセージ
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    disabled={order.status !== 'PENDING'}
                    onClick={handleClickAcceptedButton}
                    sx={{ width: '100px' }}
                  >
                    採用
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    disabled={
                      order.status !== 'PENDING' && order.status !== 'ACCEPTED'
                    }
                    onClick={handleClickCanceledButton}
                    sx={{
                      width: order.status !== 'ACCEPTED' ? '100px' : 'auto',
                    }}
                  >
                    {order.status !== 'ACCEPTED' ? '不採用' : '採用キャンセル'}
                  </Button>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <Avatar
                  sx={{ backgroundColor: 'lightblue', height: 32, width: 32 }}
                >
                  <PersonIcon />
                </Avatar>
                {!!order.customer?.user.customFields?.deleted_at && (
                  <Typography>（退会済ユーザー）</Typography>
                )}
                <Typography>
                  {order.customer?.user.customFields?.familyName}
                </Typography>
                <Typography>
                  {order.customer?.user.customFields?.firstName}
                </Typography>
                <Typography>/</Typography>
                <Typography>
                  {order.customer?.user.customFields?.familyNameKana}
                </Typography>
                <Typography>
                  {order.customer?.user.customFields?.firstNameKana}
                </Typography>
              </Stack>
            </Box>
            <Stack spacing={5}>
              <Stack spacing={2} mb={4}>
                <Typography fontSize="20px">応募情報</Typography>
                <Box>
                  <Typography variant="body2">ステータス</Typography>
                  <Typography>
                    {getOrderStatusLabel(
                      order.status,
                      order.customFields?.cancelType
                    )}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">理由・コメント</Typography>
                  <Typography sx={{ whiteSpace: 'pre-wrap' }}>
                    {restoreSanitizedString(
                      order.customFields?.cancelMessage || '-'
                    )}
                  </Typography>
                </Box>
              </Stack>
              <UserProfile
                user={order.customer?.user}
                orderStatus={order.status}
                attachment={order.attachment}
              />
              <UserReviews
                reviewProducts={reviewProducts}
                reviews={reviews}
                reviewsDisplayCount={reviewsDisplayCount}
                reviewsTotal={reviewsTotal}
                loading={reviewsTotal === undefined}
                onClickNext={handleClickNextUserReview}
                onChange={handleChangeUserReview}
              />
            </Stack>
          </Stack>
        </Card>
      ) : (
        <Card sx={{ py: 5, textAlign: 'center' }}>
          <CircularProgress />
        </Card>
      )}
      {order && (
        <ReviewDialog
          open={isOpenReviewDialog}
          userId={order.customer.user.id}
          productId={product?.id}
          orderId={order.id}
          onClose={handleClosReviewDialog}
        />
      )}
      <ConfirmDialog
        title={`${userName}さんを採用しますか？`}
        okButtonText="採用する"
        okButtonLoading={isLoadingDialog}
        open={isOpenAcceptedDialog}
        onClose={handleCloseAcceptedDialog}
      >
        <Stack spacing={1}>
          <Typography>
            「採用する」を押すと、{userName}さんに採用通知が送られます。
          </Typography>
          <Typography variant="body2" color={theme.palette.grey[500]}>
            ※採用後のキャンセルはご遠慮ください。採用後にクリニック側からキャンセルする場合、休業補償のリスク・責任が発生します。
          </Typography>
          <FormControlLabel
            sx={{ width: 'fit-content' }}
            control={
              <Checkbox
                checked={isProductArchived}
                onChange={handleChangeProductArchived}
              />
            }
            label="対象の求人については応募を締め切る"
          />
        </Stack>
      </ConfirmDialog>
      {order && (
        <CancelDialog
          orders={[order]}
          open={isOpenCanceledDialog}
          onClose={() => setIsOpenCanceledDialog(false)}
          onSubmit={handleSubmitCancelDialog}
        />
      )}
    </>
  );
}
