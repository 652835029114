import { Box, Typography, Grid, styled } from '@mui/material';
import { ComponentProps } from 'react';

import { Attribute, Product } from '@app/types/catalog';
import { getProductDayWeekTitle } from '@app/utils/catalog';
import { convertNewLineToBr } from '@app/utils/components';
import { productCommutingList } from '@app/utils/constants';
import { formatDate, formatTime } from '@app/utils/format';

const ColBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const RowBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

function DetailGridItem({
  label,
  children,
  ...props
}: ComponentProps<typeof Grid> & { label: string }) {
  return (
    <Grid
      item
      xs={6}
      display="flex"
      flexDirection="column"
      gap={1}
      {...props}
      pr={24}
    >
      <Typography fontWeight={700}>{label}</Typography>
      <ColBox>{children}</ColBox>
    </Grid>
  );
}

export interface ProductDetailComponentProps {
  attributeClinicalDepartmentList?: Attribute[];
  attributeJobTypeList?: Attribute[];
  product: Product;
}

export function ProductDetailComponent({
  product,
  attributeClinicalDepartmentList,
  attributeJobTypeList,
}: ProductDetailComponentProps) {
  const clinicalDepartmentList =
    attributeClinicalDepartmentList
      ?.filter((c) => product?.attributes.some((a) => a.attributeId === c.id))
      .map((c) => c.items?.[0].value ?? '') ?? [];
  const jobTypeList =
    attributeJobTypeList
      ?.filter((c) => product?.attributes.some((a) => a.attributeId === c.id))
      .map((c) => c.name) ?? [];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container columnSpacing={2} rowSpacing={4}>
        <DetailGridItem label="案件区分">
          <Typography>
            {product.category.name}
            求人
          </Typography>
        </DetailGridItem>
        <DetailGridItem label="掲載期間">
          <Typography>
            {formatDate(product.publication?.since)}～
            {formatDate(product.publication?.until)}
          </Typography>
        </DetailGridItem>
        <DetailGridItem label="募集する勤務日">
          <Typography>{getProductDayWeekTitle(product)}</Typography>
          {product.customFields?.repeatDetail && (
            <Typography>
              {convertNewLineToBr(product.customFields?.repeatDetail)}
            </Typography>
          )}
          {product.customFields?.workPublicHoliday && (
            <Typography>※祝日勤務あり</Typography>
          )}
        </DetailGridItem>
        <DetailGridItem label="募集科目">
          <Typography>{clinicalDepartmentList.join('、')}</Typography>
        </DetailGridItem>
        <DetailGridItem label="勤務先">
          <Typography>〒{product.customFields?.workPostalCode}</Typography>
          <RowBox gap={1}>
            <Typography>
              {product.locations.find((l) => l.type === 'prefecture')?.name}
            </Typography>
            <Typography>
              {product.locations.find((l) => l.type === 'city')?.name}
            </Typography>
          </RowBox>
          <Typography>{product.customFields?.workAddress1}</Typography>
          <Typography>{product.customFields?.workAddress2}</Typography>
          <Typography>
            {convertNewLineToBr(product.customFields?.access)}
          </Typography>
        </DetailGridItem>
        <DetailGridItem label="仕事種別">
          <Typography>{jobTypeList.join('、')}</Typography>
        </DetailGridItem>
        <DetailGridItem label="勤務時間">
          <Typography>
            {formatTime(product.customFields?.startTime)}～
            {formatTime(product.customFields?.endTime)}
          </Typography>
          {product.customFields?.overWorkConditions?.map(
            (o, i) => o && <Typography key={i}>※{o}</Typography>
          )}
        </DetailGridItem>
        <DetailGridItem label="仕事内容">
          <Typography>{convertNewLineToBr(product.description)}</Typography>
        </DetailGridItem>
        <DetailGridItem label="休憩時間">
          <Typography>
            {product.customFields?.breakTimeNoParticular
              ? '随時・なし'
              : `${product.customFields?.breakTime}分`}
          </Typography>
        </DetailGridItem>
        <DetailGridItem label="応募条件">
          <Typography>
            {convertNewLineToBr(product.customFields?.orderConditions)}
          </Typography>
        </DetailGridItem>
        <DetailGridItem label="給与">
          {product.variants?.[product.variants?.length - 1]?.sku === 'hour' ? (
            <Typography>
              時給
              {product.customFields?.calculatedHourlyPay?.toLocaleString()}円
            </Typography>
          ) : (
            <Typography>
              日給
              {product.customFields?.calculatedDairyPay?.toLocaleString()}円
            </Typography>
          )}
        </DetailGridItem>
        <DetailGridItem label="選考方法">
          <Typography>{product.customFields?.selection}</Typography>
        </DetailGridItem>
        <DetailGridItem label="インセンティブ（ある場合は具体的な条件をご記載ください）">
          <Typography>
            {convertNewLineToBr(
              product.variants?.[product.variants?.length - 1]?.description
            )}
          </Typography>
        </DetailGridItem>
        <DetailGridItem label="その他情報">
          <Typography>
            {convertNewLineToBr(product.additionalInformation)}
          </Typography>
        </DetailGridItem>
        <DetailGridItem label="交通費">
          <Typography>
            {
              productCommutingList.find(
                (v) => v.id === product.customFields?.commuting
              )?.name
            }
            {product.customFields?.commutingCost
              ? `${product.customFields?.commutingCost.toLocaleString()}円`
              : ''}
          </Typography>
        </DetailGridItem>
        <DetailGridItem label="募集人員">
          <Typography>{product.customFields?.count}人</Typography>
        </DetailGridItem>
      </Grid>
    </Box>
  );
}
