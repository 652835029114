import { ContentCopy, DeleteOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Checkbox,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGrid, DataGridProps, GridColDef } from '@mui/x-data-grid';

import { SupportedProductPublicationStatus } from '@app/types/catalog';
import { formatDate, formatTime, formatWeek } from '@app/utils/format';

export interface ProductTableProps {
  loading: boolean;
  onPaginationModelChange: DataGridProps['onPaginationModelChange'];
  onRowClick: (row: ProductTableRow) => void | Promise<void>;
  onRowClickCopy?: (row: ProductTableRow) => void | Promise<void>;
  onRowClickDelete?: (row: ProductTableRow) => void | Promise<void>;
  onRowSelectionChange?: (ids: string[]) => void | Promise<void>;
  paginationModel: DataGridProps['paginationModel'];
  rowCount: number;
  rowSelectionIds?: string[];
  rows: ProductTableRow[];
  selectionCategoryName?: string;
}

export interface ProductTableAttribute {
  id: string;
  name: string;
}

export interface ProductTableRow {
  attributesClinical?: ProductTableAttribute[];
  attributesJob?: ProductTableAttribute[];
  calculatedDairyPay?: number;
  categoryName?: string;
  commuting?: string;
  day?: string;
  endTime?: string;
  id: string;
  inquiry?: number;
  name?: string;
  publicationSince?: string;
  publicationUntil?: string;
  searchConditionWeek?: number[];
  startTime?: string;
  status?: SupportedProductPublicationStatus;
}

export function ProductTable({
  rows,
  rowCount,
  loading,
  rowSelectionIds = [],
  onRowClick,
  onRowClickCopy,
  onRowClickDelete,
  onRowSelectionChange,
  paginationModel,
  onPaginationModelChange,
  selectionCategoryName,
}: ProductTableProps) {
  const theme = useTheme();
  const isSelectionMode = !!rowSelectionIds.length;

  const columns: GridColDef<ProductTableRow>[] = [
    {
      field: 'check',
      headerAlign: 'center',
      headerName: '',
      renderCell: (params) => (
        <Checkbox
          checked={rowSelectionIds.includes(params.row.id)}
          disabled={
            (isSelectionMode &&
              selectionCategoryName !== params.row.categoryName) ||
            !!params.row.inquiry
          }
          onClick={async (e) => {
            e.stopPropagation();
            e.preventDefault();
            const newSelectionIds = !rowSelectionIds.includes(params.row.id)
              ? [...rowSelectionIds, params.row.id].sort()
              : rowSelectionIds.filter((v) => v !== params.row.id);
            onRowSelectionChange &&
              (await onRowSelectionChange(newSelectionIds));
          }}
        />
      ),
      width: 50,
    },
    {
      field: 'id',
      headerAlign: 'center',
      headerName: '求人ID/案件名',
      renderCell: (params) => (
        <Box whiteSpace="pre-wrap">
          <Typography variant="caption" color={theme.palette.grey[500]}>
            {params.row.id}
          </Typography>
          <Box>{params.row.name}</Box>
        </Box>
      ),
      renderHeader: () => (
        <Box lineHeight="1rem">
          求人ID/
          <br />
          案件名
        </Box>
      ),
      width: 190,
    },
    {
      field: 'dateTime',
      headerAlign: 'center',
      headerName: '勤務日時',
      renderCell: (params) => (
        <Box whiteSpace="pre-wrap">
          <Chip
            label={params.row.categoryName}
            size="small"
            sx={{
              borderRadius: 1,
              color: theme.palette.info.dark,
              fontSize: '0.7rem',
              height: '18px',
            }}
          />
          {params.row.day && <Box>{formatDate(params.row.day)}</Box>}
          {!params.row.day && !!params.row.searchConditionWeek?.length && (
            <Box display="inline-block">
              {formatWeek(params.row.searchConditionWeek)}
            </Box>
          )}
          <Box display="inline-block">
            {formatTime(params.row.startTime)}-{formatTime(params.row.endTime)}
          </Box>
        </Box>
      ),
      valueGetter: (params) =>
        `${formatDate(params.row.day ?? '9999/12/31')} ${
          params.row.searchConditionWeek?.join().padStart(7, '0') ?? '0000000'
        } ${formatTime(params.row.startTime)} ${formatTime(
          params.row.endTime
        )}`, // NOTE:ソート用に設定
      width: 147,
    },
    {
      field: 'status',
      headerAlign: 'center',
      headerName: 'ステータス',
      renderCell: (params) => (
        <Box textAlign="center" width="1">
          {params.row.status === 'ACTIVE' ? (
            <Box color={theme.palette.primary.main}>掲載中</Box>
          ) : params.row.status === 'ARCHIVED' ? (
            <Box color={theme.customPalette.alert}>終了</Box>
          ) : params.row.status === 'DRAFT' ? (
            '下書き'
          ) : (
            '-'
          )}
        </Box>
      ),
      width: 110,
    },
    {
      field: 'inquiry',
      headerAlign: 'center',
      headerName: '問い合わせ',
      renderCell: (params) => (
        <Box textAlign="center" width="1">
          {params.row.inquiry}件
        </Box>
      ),
      width: 110,
    },
    {
      field: 'attribute',
      headerAlign: 'center',
      headerName: '募集科目/仕事種別',
      renderCell: (params) => (
        <Box whiteSpace="pre-wrap">
          {params.row.attributesClinical?.map((a) => a.name).join('・')}/<br />
          {params.row.attributesJob?.map((a) => a.name).join('・')}
        </Box>
      ),
      renderHeader: () => (
        <Box lineHeight="1rem">
          募集科目/
          <br />
          仕事種別
        </Box>
      ),
      valueGetter: (params) =>
        `${params.row.attributesClinical
          ?.map((a) => a.name)
          .join('・')}/${params.row.attributesJob
          ?.map((a) => a.name)
          .join('・')}`, // NOTE:ソート用に設定
      width: 150,
    },
    {
      field: 'pay',
      headerAlign: 'center',
      headerName: '日給/交通費',
      renderCell: (params) => (
        <Box whiteSpace="pre-wrap" textAlign="center" width="1">
          {params.row.calculatedDairyPay?.toLocaleString()}円/
          <br />
          {params.row.commuting}
        </Box>
      ),
      renderHeader: () => (
        <Box lineHeight="1rem">
          日給/
          <br />
          交通費
        </Box>
      ),
      valueGetter: (params) =>
        `${params.row.calculatedDairyPay?.toString().padStart(10, '0')}/${
          params.row.commuting
        }`, // NOTE:ソート用に設定
      width: 170,
    },
    {
      field: 'publicationPeriod',
      headerAlign: 'center',
      headerName: '掲載期間',
      renderCell: (params) => (
        <Box textAlign="left">
          {formatDate(params.row.publicationSince)}
          <br />~{formatDate(params.row.publicationUntil)}
        </Box>
      ),
      valueGetter: (params) =>
        `${formatDate(params.row.publicationSince)} ${formatDate(
          params.row.publicationUntil
        )}`, // NOTE:ソート用に設定
      width: 160,
    },
    {
      field: 'action',
      headerAlign: 'center',
      headerName: '',
      renderCell: (params) => {
        const canDelete = !isSelectionMode && !params.row.inquiry;
        return (
          <Stack direction="row" spacing={1} justifyContent="center">
            {onRowClickCopy && (
              <Button
                title="複製"
                variant="outlined"
                color="secondary"
                size="small"
                disabled={isSelectionMode}
                onClick={async (event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  await onRowClickCopy?.(params.row);
                }}
              >
                <ContentCopy color="action" />
              </Button>
            )}
            {onRowClickDelete && (
              <Box
                onClick={(e) => {
                  if (!canDelete) {
                    e.stopPropagation();
                  }
                }}
                sx={{
                  cursor: canDelete ? 'pointer' : 'not-allowed',
                }}
              >
                <Button
                  title="削除"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  disabled={!canDelete}
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onRowClickDelete && (await onRowClickDelete(params.row));
                  }}
                >
                  <DeleteOutline color="action" />
                </Button>
              </Box>
            )}
          </Stack>
        );
      },
      sortable: false,
      width: 120,
    },
  ];

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      getRowHeight={() => 'auto'}
      pageSizeOptions={[10, 20, 50]}
      initialState={{
        pagination: { paginationModel: { pageSize: 10 } },
      }}
      paginationMode="server"
      rowCount={rowCount}
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationModelChange}
      localeText={{ noRowsLabel: 'データがありません' }}
      getRowClassName={(params) => {
        return rowSelectionIds.includes(params.row.id) ? 'row-select' : '';
      }}
      loading={loading}
      autoHeight
      disableColumnMenu
      pagination
      slotProps={{
        pagination: {
          labelRowsPerPage: (
            <Typography variant="caption">1ページの表示数</Typography>
          ),
          showFirstButton: true,
          showLastButton: true,
        },
      }}
      onRowClick={(params) => {
        if (isSelectionMode) return;
        onRowClick && void onRowClick(params.row);
      }}
      sx={{
        '& .row-select': {
          background: theme.customPalette.selection,
        },
        '.MuiDataGrid-cell': {
          ':focus-within': { outline: 'none' },
          cursor: isSelectionMode ? undefined : 'pointer',
          fontSize: '13px',
          py: 0.5,
        },
        '.MuiDataGrid-columnHeader': {
          ':focus-within': { outline: 'none' },
          fontSize: '12px',
          px: 0.5,
        },
        '.MuiDataGrid-columnHeaders': {
          backgroundColor: theme.palette.grey[200],
          borderRadius: 0,
        },
        border: 'none',
      }}
    />
  );
}
