import { createContext, useContext } from 'react';

type AppContextType = {
  fetchMessagesAndUnreadCount: () => Promise<void>;
  unreadCount?: number;
};

export const AppContext = createContext<AppContextType | undefined>(undefined);

export const useAppContext = (): AppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppContextProvider');
  }
  return context;
};
