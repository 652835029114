import {
  CheckCircleOutline,
  ReportGmailerrorred as Report, // cspell:disable-line
} from '@mui/icons-material'; // eslint-disable-line
import { Box, Typography, TableCell, TableRow, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

import { SupportedOrderStatus } from '@app/types/order';
import { getAge } from '@app/utils';
import { formatTime } from '@app/utils/format';

export interface OrderInfoItemProps {
  birthday?: string;
  endTime?: string;
  familyName?: string;
  firstName?: string;
  gender?: string;
  orderId?: string;
  startTime?: string;
  status?: SupportedOrderStatus;
}

export function OrderInfoItem(props: OrderInfoItemProps) {
  const theme = useTheme();

  return (
    <TableRow sx={{ height: '2rem' }}>
      <TableCell sx={{ pr: 0, width: '8rem' }}>
        {props.startTime && (
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            alignItems="center"
            fontSize="0.95rem"
          >
            <Typography fontSize="100%">
              {formatTime(props.startTime)}
            </Typography>
            <Typography fontSize="100%">-</Typography>
            <Typography fontSize="100%">{formatTime(props.endTime)}</Typography>
          </Box>
        )}
      </TableCell>
      <TableCell sx={{ width: '14rem' }}>
        <Box
          display="flex"
          flexDirection="row"
          gap={1}
          alignItems="center"
          justifyContent="top"
        >
          {props.familyName && (
            <Link
              to={'/orders/' + props.orderId}
              style={{ color: theme.palette.text.primary }}
            >
              <Typography fontSize="0.95rem">{`${props.familyName} ${props.firstName}`}</Typography>
            </Link>
          )}
          {(props.birthday || props.gender) && (
            <Typography fontSize="0.8rem">
              {`${
                getAge(props.birthday) ? getAge(props.birthday) + '歳' : ''
              }/${props.gender ?? ''}`
                .replace(/^\//, '')
                .replace(/\/$/, '')}
            </Typography>
          )}

          {props.status === undefined && (
            <Box
              display="inline-flex"
              alignItems="center"
              color={theme.customPalette.alert}
              gap={0.3}
            >
              <Report sx={{ fontSize: '1rem' }} />
              <Typography>応募なし</Typography>
            </Box>
          )}
        </Box>
      </TableCell>
      <TableCell sx={{ pl: 0, textAlign: 'right' }}>
        {(props.status === 'ACCEPTED' ||
          props.status === 'PROCESSING' ||
          props.status === 'CLOSED') && (
          <Box
            display="inline-flex"
            alignItems="center"
            marginLeft="auto"
            color={theme.palette.info.main}
            gap={0.3}
          >
            <CheckCircleOutline sx={{ fontSize: '1rem' }} />
            <Typography fontSize="0.85rem">採用</Typography>
          </Box>
        )}
        {props.status === 'PENDING' && (
          <Box
            display="inline-flex"
            alignItems="center"
            marginLeft="auto"
            color={theme.customPalette.alert}
            gap={0.3}
          >
            <Report sx={{ fontSize: '1rem' }} />
            <Typography fontSize="0.85rem">要対応</Typography>
          </Box>
        )}
      </TableCell>
    </TableRow>
  );
}
