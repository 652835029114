import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  TextField,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { z } from 'zod';

import { changeEmailFromSetting } from '@app/adapter/user-service';
import {
  AccountSettingHeader,
  AccountSettingPaper,
} from '@app/components/AccountSetting/AccountSettingHeader';
import {
  snackbarOpenState,
  snackbarSeverityState,
  snackbarTextState,
  userAuthInfoSelector,
} from '@app/domain/app';

interface ChangeEmailFormContent {
  email: string;
}

const ChangeNameForm = z.object({
  email: z.string().email(),
});

export function EmailChangePage() {
  const navigate = useNavigate();
  const userAuthInfoState = useRecoilValue(userAuthInfoSelector);
  const [isSubmitted] = useState(false);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const setSnackbarState = useSetRecoilState(snackbarSeverityState);

  const { control, handleSubmit } = useForm<ChangeEmailFormContent>({
    defaultValues: {
      email: '',
    },
    mode: 'all',
    resolver: zodResolver(ChangeNameForm),
  });

  const onSubmit = useCallback(
    async (data: ChangeEmailFormContent) => {
      const res = await changeEmailFromSetting(
        userAuthInfoState.id,
        data.email
      );
      if (res.status === 200) {
        setSnackbarText('メールアドレスを変更しました。');
        setSnackbarState('success');
        setSnackbarOpen(true);
      } else {
        setSnackbarText('メールアドレスの変更に失敗しました。');
        setSnackbarState('error');
        setSnackbarOpen(true);
      }
      navigate('/account-setting');
    },
    [
      navigate,
      userAuthInfoState.id,
      setSnackbarText,
      setSnackbarState,
      setSnackbarOpen,
    ]
  );

  return (
    <>
      <AccountSettingHeader title="アカウント設定" />
      <AccountSettingPaper title="メールアドレス変更">
        <Grid>
          {isSubmitted ? (
            <Grid sx={{ pt: 3 }}>
              <Typography>
                新しいメールアドレス宛に確認用のメールを送信しました。
              </Typography>
              <Typography>メールの内容にそって操作してください。</Typography>
              <Grid sx={{ pr: 3, py: 2 }}>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ mt: 3, pl: 5, pr: 5 }}
                  onClick={() => navigate(-1)}
                >
                  戻る
                </Button>
              </Grid>
            </Grid>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid sx={{ minWidth: 500 }}>
                <Grid container spacing={3} sx={{ pt: 2 }}>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" sx={{ width: 400 }}>
                      <FormLabel>
                        <Typography
                          color="textPrimary"
                          component="div"
                          variant="subtitle2"
                          fontWeight={600}
                          sx={{ textAlign: 'left' }}
                        >
                          新しいメールアドレス
                          <Typography component="span" color="error">
                            *
                          </Typography>
                        </Typography>
                      </FormLabel>
                      <Controller
                        name="email"
                        control={control}
                        rules={{ required: true }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            error={error ? true : undefined}
                            helperText={error?.message}
                            margin="dense"
                            placeholder="新しいメールアドレスを入力"
                            type="text"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid sx={{ pr: 3, py: 2 }}>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  sx={{ pl: 5, pr: 5 }}
                >
                  送信
                </Button>
              </Grid>
            </form>
          )}
        </Grid>
      </AccountSettingPaper>
    </>
  );
}
