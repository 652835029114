import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  fetchCategories,
  getAttributes,
  getProducts,
  getLocationById,
} from '@app/adapter/catalog-service';
import { snackbarOpenState, snackbarTextState } from '@app/domain/app';
import { organizationSelector } from '@app/domain/organization';
import {
  Attribute,
  Category,
  Product,
  ProductLocation,
} from '@app/types/catalog';

/**
 * 求人IDから求人リストを取得するフック
 * 求人IDが変わる毎に読み込む求人が増加する
 * ※一度読み込まれた求人はストックし続ける
 * ※新たに読み込める求人IDは100件まで
 * @returns 求人リスト
 */
export function useProductsAdd(
  productIds: string[] | undefined,
  isAdmin = false
) {
  const organizationState = useRecoilValue(organizationSelector);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [state, setState] = useState(undefined as Product[] | undefined);
  const load = useCallback(async () => {
    try {
      if (!productIds?.length) {
        return;
      }
      // NOTE:未取得の求人IDのみ取得
      const productIds2 = productIds.filter(
        (id) => !state?.some((p) => p.id === id)
      );
      if (!productIds2.length) {
        return;
      }
      const result = await getProducts(
        isAdmin ? undefined : organizationState.id,
        {
          ids: productIds2,
          isAdmin,
          pageNumber: 0,
          pageSize: productIds2.length,
        }
      );
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      const productIds3 = productIds2.filter(
        (id) => !result.data.value?.some((p) => p.id === id)
      );
      // 他の医療機関の求人を取得する
      const result2 =
        isAdmin || !productIds3.length
          ? undefined
          : await getProducts(undefined, {
              ids: productIds3,
              isAdmin,
              pageNumber: 0,
              pageSize: productIds2.length,
            });
      if (result2 && result2.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      if (!result.data.value.length && !result2?.data.value.length) {
        return;
      }
      setState((prev) => [
        ...(prev ?? []),
        ...result.data.value,
        ...(result2?.data.value ?? []),
      ]);
    } catch (error) {
      setSnackbarText(
        `求人の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [
    productIds,
    isAdmin,
    state,
    organizationState,
    setSnackbarOpen,
    setSnackbarText,
  ]);

  useEffect(() => {
    void load();
  }, [load]);
  return state;
}

/**
 * 診療科目リストを取得するフック
 * @returns 診療科目リスト
 */
export function useAttributesClinicalDepartment() {
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [state, setState] = useState(undefined as Attribute[] | undefined);
  const load = useCallback(async () => {
    if (state) {
      return;
    }
    try {
      const result = await getAttributes({
        filter: {
          groupName: ['clinicalDepartment'],
        },
        pageNumber: 0,
        pageSize: 100,
      });
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      setState(result.data.value ?? []);
    } catch (error) {
      setState([]);
      setSnackbarText(
        `診療科目の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [state, setState, setSnackbarOpen, setSnackbarText]);
  useEffect(() => {
    void load();
  }, [load]);
  return state;
}

/**
 * 仕事種別リストを取得するフック
 * @returns 仕事種別リスト
 */
export function useAttributesJobType() {
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [state, setState] = useState(undefined as Attribute[] | undefined);
  const load = useCallback(async () => {
    if (state) {
      return;
    }
    try {
      const result = await getAttributes({
        filter: {
          groupName: ['jobType'],
        },
        pageNumber: 0,
        pageSize: 100,
      });
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      setState(result.data.value ?? []);
    } catch (error) {
      setState([]);
      setSnackbarText(
        `仕事種別の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [state, setState, setSnackbarOpen, setSnackbarText]);
  useEffect(() => {
    void load();
  }, [load]);
  return state;
}

/**
 * 案件区分リストを取得するフック
 * @returns 案件区分リスト
 */
export function useProductCategories() {
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [state, setState] = useState(undefined as Category[] | undefined);
  const load = useCallback(async () => {
    if (state) {
      return;
    }
    try {
      const result = await fetchCategories();
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      const category = result.data.value.find((c) => c.name === '案件区分');
      setState(category?.children ?? []);
    } catch (error) {
      setState([]);
      setSnackbarText(
        `案件区分の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [state, setState, setSnackbarOpen, setSnackbarText]);
  useEffect(() => {
    void load();
  }, [load]);
  return state;
}

/**
 * 住所を取得するフック
 * @returns 住所
 */
export function useProductLocation(locationId: string | undefined) {
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [state, setState] = useState(undefined as ProductLocation | undefined);
  const load = useCallback(async () => {
    try {
      if (!locationId) {
        return;
      }
      const result = await getLocationById(locationId);
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      setState(result.data);
    } catch (error) {
      setSnackbarText(
        `住所の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    }
  }, [locationId, setSnackbarOpen, setSnackbarText]);

  useEffect(() => {
    void load();
  }, [load]);
  return state;
}
