// Copyright 2022 Co-Lift Inc.
import { isError } from 'util';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { ReactElement, useCallback, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { z } from 'zod';

import { sendResetPasswordEmail } from '@app/adapter/user-service';
import { snackbarTextState, snackbarOpenState } from '@app/domain/app';

interface SendResetPasswordEmailSchema {
  email: string;
}

export function SendResetPasswordEmail(): ReactElement {
  const navigate = useNavigate();
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [emailNotFoundError, setEmailNotFoundError] = useState<string | null>(
    null
  );

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<SendResetPasswordEmailSchema>({
    defaultValues: { email: '' },
    mode: 'onChange',
    resolver: zodResolver(
      z.object({
        email: z
          .string()
          .email({ message: 'メールアドレスを入力してください' }),
      })
    ),
  });

  const onSubmit = useCallback(
    async (data: SendResetPasswordEmailSchema) => {
      const { email } = data;
      try {
        await sendResetPasswordEmail(email);
        navigate('/waiting_reset_password', { state: { waiting: true } });
      } catch (error: unknown) {
        if (isError(error)) {
          if (error.message === 'email not found') {
            setEmailNotFoundError(error.message);
          } else {
            setSnackbarText(`処理できませんでした, ${error.message}`);
            setSnackbarOpen(true);
          }
        } else {
          setSnackbarText(`処理できませんでした`);
          setSnackbarOpen(true);
        }
      }
    },
    [navigate, setSnackbarOpen, setEmailNotFoundError, setSnackbarText]
  );

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={6} lg={4}>
          <Card
            sx={{
              mb: 10,
              minWidth: '450px',
              pb: 5,
              pt: 5,
              textAlign: 'center',
            }}
          >
            <CardContent>
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                sx={{
                  fontSize: 24,
                  fontWeight: 600,
                  lineHeight: '33.6px',
                }}
              >
                パスワード再設定手続き
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container sx={{ mt: 5 }} justifyContent="center">
                  <Grid item xs={10}>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      textAlign={'left'}
                    >
                      会員登録時にご登録して頂いたメールアドレスを入力してください。パスワード再発行手続きのメールをお送りします。
                    </Typography>
                    {emailNotFoundError && (
                      <Typography
                        color="error"
                        sx={{ pt: 3, textAlign: 'left' }}
                      >
                        入力されたメールアドレスの会員情報が見つかりません。間違ったメールアドレスを入力されていないかご確認ください。
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={10} sx={{ mb: 3, mt: 4 }}>
                    <FormControl fullWidth variant="outlined">
                      <FormLabel>
                        <Typography
                          color="textPrimary"
                          variant="subtitle2"
                          fontWeight={600}
                          textAlign={'left'}
                        >
                          メールアドレス
                        </Typography>
                      </FormLabel>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            error={!!error}
                            helperText={error ? error.message : undefined}
                            margin="dense"
                            placeholder="メールアドレスを入力"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={10}>
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      fullWidth
                      disabled={!isValid}
                    >
                      送信
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
