import { useState } from 'react';

import { deleteMessage } from '@app/adapter/chat-service';
import { Message } from '@app/types/chats';

type PopoverPosition = { left: number; top: number } | undefined;

export const useDeleteLogic = (
  chatTopicMessages: Message[],
  setChatTopicMessages: (messages: Message[]) => void
) => {
  const [messageToDelete, setMessageToDelete] = useState<string | null>(null);
  const [anchorPosition, setAnchorPosition] =
    useState<PopoverPosition>(undefined);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  let longPressTimer: NodeJS.Timeout;

  const handleTouchStart = (event: React.TouchEvent, messageId: string) => {
    longPressTimer = setTimeout(() => {
      handleContextMenu(event, messageId);
    }, 500);
  };

  const handleTouchEnd = () => {
    clearTimeout(longPressTimer);
  };

  const handleContextMenu = (
    event: React.MouseEvent | React.TouchEvent,
    messageId: string
  ) => {
    event.preventDefault();
    let clientX = 0;
    let clientY = 0;

    if ('clientX' in event) {
      clientX = event.clientX;
      clientY = event.clientY;
    } else {
      const touch = event.touches[0];
      clientX = touch.clientX;
      clientY = touch.clientY;
    }

    setAnchorPosition({
      left: clientX,
      top: clientY,
    });
    setMessageToDelete(messageId);
  };

  const handleCloseContextMenu = () => {
    setAnchorPosition(undefined);
  };

  const handleDeleteMessage = async (messageId: string) => {
    try {
      const targetMessage = chatTopicMessages.find(
        (message) => message.id === messageId
      );

      if (targetMessage && targetMessage.category === 'supply') {
        await deleteMessage(messageId, 'メッセージは削除されました');

        const updatedMessages = chatTopicMessages.map((message) => {
          if (message.id === messageId) {
            return {
              ...message,
              content: 'メッセージは削除されました',
            };
          }
          return message;
        });
        setChatTopicMessages(updatedMessages);
      } else {
        console.warn(
          "The message is not of category 'supply' and will not be deleted."
        );
      }
    } catch (error) {
      console.error('Failed to delete the message:', error);
    }
  };

  return {
    anchorEl,
    anchorPosition,
    handleCloseContextMenu,
    handleContextMenu,
    handleDeleteMessage,
    handleTouchEnd,
    handleTouchStart,
    messageToDelete,
    setAnchorEl,
    setMessageToDelete,
  };
};
