import { AlertColor } from '@mui/material';
import { atom, selector, DefaultValue, useSetRecoilState } from 'recoil';

import {
  organization,
  allOrganizationsAtom,
  ORGANIZATION_DEFAULT,
} from '@app/domain/organization';
import { User } from '@app/types/user';
import {
  getStoredAccessToken,
  getStoredUserId,
  removeStoredAccessToken,
  removeStoredUserId,
  removeStoredRefreshToken,
} from '@app/utils/auth';

export const isLoading = atom<boolean>({
  default: false,
  key: 'isLoading',
});

/**
 * blocks-5f90
 *
 * @note This is an alpha feature. We will remove this when we reach to beta stage.
 *
 * Store a user's auth information like id and token.
 * This is tricky, we're going to force this not to be null or undefined
 * and ensure its value will be set by private router. Therefore, we do not
 * need to check if it exists everywhere under private route.
 *
 * Use `isValidUserAuthInfoState` to know if the auth information is valid.
 */
export const USER_AUTH_INFO_STATE_DEFAULT = {
  accessToken: '',
  fingerprint: '',
  id: '',
};

const userAuthInfoState = atom<{
  accessToken: string;
  fingerprint: string;
  id: string;
  name?: string;
}>({
  default: USER_AUTH_INFO_STATE_DEFAULT,
  key: 'userSessionState',
});

/**
 * blocks-5f90
 *
 * @note This is an alpha feature. We will remove this when we reach to beta stage.
 *
 * Returns if the loggedIn user has a valid auth info.
 */
export const isValidUserAuthInfoState = selector<boolean>({
  get: ({ get }) => {
    const authInfo = get(userAuthInfoState);
    if (Boolean(authInfo.id) && Boolean(authInfo.accessToken)) {
      return true;
    }

    if (Boolean(getStoredUserId()) && Boolean(getStoredAccessToken())) {
      return true;
    }
    return false;
  },
  key: 'isValidUserAuthInfoState',
});

/**
 * blocks-5f90
 *
 * A selector for userAuthInfoState.
 */

export const userAuthInfoSelector = selector<{
  accessToken: string;
  fingerprint: string;
  id: string;
}>({
  get: ({ get }) => {
    return get(userAuthInfoState);
  },
  key: 'userAuthInfoSelector',
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(userAuthInfoState, newValue);
      return;
    }
    set(userAuthInfoState, newValue);
  },
});

export const loggedInUserState = atom<User | null>({
  default: null,
  key: 'loggedInUserState',
});

//
// Sign out utility
//
export function useClearAuthStateAndStorage() {
  const setUserAuthInfo = useSetRecoilState(userAuthInfoSelector);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);
  const setOrganization = useSetRecoilState(organization);
  const setAllOrganizations = useSetRecoilState(allOrganizationsAtom);

  return () => {
    // storage
    removeStoredAccessToken();
    removeStoredUserId();
    removeStoredRefreshToken();

    // state (recoil)
    setLoggedInUser(null);
    setUserAuthInfo({ ...USER_AUTH_INFO_STATE_DEFAULT });
    setOrganization({ ...ORGANIZATION_DEFAULT });
    setAllOrganizations([]);
  };
}

//
// snackbar
//
export const snackbarOpenState = atom<boolean>({
  default: false,
  key: 'snackbarOpenState',
});

export const snackbarTextState = atom<string>({
  default: '',
  key: 'snackbarTextState',
});
export const snackbarSeverityState = atom<AlertColor>({
  default: 'error',
  key: 'snackbarSeverityState',
});

export const afterLoginRouteState = atom<string | null>({
  default: null,
  key: 'afterLoginRouteState',
});
