import { Ad, PublicationGroup } from '@app/types/ad';

export function findByGroup(ads: Ad[], group: string): Ad | undefined {
  return ads.find((a) => a?.publicationGroup === group);
}

export function findByGroupOrDefault(ads: Ad[], group: string): Ad {
  return (
    findByGroup(ads, group) || {
      imageUrl: PublicationGroup[group].defaultImage,
      name: '広告募集中',
      publicationGroup: group,
    }
  );
}
