import { atom } from 'recoil';

import { Paginated } from '@app/types/common';
import { Message } from '@app/types/notifications';

export const messagesState = atom<Partial<Paginated<Message>>>({
  default: {
    '@nextLink': '',
    count: 0,
    total: 0,
  },
  key: 'messagesState',
});
