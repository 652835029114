import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { ReactElement, ReactNode, useCallback } from 'react';

interface ConfirmDialogProps {
  children?: ReactNode;
  closeButtonText?: string;
  okButtonLoading?: boolean;
  okButtonText?: string;
  onClose: (confirm?: boolean) => void;
  open: boolean;
  title?: ReactNode;
}

export function ConfirmDialog({
  closeButtonText = 'キャンセル',
  okButtonLoading,
  okButtonText,
  open,
  onClose,
  title,
  children,
}: ConfirmDialogProps): ReactElement {
  const handleClose = useCallback((): void => {
    if (okButtonLoading) {
      return;
    }
    onClose(false);
  }, [okButtonLoading, onClose]);

  const handleConfirm = useCallback(async () => {
    onClose(true);
  }, [onClose]);

  return (
    <Dialog open={open} onClose={handleClose}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {children && <DialogContent>{children}</DialogContent>}
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          disabled={okButtonLoading}
          onClick={handleClose}
          sx={{ minWidth: '120px' }}
        >
          {closeButtonText}
        </Button>
        {okButtonText && (
          <LoadingButton
            variant="contained"
            size="small"
            loading={okButtonLoading}
            onClick={handleConfirm}
            sx={{ minWidth: '120px' }}
          >
            {okButtonText}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
