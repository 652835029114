import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Paper, Typography, Box, useTheme } from '@mui/material';

import { User } from '@app/types/user';

export interface ReviewEndProps {
  endStatus: '' | 'confirm' | 'cancel' | 'decline';
  user: User | undefined;
}

export function ReviewEnd({ endStatus, user }: ReviewEndProps) {
  const theme = useTheme();

  return (
    <Box>
      <Paper
        sx={{
          display: 'flex',
          height: '16rem',
          mx: 'auto',
          p: 1,
          width: '24rem',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            justifyContent: 'center',
            m: 'auto',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="6rem"
            height="6rem"
            borderRadius="50%"
            bgcolor={theme.palette.grey[300]}
          >
            <MailOutlineIcon sx={{ fontSize: '3rem' }} color="primary" />
          </Box>
          {(endStatus === 'confirm' || endStatus === 'decline') && (
            <>
              <Typography sx={{ m: 'auto' }}>ありがとうございます。</Typography>
              <Typography sx={{ m: 'auto' }}>
                {user?.customFields.familyName}
                さんの評価が終了しました。
              </Typography>
            </>
          )}
          {endStatus === 'cancel' && (
            <Typography sx={{ m: 'auto' }}>
              {user?.customFields.familyName}
              さんの評価をキャンセルしました。
            </Typography>
          )}
        </Box>
      </Paper>
    </Box>
  );
}
