import {
  AxiosPromise,
  AxiosResponse,
  AxiosRequestConfig,
  CanceledError,
} from 'axios';
import { endOfYear, format, startOfYear, subYears } from 'date-fns';

import { axiosInstance, USER_SERVICE } from '@app/adapter/axios';
import { Ad, AdInputEdit, CsvAd } from '@app/types/ad';
import { Paginated } from '@app/types/common';
import {
  Attachment,
  FollowListResponse,
  FollowUser,
  JobChange,
  InvitationForm,
  InvitationItemDetail,
  User,
  UserInfoResponse,
  UserScout,
} from '@app/types/user';
import { filterSyntaxGen } from '@app/utils';
import { getAuthorizationHeader } from '@app/utils/authorization';
import { getUploadedFileUrl } from '@app/utils/file_upload';

/**
 * Register a president account with permission 010.
 */
export function register(
  email: string,
  password: string
): AxiosPromise<{ id: string }> {
  return axiosInstance
    .post(`${USER_SERVICE}/users`, {
      email,
      password,
      typeId: '010',
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getUser(userId: string): AxiosPromise<User> {
  return axiosInstance.get(`${USER_SERVICE}/users/${userId}`);
}

export function sendVerificationEmail(userId: string): AxiosPromise<void> {
  return axiosInstance.post(
    `${USER_SERVICE}/users/${userId}:sendVerificationEmail`,
    {}
  );
}

export function updateUser(
  userId: string,
  birthday: string,
  familyName: string,
  familyNameKana: string,
  firstName: string,
  firstNameKana: string,
  gender: string,
  genderSelf: string,
  token: string
): AxiosPromise<void> {
  return axiosInstance
    .patch(
      `${USER_SERVICE}/users/${userId}`,
      {
        customFields: {
          birthday,
          familyName,
          familyNameKana,
          firstName,
          firstNameKana,
          gender,
          genderSelf,
        },
      },
      {
        headers: {
          'x-nb-token': token,
        },
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * ユーザーを利用停止する。
 * @param userId
 * @returns
 */
export function stopUser(userId: string) {
  return axiosInstance
    .patch<void>(`${USER_SERVICE}/users/${userId}`, {
      customFields: {
        isStop: true,
      },
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * ユーザーを利用停止を解除する。
 * @param userId
 * @returns
 */
export function unStopUser(userId: string) {
  return axiosInstance
    .patch<void>(`${USER_SERVICE}/users/${userId}`, {
      customFields: {
        isStop: false,
      },
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * ユーザーを削除状態に更新する。（論理削除）
 * @param userId
 * @returns
 */
export function deleteUpdateUser(userId: string) {
  const updateUser: Omit<
    User,
    | 'customFields'
    | 'id'
    | 'accessToken'
    | 'typeId'
    | 'name'
    | 'emailVerified'
    | 'email'
  > & {
    customFields: Omit<
      User['customFields'],
      'familyName' | 'familyNameKana' | 'firstName' | 'firstNameKana'
    >;
  } = {
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    avatar: '',
    customFields: {
      addressLine4: '',
      birthday: '',
      clinicalDepartments: [],
      confidences: [],
      currentDepartment: '',
      currentHospital: '',
      deleted_at: new Date().toISOString(),
      firstClinicalDepartments: [],
      gender: '',
      genderSelf: '',
      license: '',
      medicalLicenseYear: 0,
      medicalRegisterNumber: '',
      notes: '',
      orderAfterFiles: [],
      orderMatchedFiles: [],
      postalCode: '',
      universityName: '',
    },
    phoneNumber: '',
  };
  return axiosInstance
    .patch<User>(`${USER_SERVICE}/users/${userId}`, updateUser)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Get Invitation list
 *
 * @param organizationId
 * @returns
 * blocks-9ec7
 */
export function getInvitationList(
  organizationId: string,
  top: number,
  offset: number
): AxiosPromise<Paginated<InvitationItemDetail>> {
  return axiosInstance.get(
    `${USER_SERVICE}/invitations?orgId=${organizationId}&$top=${top}&$skip=${offset}`
  );
}

/**
 * Invite a member to an organization.
 */
export function inviteMember(
  member: InvitationForm
): AxiosPromise<InvitationItemDetail> {
  return axiosInstance.post(`${USER_SERVICE}/invitations`, {
    ...member,
  });
}

/**
 * API wrapper for accept invitation in user-service
 * @param invitationId
 * @param opts
 * @param token
 * @returns void
 * blocks-9ec7
 */
export function acceptInvitation(
  invitationId: string,
  opts: { name: string; password: string },
  token: string
) {
  return axiosInstance.patch(
    `${USER_SERVICE}/invitations/${invitationId}`,
    {
      ...opts,
      typeId: '001',
    },
    {
      headers: getAuthorizationHeader(token),
    }
  );
}

/**
 * blocks-5f90
 */
export function verifyEmail(token: string): AxiosPromise<void> {
  return axiosInstance
    .post(`${USER_SERVICE}/users/verify_email`, undefined, {
      headers: getAuthorizationHeader(token),
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * send email to reset password
 * @param email - user email
 */
export function sendResetPasswordEmail(email: string): AxiosPromise<null> {
  return axiosInstance
    .post(`${USER_SERVICE}/users/send_reset_password_email`, { email })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * reset password
 * @param newPassword - new password
 * @param token - onetime token
 * @returns
 */
export function resetPassword(
  newPassword: string,
  token: string
): AxiosPromise<null> {
  return axiosInstance
    .post(
      `${USER_SERVICE}/users/reset_password`,
      { password: newPassword },
      {
        headers: getAuthorizationHeader(token),
      }
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * reset password from account settings
 * @param userId
 * @param newPassword
 */
export function resetPasswordFromSetting(
  userId: string,
  newPassword: string
): AxiosPromise<null> {
  return axiosInstance
    .patch(`${USER_SERVICE}/users/${userId}`, { password: newPassword })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getUserInfoById(userId: string): Promise<UserInfoResponse> {
  return axiosInstance
    .get(`${USER_SERVICE}/users/${userId}`)
    .then((response) => {
      console.log('getUserInfoById Response:', response.data);
      return response.data;
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function changeNameFromSetting(
  userId: string,
  newFamilyName: string,
  newFirstName: string
): AxiosPromise<null> {
  return axiosInstance
    .patch(`${USER_SERVICE}/users/${userId}`, {
      customFields: {
        familyName: newFamilyName,
        firstName: newFirstName,
      },
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

// MEMO：現状APIがないので仮のもの
export function changeEmailFromSetting(
  userId: string,
  newEmail: string
): AxiosPromise<null> {
  return axiosInstance
    .patch(`${USER_SERVICE}/users/${userId}`, {
      email: newEmail,
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * Get avatar upload url
 * @param userId
 * @param contentType
 * @param contentLength
 */
export function getAvatarUploadUrl(
  userId: string,
  contentType: string,
  contentLength: number
): AxiosPromise<string> {
  const params = new URLSearchParams();
  params.append('contentType', contentType);
  params.append('contentLength', contentLength.toString());

  return axiosInstance
    .get(`${USER_SERVICE}/users/${userId}/avatarUploadUrl?${params}`)
    .catch((error) => {
      throw new Error(error.response.data);
    });
}

/**
 * Update user avatar
 */
export function updateAvatar(
  userId: string,
  avatar: string
): AxiosPromise<{ id: string }> {
  return axiosInstance
    .patch(`${USER_SERVICE}/users/${userId}`, {
      avatar,
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getUserFollowList(
  userId: string,
  type: 'user' | 'organization' | 'product',
  options?: {
    followIds?: string[];
    pageNumber?: number | 0;
    pageSize?: number | 10;
  }
): Promise<FollowListResponse> {
  const urlParams: string[][] = [];

  if (options?.pageSize !== undefined && options?.pageNumber !== undefined) {
    urlParams.push([`$top`, options.pageSize.toString()]);
    urlParams.push([
      `$skip`,
      (options.pageNumber * options.pageSize).toString(),
    ]);
  }

  const filterParam: string[] = [];
  if (options?.followIds?.length) {
    filterParam.push(`followId in ${filterSyntaxGen(options.followIds)}`);
  }

  if (filterParam.length > 0) {
    urlParams.push(['$filter', filterParam.join(' and ')]);
  }

  return axiosInstance
    .get(
      `${USER_SERVICE}/follows?followerId=${userId}&type=${type}&${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .then((response: AxiosResponse<FollowListResponse>) => {
      return response.data;
    })
    .catch((error) => {
      console.error(
        'Failed to fetch follow list:',
        error.response ? error.response.data : error.message
      );
      throw error;
    });
}

export function followUser(
  userId: string,
  followerId: string,
  followerType: 'user' | 'organization' | 'product'
): Promise<FollowUser> {
  return axiosInstance
    .post(`${USER_SERVICE}/users/${userId}/follow`, {
      followerId,
      followerType,
    })
    .then((response: AxiosResponse<FollowUser>) => {
      return response.data;
    })
    .catch((error) => {
      console.error(
        'Failed to follow user:',
        error.response ? error.response.data : error.message
      );
      throw error;
    });
}

export function unFollowUser(
  userId: string,
  followerId: string,
  followerType: 'user' | 'organization' | 'product'
): Promise<FollowUser> {
  return axiosInstance
    .post(`${USER_SERVICE}/users/${userId}/unfollow`, {
      followerId,
      followerType,
    })
    .then((response: AxiosResponse<FollowUser>) => {
      return response.data;
    })
    .catch((error) => {
      console.error(
        'Failed to unFollow user:',
        error.response ? error.response.data : error.message
      );
      throw error;
    });
}

//
// ユーザ一覧取得
//
export function getUsers(
  config?: AxiosRequestConfig
): AxiosPromise<Paginated<User>> {
  return axiosInstance.get(`${USER_SERVICE}/users`, config).catch((error) => {
    if (error instanceof CanceledError) {
      throw error;
    } else if (error.response && 'message' in error.response.data) {
      throw new Error(error.response?.data.message);
    } else {
      throw new Error(error.message);
    }
  });
}

export function getAttachments(
  userId: string,
  options?: { ids?: string[]; limit?: number }
): AxiosPromise<Paginated<Attachment>> {
  const filterParams = [];
  if (options?.ids?.length) {
    filterParams.push(`id in ${filterSyntaxGen(options?.ids)}`);
  }

  const urlParams = [['$top', String(options?.limit ?? 100)]];
  if (filterParams.length > 0) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }
  return axiosInstance
    .get(
      `${USER_SERVICE}/users/${userId}/attachments?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

//
// 広告
//
export function createAd(payload: AdInputEdit): AxiosPromise<Ad> {
  return axiosInstance.post(`${USER_SERVICE}/ads`, payload).catch((error) => {
    if ('message' in error.response.data) {
      throw new Error(error.response?.data.message);
    } else {
      throw new Error(error.message);
    }
  });
}

export function updateAd(id: string, payload: AdInputEdit): AxiosPromise<Ad> {
  return axiosInstance
    .patch(`${USER_SERVICE}/ads/${id}`, payload)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function deleteAd(id: string): AxiosPromise<unknown> {
  return axiosInstance.delete(`${USER_SERVICE}/ads/${id}`).catch((error) => {
    if ('message' in error.response.data) {
      throw new Error(error.response?.data.message);
    } else {
      throw new Error(error.message);
    }
  });
}

export function getAd(id: string): AxiosPromise<Ad> {
  return axiosInstance.get(`${USER_SERVICE}/ads/${id}`);
}

export function getAds(payload?: {
  expand?: string;
  filter?: {
    keyword?: string;
    status?: string[];
  };
  orderBy?: string;
  skip?: number;
  top?: number;
}): AxiosPromise<Paginated<Ad>> {
  const filterParams = [];
  const urlParams = [['$top', String(payload?.top ?? 30)]];

  if (payload?.filter?.keyword) {
    const keywordFilters = [
      `name co '${payload.filter.keyword}'`,
      `adName co '${payload.filter.keyword}'`,
    ];
    filterParams.push(`(${keywordFilters.join(' or ')})`);
  }
  if (payload?.filter?.status?.length) {
    filterParams.push(`status in ${filterSyntaxGen(payload.filter.status)}`);
  }

  if (filterParams.length > 0) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }
  if (payload?.skip) {
    urlParams.push(['$skip', String(payload.skip)]);
  }
  if (payload?.expand) {
    urlParams.push(['$expand', payload.expand]);
  }
  if (payload?.orderBy) {
    urlParams.push(['$orderBy', payload.orderBy]);
  }

  return axiosInstance.get(
    `${USER_SERVICE}/ads?${new URLSearchParams(urlParams).toString()}`
  );
}

export function getCsvByAd(payload?: {
  filter?: {
    dateFrom?: string;
    dateTo?: string;
  };
  orderBy?: string;
}): AxiosPromise<Paginated<CsvAd>> {
  const filterParams = [];
  const urlParams = [];

  if (payload?.filter?.dateFrom) {
    filterParams.push(`ymd(createdAt) ge '${payload.filter.dateFrom}'`);
  }
  if (payload?.filter?.dateTo) {
    filterParams.push(`ymd(createdAt) le '${payload.filter.dateTo}'`);
  }

  if (filterParams.length > 0) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }
  if (payload?.orderBy) {
    urlParams.push(['$orderBy', payload.orderBy]);
  }
  return axiosInstance.get(
    `${USER_SERVICE}/ads/totalization?${new URLSearchParams(
      urlParams
    ).toString()}`
  );
}

export function getPublishedAds(payload?: {
  expand?: string;
  filter?: {
    publicationGroup?: string[];
  };
  orderBy?: string;
  skip?: number;
  top?: number;
}): AxiosPromise<Paginated<Ad>> {
  const filterParams = [];
  const urlParams = [['$top', String(payload?.top ?? 30)]];

  if (payload?.filter?.publicationGroup?.length) {
    filterParams.push(
      `publicationGroup in ${filterSyntaxGen(payload.filter.publicationGroup)}`
    );
  }

  if (filterParams.length > 0) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }
  if (payload?.skip) {
    urlParams.push(['$skip', String(payload.skip)]);
  }
  if (payload?.expand) {
    urlParams.push(['$expand', payload.expand]);
  }
  if (payload?.orderBy) {
    urlParams.push(['$orderBy', payload.orderBy]);
  }

  return axiosInstance.get(
    `${USER_SERVICE}/published-ads?${new URLSearchParams(urlParams).toString()}`
  );
}

export function adClick(id: string): AxiosPromise<undefined> {
  return axiosInstance.post(`${USER_SERVICE}/ads-click/${id}`);
}

export async function adUploadBlob(file: Blob): Promise<string> {
  if (!file) {
    throw new Error('blob is not defined');
  }
  const signedUrl = await getAdUploadSignedUrl(file);
  const uploadedUrl = await getUploadedFileUrl(file, signedUrl);

  const objectId = new URL(uploadedUrl).pathname
    .split('?')[0]
    .replace(/^\/[^/]+\//, '');
  if (!objectId) {
    throw new Error('objectId is not undefined, upload may got error');
  }
  return objectId;
}

export function getAdUploadSignedUrl(blob: Blob): Promise<string> {
  return axiosInstance
    .get(
      `${USER_SERVICE}/ads/attachments/uploadUrl?contentLength=${blob.size}&contentType=${blob.type}`
    )
    .then((response) => response.data);
}

export function getScoutUsers(option?: {
  expand?: string;
  filter?: {
    address: string[];
    age: string;
    clinicalDepartment: string[];
    gender: string;
    isScouted: number;
    jobChange: string;
    review: string;
    workResult: string;
  };
  orderBy?: string;
  skip?: number;
  top?: number;
}): AxiosPromise<Paginated<UserScout>> {
  const filterParams = [];
  const urlParams = [['$top', String(option?.top ?? 10)]];

  if (option?.filter?.address?.length) {
    filterParams.push(
      `addressLine1 in ${filterSyntaxGen(option.filter.address)}`
    );
  }
  if (option?.filter?.age) {
    const currentDate = new Date();
    const birthDays = option.filter.age
      .split(' ')
      .map((i) => subYears(currentDate, Number(i)));
    filterParams.push(
      `customFields.birthday le '${format(
        startOfYear(birthDays[0]),
        'yyyy-MM-dd'
      )}'`
    );
    if (birthDays[1]) {
      filterParams.push(
        `customFields.birthday ge '${format(
          endOfYear(birthDays[1]),
          'yyyy-MM-dd'
        )}'`
      );
    }
  }
  if (option?.filter?.clinicalDepartment?.length) {
    filterParams.push(
      `customFields.clinicalDepartments in ${filterSyntaxGen(
        option.filter.clinicalDepartment
      )}`
    );
  }
  if (option?.filter?.gender) {
    filterParams.push(`customFields.gender eq '${option.filter.gender}'`);
  }
  if (option?.filter?.jobChange) {
    if (option.filter.jobChange === JobChange.NOT_THINKING) {
      filterParams.push(
        `customFields.jobChange ne '${JobChange.ACTIVE}' and customFields.jobChange ne '${JobChange.CONDITIONS_MATCH}'`
      );
    } else {
      filterParams.push(
        `customFields.jobChange eq '${option.filter.jobChange}'`
      );
    }
  }
  if (option?.filter?.review) {
    filterParams.push(`reviewStats.averageRate ge ${option.filter.review}`);
  }
  if (filterParams.length > 0) {
    urlParams.push(['$filter', filterParams.join(' and ')]);
  }

  if (option?.filter?.isScouted) {
    urlParams.push(['isScouted', `${option.filter.isScouted}`]);
  }
  if (option?.filter?.workResult) {
    urlParams.push(['workResult', option.filter.workResult]);
  }

  if (option?.skip) {
    urlParams.push(['$skip', String(option.skip)]);
  }
  if (option?.expand) {
    urlParams.push(['$expand', option.expand]);
  }
  if (option?.orderBy) {
    urlParams.push(['$orderBy', option.orderBy]);
  }

  return axiosInstance.get(
    `${USER_SERVICE}/demand-users?${new URLSearchParams(urlParams).toString()}`
  );
}
