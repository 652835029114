import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Review } from '@app/components/Review/Review';
import { afterLoginRouteState } from '@app/domain/app';
import { organizationSelector } from '@app/domain/organization';
import { Login } from '@app/views/Login';

export function ReviewLogin() {
  const location = useLocation();
  const setAfterLoginRoute = useSetRecoilState(afterLoginRouteState);
  const organizationState = useRecoilValue(organizationSelector);

  useEffect(() => {
    if (!organizationState.id) {
      setAfterLoginRoute(location.pathname + location.search + location.hash);
    } else {
      setAfterLoginRoute(null);
    }
  });

  if (!organizationState.id) {
    return <Login />;
  }
  return <Review />;
}
