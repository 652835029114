import imageSquare1 from '@app/static/images/doctorMobile.png';
import imageBanner1 from '@app/static/images/doctorPc.png';

export const AdStatus = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
} as const;
export type AdStatusKey = (typeof AdStatus)[keyof typeof AdStatus];

export const AdRank = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
} as const;
export type AdRankKey = (typeof AdRank)[keyof typeof AdRank];

export const PublicationGroup: {
  [key: string]: {
    defaultImage: string;
  };
} = {
  '201': {
    defaultImage: imageSquare1,
  },
  '202': {
    defaultImage: imageBanner1,
  },
  '203': {
    defaultImage: imageBanner1,
  },
} as const;
export type PublicationGroupKey =
  (typeof PublicationGroup)[keyof typeof PublicationGroup];

export interface Ad {
  adName?: string;
  adNumber?: string;
  clickCount?: number;
  createdAt?: string;
  destinationUrl?: string;
  id?: string;
  imagePath?: string;
  imageUrl: string;
  impressionCount?: number;
  name?: string;
  publicationGroup?: string;
  publicationSince?: string;
  publicationUntil?: string;
  rank?: AdRankKey;
  status?: AdStatusKey;
  updatedAt?: string;
}

export interface AdInputEdit {
  adName: string;
  adNumber?: string;
  destinationUrl: string;
  imagePath: string;
  name: string;
  publicationGroup: string;
  publicationSince: string;
  publicationUntil: string;
  rank: AdRankKey | string;
  status: AdStatusKey;
}

export interface CsvAd {
  adName: string;
  adNumber: string;
  id: string;
  name: string;
  totalization: {
    clickCount: number;
    date: string;
    impressionCount: number;
  }[];
}

export interface AdInputDownload {
  dateFrom: string;
  dateTo: string;
}
