import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import { ReactElement, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
  getMessageTemplate,
  postMessageTemplate,
  updateMessageTemplate,
} from '@app/adapter/chat-service';
import { PageTitle } from '@app/components/Shared/PageTitle';
import { organization } from '@app/domain/organization';
import { TemplateInputForm } from '@app/schemas/chat';
import { TemplateInputEdit } from '@app/types/chats';
import { isError } from '@app/utils/error';
import { restoreSanitizedString } from '@app/utils/format';
import { useSetSnackbar } from '@app/utils/useSetSnackbar';

export function TemplateCreateEdit() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { id: templateId, duplicateId } = useParams();
  const setSnackbar = useSetSnackbar();
  const orgId = useRecoilValue(organization).id;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    formState: { isValid },
    getValues,
    reset,
  } = useForm<TemplateInputEdit>({
    defaultValues: { ...TemplateInputForm.defaultValues, orgId },
    mode: 'all',
    resolver: TemplateInputForm.resolver,
  });

  const fetchTemplate = async () => {
    const id = templateId || duplicateId;
    if (!id) return;
    setIsLoading(true);
    try {
      const result = await getMessageTemplate(id);
      reset({
        content: restoreSanitizedString(result.data.content),
        orgId,
        permissions: result.data.permissions,
        title: result.data.title,
      });
    } catch (e) {
      setSnackbar(true, 'テンプレートの取得に失敗しました', 'error');
      if (isError(e)) {
        console.error(e.message);
      }
      navigate('/chats/templates/register');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId]);

  const handleSubmit = async () => {
    setIsLoading(true);
    const label = templateId ? '更新' : '登録';
    try {
      const payload = getValues();
      if (templateId) {
        await updateMessageTemplate(templateId, {
          content: payload.content,
          permissions: payload.permissions,
          title: payload.title,
        });
      } else {
        await postMessageTemplate(payload);
      }
      setSnackbar(true, `テンプレートを${label}しました`, 'success');
      navigate('/chats/templates');
    } catch (e) {
      setSnackbar(true, `テンプレートの${label}に失敗しました`, 'error');
      if (isError(e)) {
        console.error(e.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const formLabel = (
    label: string,
    require?: boolean,
    subLabel?: string
  ): ReactElement => {
    return (
      <FormLabel focused={false}>
        <Stack direction="row" spacing={2} alignItems="end">
          <Typography variant="body2">
            {label}
            {require && (
              <Typography component="span" color="error">
                *
              </Typography>
            )}
          </Typography>
          {subLabel && (
            <Typography
              variant="caption"
              sx={{ color: theme.customPalette.lightGray }}
            >
              {subLabel}
            </Typography>
          )}
        </Stack>
      </FormLabel>
    );
  };

  return (
    <>
      <Box display="flex" mb={2}>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={() => navigate('/chats/templates')}
          sx={{ height: '40px', mr: 2, width: '40px' }}
        >
          <ArrowBackIcon />
        </Button>
        <PageTitle title="テンプレート一覧" />
      </Box>
      <Paper elevation={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Typography variant="h5">
            {templateId ? '編集' : '新規登録'}
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button
              color="secondary"
              variant="outlined"
              size="small"
              disabled={!isValid}
              onClick={() => setIsModalOpen(true)}
              sx={{ width: '120px' }}
            >
              プレビュー
            </Button>
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              size="small"
              disabled={!isValid}
              loading={isLoading}
              onClick={handleSubmit}
              sx={{ width: '120px' }}
            >
              {templateId ? '保存' : '登録'}
            </LoadingButton>
          </Stack>
        </Stack>
        <Divider />
        <Stack spacing={3} p={2}>
          <Typography>
            <Typography component="span" color="error">
              *
            </Typography>
            は必須事項です
          </Typography>
          <FormControl>
            {formLabel(
              'テンプレートタイトル',
              true,
              '管理用のタイトルですユーザーには表示されません'
            )}
            <Controller
              name="title"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  placeholder="テンプレートタイトルを入力"
                />
              )}
            />
          </FormControl>
          <FormControl>
            {formLabel('メッセージ', true)}
            <Controller
              name="content"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  placeholder="メッセージを入力"
                  multiline
                  rows={6}
                />
              )}
            />
          </FormControl>
        </Stack>
      </Paper>
      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>プレビュー</DialogTitle>
        <DialogContent>
          <Grid
            sx={{
              backgroundColor: theme.palette.primary.main,
              borderRadius: '20px 20px 0px 20px',
              color: theme.customPalette.white,
              padding: '10px 16px',
            }}
          >
            <Typography
              dangerouslySetInnerHTML={{
                // eslint-disable-next-line @typescript-eslint/naming-convention
                __html: getValues('content').replace(/\n/g, '<br />'),
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            size="small"
            onClick={handleCloseModal}
            sx={{ width: '120px' }}
          >
            閉じる
          </Button>
          <LoadingButton
            color="primary"
            variant="contained"
            size="small"
            disabled={!isValid}
            loading={isLoading}
            onClick={handleSubmit}
            sx={{ width: '120px' }}
          >
            {templateId ? '保存' : '登録'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
