import { Dialog, DialogProps } from '@mui/material';

import { ReviewForm, ReviewFormProps } from '@app/components/Review/ReviewForm';

export type ReviewDialogProps = Omit<DialogProps, 'onClose'> & ReviewFormProps;

export function ReviewDialog({
  onClose,
  userId,
  productId,
  review,
  orderId,
  isAdmin,
  ...props
}: ReviewDialogProps) {
  return (
    <Dialog scroll="body" {...props}>
      <ReviewForm
        userId={userId}
        productId={productId}
        orderId={orderId}
        review={review}
        isAdmin={isAdmin}
        onClose={onClose}
      />
    </Dialog>
  );
}
