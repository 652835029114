import {
  Paper,
  Grid,
  Typography,
  useTheme,
  Box,
  CircularProgress,
} from '@mui/material';
import { startOfDay } from 'date-fns';
import { memo } from 'react';

import {
  CalendarItem,
  CalendarItemProps,
} from '@app/components/Home/CalendarItem';

export interface CalendarProps {
  dayValueList: CalendarItemProps[];
  errorMessage: string;
  loading: boolean;
  month: string;
  onClick: (day: CalendarItemProps) => void;
  selectDate?: string;
}

export const Calendar = memo(function ({
  loading,
  month,
  dayValueList,
  selectDate,
  errorMessage,
  onClick,
}: CalendarProps) {
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  const monthDate = new Date(month + '/1');
  const monthLastDate = new Date(
    monthDate.getFullYear(),
    monthDate.getMonth() + 1,
    0
  );

  const theme = useTheme();

  /** 日付のリスト */
  const dayList: number[] = [
    ...Array.from(Array(monthDate.getDay()).keys()).map(() => 0), //開始日の曜日まで空日付で埋める
    ...Array.from(Array(monthLastDate.getDate()).keys(), (x) => x + 1), //末日までの日付配列
  ];

  /** 日付コンポーネントプロパティのリスト */
  const dayPropsList: CalendarItemProps[] = dayList.map((day) => {
    const dayDate = new Date(
      monthDate.getFullYear(),
      monthDate.getMonth(),
      day
    );
    const date = day ? `${month}/${day}` : '';
    const dayValue = dayValueList?.find(
      (value) =>
        value.date &&
        startOfDay(new Date(value.date)).getTime() ===
          startOfDay(new Date(date)).getTime()
    );

    const result: CalendarItemProps = {
      date: dayValue?.date ?? day ? `${month}/${day}` : '',
      disabled: dayDate.getTime() < today.getTime(),
      isProduct: dayValue?.isProduct,
      isToday: dayDate.getTime() === today.getTime(),
      mailCount: dayValue?.mailCount,
      onClick: () => {
        onClick?.(result);
      },
      orderList: dayValue?.orderList,
    };

    return result;
  });

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0.5,
        height: '40rem',
        padding: 0.5,
        position: 'relative',
        width: '42rem',
      }}
    >
      <Grid container alignItems="center" columns={7} spacing={0.5}>
        {['日', '月', '火', '水', '木', '金', '土'].map((item) => (
          <Grid
            item
            xs={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="3rem"
            key={item}
          >
            <Typography
              color={theme.palette.grey[500]}
              sx={{ fontSize: '0.9rem' }}
            >
              {item}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid container alignItems="center" columns={7} spacing={0.5}>
        {dayPropsList.map((item, idx) => (
          <Grid item xs={1} height={98} key={idx}>
            <CalendarItem
              {...item}
              selected={
                selectDate && item.date
                  ? startOfDay(new Date(item.date)).getTime() ===
                    startOfDay(new Date(selectDate)).getTime()
                  : false
              }
            />
          </Grid>
        ))}
      </Grid>
      {(loading || !!errorMessage) && (
        <Box
          position="absolute"
          height="39.8rem"
          width="41.8rem"
          bgcolor="rgba(255,255,255,0.5)" // NOTE:曇ったような感じになるように覆う
          display="flex"
          top="0.1rem"
          left="0.1rem"
        >
          {loading && <CircularProgress sx={{ mx: 'auto', my: 'auto' }} />}
          {errorMessage && (
            <Typography
              sx={{
                color: theme.customPalette.alert,
                fontSize: '1.4rem',
                fontWeight: 600,
                mx: 'auto',
                my: 'auto',
              }}
            >
              {errorMessage}
            </Typography>
          )}
        </Box>
      )}
    </Paper>
  );
});
