import {
  ContentCopyOutlined as ContentCopyOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  useTheme,
} from '@mui/material';
import {
  GridColDef,
  GridPaginationModel,
  GridRowParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import {
  deleteMessageTemplate,
  getMessageTemplatesByOrgId,
} from '@app/adapter/chat-service';
import { PageTitle } from '@app/components/Shared/PageTitle';
import { SearchTextBox } from '@app/components/Shared/SearchTextBox';
import {
  INITIAL_PAGINATE_MODEL,
  StandardStyleDataGrid,
} from '@app/components/Shared/StandardStyleDataGrid';
import { organization } from '@app/domain/organization';
import { Template } from '@app/types/chats';
import { isError } from '@app/utils/error';
import { restoreSanitizedString } from '@app/utils/format';
import { truncateString } from '@app/utils/pattern';
import { useSetSnackbar } from '@app/utils/useSetSnackbar';

export function Templates() {
  const theme = useTheme();
  const navigate = useNavigate();
  const setSnackbar = useSetSnackbar();
  const orgId = useRecoilValue(organization).id;

  const [keyword, setKeyword] = useState<string>('');
  const [templateToDelete, setTemplateToDelete] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isBulkDeleteModalOpen, setIsBulkDeleteModalOpen] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(
    []
  );
  const columns: Array<GridColDef> = [
    {
      field: 'title',
      headerName: 'テンプレートタイトル',
      renderCell: (params) => (
        <Box
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {params.row.title}
        </Box>
      ),
      sortable: false,
      width: 300,
    },
    {
      field: 'content',
      headerName: 'メッセージ',
      renderCell: (params) => (
        <Box
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
          }}
        >
          {truncateString(restoreSanitizedString(params.row.content))}
        </Box>
      ),
      sortable: false,
      width: 400,
    },
    {
      field: 'actions',
      headerName: '',
      renderCell: (params) => (
        <>
          <IconButton
            sx={{
              border: `1px solid ${theme.customPalette.lightGray}`,
              borderRadius: '4px',
              mr: 1,
            }}
            onClick={(event) => {
              event.stopPropagation();
              navigate(`/chats/templates/${params.row.id}/copy`);
            }}
          >
            <ContentCopyOutlinedIcon />
          </IconButton>
          <IconButton
            sx={{
              border: `1px solid ${theme.customPalette.lightGray}`,
              borderRadius: '4px',
            }}
            onClick={(event) => {
              event.stopPropagation();
              setTemplateToDelete(params.row.id);
              setIsDeleteModalOpen(true);
            }}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </>
      ),
      sortable: false,
      width: 112,
    },
  ];

  // Pagination
  const [currentPaginationModel, setCurrentPaginationModel] =
    useState<GridPaginationModel>(INITIAL_PAGINATE_MODEL);
  const [rows, setRows] = useState<Array<Template>>([]);
  const [rowTotal, setRowTotal] = useState<number>(
    INITIAL_PAGINATE_MODEL.pageSize
  );

  const fetchTemplates = async () => {
    setIsLoading(true);
    try {
      const { page, pageSize } = currentPaginationModel;
      const result = await getMessageTemplatesByOrgId(orgId, {
        filter: {
          keyword,
        },
        skip: Math.ceil(page * pageSize),
        top: pageSize,
      });
      setRows(result.data.value);
      setRowTotal(result.data.total);
    } catch (e) {
      setSnackbar(true, 'テンプレートの取得に失敗しました', 'error');
      if (isError(e)) {
        console.error(e.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, currentPaginationModel, keyword]);

  const deleteTemplates = async (id?: string) => {
    const selected = id ? [id] : selectionModel;
    setIsActionLoading(true);
    try {
      await Promise.all(
        selected.map(async (id) => await deleteMessageTemplate(String(id)))
      );
    } catch (e) {
      setSnackbar(true, 'テンプレートの削除に失敗しました', 'error');
      if (isError(e)) {
        console.error(e.message);
      }
    } finally {
      setIsActionLoading(false);
    }
    void fetchTemplates();
  };

  const closeDeleteModal = () => {
    setTemplateToDelete(undefined);
    setIsDeleteModalOpen(false);
  };
  const closeBulkDeleteModal = () => {
    setIsBulkDeleteModalOpen(false);
  };
  const handleClickDelete = async () => {
    if (templateToDelete) {
      await deleteTemplates(templateToDelete);
    }
    closeDeleteModal();
  };
  const handleClickBulkDelete = async () => {
    await deleteTemplates();
    closeBulkDeleteModal();
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" pb={2}>
        <PageTitle title="テンプレート一覧" />
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() => navigate('/chats/templates/register')}
          sx={{ width: '120px' }}
        >
          新規登録
        </Button>
      </Stack>
      <Card>
        <CardContent>
          <Stack direction="row" justifyContent="space-between">
            <SearchTextBox value={keyword} onSubmit={setKeyword} />
            <LoadingButton
              color="secondary"
              variant="outlined"
              size="small"
              disabled={selectionModel.length < 1}
              loading={isActionLoading}
              onClick={() => setIsBulkDeleteModalOpen(true)}
            >
              一括削除
            </LoadingButton>
          </Stack>
        </CardContent>
        <StandardStyleDataGrid
          paginationModel={currentPaginationModel}
          columns={columns}
          rows={rows}
          rowCount={rowTotal}
          rowHeight={90}
          loading={isLoading}
          checkboxSelection
          disableColumnMenu
          rowSelectionModel={selectionModel}
          onRowClick={(params: GridRowParams<Template>) => {
            navigate(`/chats/templates/${params.row.id}/edit`);
          }}
          onRowSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          onPaginationModelChange={setCurrentPaginationModel}
        />
      </Card>
      <Dialog open={isDeleteModalOpen} onClose={closeDeleteModal}>
        <DialogTitle>{'テンプレートの削除'}</DialogTitle>
        <DialogContent style={{ padding: '24px 40px' }}>
          <DialogContentText>
            本当にこのテンプレートを削除しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ pb: 2 }}>
          <Button
            color="secondary"
            variant="outlined"
            size="small"
            onClick={closeDeleteModal}
          >
            キャンセル
          </Button>
          <LoadingButton
            color="primary"
            variant="contained"
            size="small"
            loading={isActionLoading}
            onClick={handleClickDelete}
          >
            削除する
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog open={isBulkDeleteModalOpen} onClose={closeBulkDeleteModal}>
        <DialogTitle>{'テンプレートの一括削除'}</DialogTitle>
        <DialogContent style={{ padding: '24px 40px' }}>
          <DialogContentText>
            選択されたテンプレートを全て削除しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ pb: 2 }}>
          <Button
            color="secondary"
            variant="outlined"
            size="small"
            onClick={closeBulkDeleteModal}
          >
            キャンセル
          </Button>
          <LoadingButton
            color="primary"
            variant="contained"
            size="small"
            loading={isActionLoading}
            onClick={handleClickBulkDelete}
          >
            削除する
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
