import {
  Card,
  CardContent,
  useMediaQuery,
  Container,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/system';
import { ReactElement } from 'react';

import { RegistrationForm } from '@app/components/Registration/RegistrationForm';
import { InquiryFormLink } from '@app/components/Shared/InquiryFormLink';

export function Register(): ReactElement {
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const RegistrationContent = (
    <>
      <Typography
        sx={{
          fontSize: '24px',
          fontWeight: 600,
          lineHeight: '33.6px',
          textAlign: 'center',
        }}
      >
        新規登録
      </Typography>
      <RegistrationForm />

      {/* 問い合わせ */}
      <InquiryFormLink />
    </>
  );

  return (
    <>
      {isMd ? (
        <Container sx={{ maxWidth: '550px !important' }}>
          <Card sx={{ mb: 10, mt: 5, pb: 3, pt: 5, px: 5 }}>
            <CardContent>{RegistrationContent}</CardContent>
          </Card>
        </Container>
      ) : (
        <Container sx={{ bgcolor: '#fff', py: 5 }}>
          {RegistrationContent}
        </Container>
      )}
    </>
  );
}
