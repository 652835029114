import { Paper, CircularProgress, Typography, Box } from '@mui/material';
import { ComponentProps, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { getSingleOrder } from '@app/adapter/order-service';
import { ReviewEnd } from '@app/components/Review/ReviewEnd';
import { ReviewForm } from '@app/components/Review/ReviewForm';
import { snackbarOpenState, snackbarTextState } from '@app/domain/app';
import { organizationSelector } from '@app/domain/organization';
import { OrderLocalized } from '@app/types/order';
import { convertOrderLocalized } from '@app/utils/order';

export function Review() {
  const pageLocation = useLocation();
  const orderId = pageLocation.pathname.split('/')[2] ?? '';
  const organizationState = useRecoilValue(organizationSelector);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [order, setOrder] = useState(undefined as OrderLocalized | undefined);
  const [endStatus, setEndStatus] = useState(
    '' as '' | 'confirm' | 'cancel' | 'decline'
  );
  const [isLoadData, setIsLoadData] = useState(false);

  const loadOrder = useCallback(async () => {
    try {
      if (!orderId) {
        return;
      }
      const result = await getSingleOrder(organizationState.id, orderId);
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }
      setOrder(convertOrderLocalized(result.data));
    } catch (error) {
      setSnackbarText(
        `応募の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    } finally {
      setIsLoadData(true);
    }
  }, [organizationState.id, orderId, setSnackbarOpen, setSnackbarText]);

  useEffect(() => {
    void loadOrder();
  }, [loadOrder]);

  const handleClosReviewForm: ComponentProps<typeof ReviewForm>['onClose'] =
    useCallback((result) => {
      setEndStatus(result);
    }, []);

  if (endStatus) {
    return <ReviewEnd endStatus={endStatus} user={order?.customer.user} />;
  }

  if (!isLoadData || !order) {
    return (
      <Box>
        <Paper
          sx={{
            display: 'flex',
            height: '43rem',
            mx: 'auto',
            p: 1,
            width: '43rem',
          }}
        >
          {!isLoadData ? (
            <CircularProgress sx={{ m: 'auto' }} />
          ) : (
            <Typography sx={{ m: 'auto' }}>
              ID:{orderId}の応募を取得できませんでした
            </Typography>
          )}
        </Paper>
      </Box>
    );
  }

  return (
    <Box display="flex">
      <Paper sx={{ mx: 'auto', p: 1, width: '43rem' }}>
        <ReviewForm
          orderId={orderId}
          onClose={handleClosReviewForm}
          productId={order.lineItem.product}
          userId={order.customer.user.id}
        />
      </Paper>
    </Box>
  );
}
