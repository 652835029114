import {
  Paper,
  Box,
  Typography,
  TablePagination,
  Table,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  TableBody,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Fragment, useState, ComponentProps, memo, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  OrderInfoItem,
  OrderInfoItemProps,
} from '@app/components/Home/OrderInfoItem';
import { formatDate } from '@app/utils/format';

const ContentsPaper = styled(Paper)({
  height: '40rem',
  overflow: 'hidden',
  position: 'relative',
  width: '28rem',
});

export interface OrderInfoProps {
  date?: string;
  orderGroupList?: OrderInfoGroup[];
}

export interface OrderInfoGroup {
  orderList?: OrderInfoItemProps[];
  productId?: string;
  productName?: string;
}

export const OrderInfo = memo(function ({
  date,
  orderGroupList,
}: OrderInfoProps) {
  const dt = new Date(date ?? '');
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handlePageChange: ComponentProps<
    typeof TablePagination
  >['onPageChange'] = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange: ComponentProps<
    typeof TablePagination
  >['onRowsPerPageChange'] = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, [orderGroupList]);

  if (!date) {
    return <ContentsPaper />;
  }

  return (
    <ContentsPaper>
      <TableContainer sx={{ maxHeight: '37rem' }}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: 'left' }} colSpan={3}>
                <Typography
                  fontSize="1.1rem"
                  fontWeight="700"
                  color={theme.palette.grey[700]}
                >
                  {formatDate(dt)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderGroupList?.map((applyInfo, idx) => {
              const rowCount =
                orderGroupList
                  .filter((a, i) => i < idx)
                  .reduce((v, a) => {
                    return v + (a.orderList?.length ?? 0);
                  }, 0) + 1;

              if (
                page * rowsPerPage >=
                  rowCount + (applyInfo.orderList?.length ?? 0) - 1 ||
                page * rowsPerPage + rowsPerPage < rowCount
              ) {
                return undefined;
              }

              return (
                <Fragment key={idx}>
                  <TableRow>
                    <TableCell
                      sx={{ bgcolor: theme.palette.grey[100] }}
                      colSpan={3}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        gap={2}
                        alignItems="center"
                        justifyContent="top"
                      >
                        <Link
                          to={`/products/${applyInfo.productId}`}
                          style={{ color: theme.palette.text.primary }}
                        >
                          <Typography fontSize="0.95rem">
                            {applyInfo.productName}
                          </Typography>
                        </Link>
                      </Box>
                    </TableCell>
                  </TableRow>
                  {applyInfo.orderList?.map((detail, idx2) => {
                    if (
                      page * rowsPerPage >= rowCount + idx2 ||
                      page * rowsPerPage + rowsPerPage < rowCount + idx2
                    ) {
                      return undefined;
                    }

                    return <OrderInfoItem {...detail} key={idx2} />;
                  })}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          bottom: 0,
          fontSize: '0.7rem',
          position: 'absolute',
          width: '100%',
        }}
        component="div"
        labelRowsPerPage={
          <label style={{ fontSize: '0.8rem' }}>1ページの表示数</label>
        }
        rowsPerPageOptions={[5, 10, 20, 50]}
        labelDisplayedRows={() => ''}
        showFirstButton
        showLastButton
        count={
          orderGroupList?.reduce((v, a) => {
            return v + (a.orderList?.length ?? 0);
          }, 0) ?? 0
        }
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        data-e2e="home-pagination"
      />
    </ContentsPaper>
  );
});
