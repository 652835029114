import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Typography,
  FormHelperText,
  Select,
  Divider,
  useTheme,
  styled,
  CircularProgress,
} from '@mui/material';
import {
  useCallback,
  useEffect,
  useState,
  ReactNode,
  ComponentProps,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { z } from 'zod';

import {
  getSingleProduct,
  updateProduct,
  deleteProduct,
} from '@app/adapter/catalog-service';
import { useNavigateAdminDelegatable as useNavigate } from '@app/administrator/utils/useNavigateAdminDelegatable';
import { ProductDetailComponent } from '@app/components/Catalog/ProductDetailComponent';
import { ProductLayout } from '@app/components/Layout/ProductLayout';
import { ConfirmDialog } from '@app/components/Shared/ConfirmDialog';
import {
  snackbarOpenState,
  snackbarTextState,
  snackbarSeverityState,
} from '@app/domain/app';
import {
  useAttributesClinicalDepartment,
  useAttributesJobType,
} from '@app/hooks/catalog';
import { Product } from '@app/types/catalog';
import { getMenuItems } from '@app/utils/components';
import { productStatusList } from '@app/utils/constants';

// useNavigate を置き換え

const ColBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

const RowBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
});

function ItemLabel(props: {
  message?: ReactNode;
  required?: boolean;
  title: string;
}) {
  const theme = useTheme();
  return (
    <RowBox alignItems="center" gap={2}>
      <RowBox alignItems="center" gap={0.5}>
        <Typography>{props.title}</Typography>
        {props.required && (
          <Typography color={theme.customPalette.alert}>*</Typography>
        )}
      </RowBox>
      {props.message}
    </RowBox>
  );
}

function FormControlItem(props: {
  children: ReactNode;
  message?: ReactNode;
  required?: boolean;
  title: string;
}) {
  return (
    <ColBox gap={1}>
      <ItemLabel
        title={props.title}
        required={props.required}
        message={props.message}
      />
      <ColBox>{props.children}</ColBox>
    </ColBox>
  );
}

export const productFormSchema = z.object({
  status: z.enum(['ACTIVE', 'ARCHIVED', 'DRAFT']),
});

export type ProductForm = z.infer<typeof productFormSchema>;

export interface ProductEditStatusState {
  id?: string;
}

export function ProductEditStatus() {
  const navigate = useNavigate();
  const { productId } = useParams();
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const [product, setProduct] = useState<Product | undefined>(undefined);
  const attributeClinicalDepartmentList = useAttributesClinicalDepartment();
  const attributeJobTypeList = useAttributesJobType();
  // データ読み込み済みかどうかの状態
  const [isLoadData, setIsLoadData] = useState(false);
  const [isLoadingRegister, setIsLoadingRegister] = useState(false);
  const [shouldShowDeleteDialog, setShouldShowDeleteDialog] = useState(false);
  const theme = useTheme();

  const { control, handleSubmit, reset } = useForm<ProductForm>({
    defaultValues: { status: 'ACTIVE' },
    mode: 'onChange',
    resolver: zodResolver(productFormSchema),
  });

  /** 削除ボタンクリック処理 */
  const handleDeleteClick: NonNullable<
    ComponentProps<typeof Button>['onClick']
  > = useCallback(async () => {
    setShouldShowDeleteDialog(() => true);
  }, []);

  /** プレビューボタンクリック処理 */
  const handleClickPreview: Parameters<typeof handleSubmit>[0] =
    useCallback(() => {
      console.log('');
    }, []);

  /** 登録ボタンクリック処理 */
  const handleClickRegister: Parameters<typeof handleSubmit>[0] = useCallback(
    async (formValue) => {
      if (!productId) {
        return;
      }
      try {
        setIsLoadingRegister(true);
        const result = await updateProduct(
          {
            publication: {
              since: product?.publication.since, // TODO:項目値が消えるAPI不備が直れば削除する
              status: formValue.status,
              until: product?.publication.until, // TODO:項目値が消えるAPI不備が直れば削除する
            },
          },
          productId
        );
        if (result.status !== 200) {
          throw new Error(`${result.status} ${result.statusText}`);
        }
        navigate(`/products/${productId}`);
      } catch (error) {
        setSnackbarText(
          `求人の更新に失敗しました。, ${
            error instanceof Error ? error.message : error
          }`
        );
        setSnackbarOpen(true);
      } finally {
        setIsLoadingRegister(false);
      }
    },
    [
      productId,
      product?.publication.since,
      product?.publication.until,
      navigate,
      setSnackbarText,
      setSnackbarOpen,
    ]
  );

  const setSnackbarState = useSetRecoilState(snackbarSeverityState);
  const [isDeleting, setIsDeleting] = useState(false);
  const handleCloseDeleteDialog: ComponentProps<
    typeof ConfirmDialog
  >['onClose'] = useCallback(
    async (confirm) => {
      if (!confirm || !productId) {
        setShouldShowDeleteDialog(false);
        return;
      }
      try {
        setIsDeleting(true);
        await deleteProduct(productId);
        setSnackbarText(`${productId}の求人を削除しました。`);
        setSnackbarState('info');
        setSnackbarOpen(true);
        navigate('/products');
      } catch (error) {
        setSnackbarText(
          `求人の削除に失敗しました。, ${
            error instanceof Error ? error.message : error
          }`
        );
        setSnackbarOpen(true);
      } finally {
        setIsDeleting(false);
        setShouldShowDeleteDialog(false);
      }
    },
    [navigate, productId, setSnackbarOpen, setSnackbarState, setSnackbarText]
  );

  /** 求人データの読み込み */
  const loadProduct = useCallback(async () => {
    if (!productId) {
      return;
    }
    try {
      const result = await getSingleProduct(productId);
      if (result.status !== 200) {
        throw new Error(`${result.status} ${result.statusText}`);
      }

      const product = result.data;
      const formValues: ProductForm = {
        status:
          (product.publication.status as ProductForm['status']) ?? 'ACTIVE',
      };
      reset(formValues);
      setProduct(product);
    } catch (error) {
      setSnackbarText(
        `求人の取得に失敗しました, ${
          error instanceof Error ? error.message : error
        }`
      );
      setSnackbarOpen(true);
    } finally {
      setIsLoadData(true);
    }
  }, [reset, setSnackbarText, setSnackbarOpen, productId, setIsLoadData]);

  useEffect(() => {
    void loadProduct();
  }, [loadProduct]);

  // 編集、複製の場合、求人データが読み込めるまで内容を表示しない
  if (!isLoadData) {
    return (
      <ProductLayout
        sx={{
          minWidth: 1000,
          px: 0,
        }}
      >
        <Typography variant="h5">{productId}を編集</Typography>
        <CircularProgress sx={{ m: 'auto' }} />
      </ProductLayout>
    );
  }

  if (!product) {
    return (
      <ProductLayout
        sx={{
          minWidth: 1000,
          px: 0,
        }}
      >
        <Typography variant="h5">{productId}を編集</Typography>
        <Typography sx={{ m: 'auto' }}>
          ID:{productId}の求人を取得できませんでした
        </Typography>
      </ProductLayout>
    );
  }

  return (
    <form>
      <ProductLayout sx={{ minWidth: 1000, px: 0 }}>
        <RowBox alignItems="center" gap={1} px={2}>
          <Typography variant="h5">{productId}を編集</Typography>

          <RowBox alignItems="center" gap={2} ml="auto">
            <Button
              variant="outlined"
              size="small"
              sx={{
                bgcolor: theme.palette.common.white,
                border: `solid 1px ${theme.customPalette.alert}`,
                color: theme.customPalette.alert,
                width: '5rem',
              }}
              onClick={handleDeleteClick}
              disabled={true}
            >
              削除
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{
                display: 'none', // TODO:プレビュー機能が実装されたら削除する
                width: '8rem',
              }}
              onClick={handleSubmit(handleClickPreview)}
            >
              プレビュー
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              sx={{ width: '8rem' }}
              onClick={handleSubmit(handleClickRegister)}
              loading={isLoadingRegister}
            >
              保存
            </LoadingButton>
          </RowBox>
        </RowBox>

        <Divider />
        <ColBox p={2}>
          <FormControlItem title="ステータス" required>
            <Controller
              name="status"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Select
                    {...field}
                    error={!!error}
                    sx={{
                      bgcolor: theme.palette.common.white,
                      width: '20rem',
                    }}
                    displayEmpty
                  >
                    {getMenuItems(productStatusList)}
                  </Select>
                  {error ? (
                    <FormHelperText error sx={{ ml: 2 }}>
                      {error.message}
                    </FormHelperText>
                  ) : undefined}
                </>
              )}
            />
          </FormControlItem>
        </ColBox>

        <ColBox gap={2} pb={4} px={2}>
          <Divider />
          <RowBox alignItems="center" gap={0.5} mb={6}>
            <Typography color="red">*</Typography>
            <Typography>は必須事項です</Typography>
          </RowBox>
          <ProductDetailComponent
            product={product}
            attributeClinicalDepartmentList={attributeClinicalDepartmentList}
            attributeJobTypeList={attributeJobTypeList}
          />
        </ColBox>
      </ProductLayout>
      <ConfirmDialog
        title="本当に削除しますか？"
        okButtonText="削除"
        okButtonLoading={isDeleting}
        open={shouldShowDeleteDialog}
        onClose={handleCloseDeleteDialog}
      />
    </form>
  );
}
