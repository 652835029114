import {
  Paper,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { getMessageListWithFilter } from '@app/adapter/notification-service';
import { PageTitle } from '@app/components/Shared/PageTitle';
import {
  isLoading,
  snackbarOpenState,
  snackbarSeverityState,
  snackbarTextState,
  userAuthInfoSelector,
} from '@app/domain/app';
import { messagesState } from '@app/domain/notification';
import {
  attachListeners,
  SocketIoClient,
  subscribeToStream,
} from '@app/socket';
import { isError } from '@app/utils/error';

export function Notification() {
  const messages = useRecoilValue(messagesState);
  const setMessages = useSetRecoilState(messagesState);
  const userAuthInfoState = useRecoilValue(userAuthInfoSelector);
  const setIsLoading = useSetRecoilState(isLoading);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const setSnackbarState = useSetRecoilState(snackbarSeverityState);
  const getMessages = useCallback(async () => {
    // Status will be added in future PR
    try {
      const result = await getMessageListWithFilter(
        userAuthInfoState.accessToken
      );

      setMessages(result.data);
    } catch (error) {
      if (isError(error)) {
        setSnackbarText(`Messageの取得に失敗しました, ${error.message}`);
      } else {
        setSnackbarText(`Messageの取得に失敗しました`);
      }
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  }, [
    userAuthInfoState.accessToken,
    setMessages,
    setSnackbarOpen,
    setSnackbarText,
    setIsLoading,
  ]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    getMessages();
  }, [getMessages]);

  const displayMessage = useCallback(
    (message: { content: string }): void => {
      setSnackbarText(message.content);
      setSnackbarOpen(true);
      setSnackbarState('info');
    },
    [setSnackbarText, setSnackbarOpen, setSnackbarState]
  );
  useEffect(() => {
    const socket = new SocketIoClient();
    async function initSocket() {
      await socket.init(
        userAuthInfoState.accessToken,
        userAuthInfoState.fingerprint
      );
      subscribeToStream(socket, userAuthInfoState.id);
      attachListeners(socket, displayMessage);
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initSocket();
    //connect(userAuthInfoState.token, userAuthInfoState.fingerprint);
    return () => {
      socket.disconnect();
    };
  }, [
    displayMessage,
    userAuthInfoState.fingerprint,
    userAuthInfoState.id,
    userAuthInfoState.accessToken,
  ]);

  return (
    <>
      <PageTitle title="通知" />
      <Paper elevation={1}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="normal" align="left">
                  通知タイトル
                </TableCell>
                <TableCell padding="normal" align="left">
                  内容
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {messages.value &&
                messages.value.length > 0 &&
                messages.value.map((message) => (
                  <TableRow
                    key={message.id}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="normal" align="left">
                      {message.title}
                    </TableCell>
                    <TableCell padding="normal" align="left">
                      {message.content}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
