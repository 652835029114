import {
  Stack,
  FormHelperText,
  MenuItem,
  Select,
  SelectProps,
  useTheme,
} from '@mui/material';
import { forwardRef, ForwardedRef, useEffect, useState } from 'react';

import { getLocationList } from '@app/adapter/catalog-service';
import { Location } from '@app/types/catalog';
import { isError } from '@app/utils/error';
import { useSetSnackbar } from '@app/utils/useSetSnackbar';

export interface PrefectureSelectProps extends SelectProps<string> {
  helperText?: string;
}

export const PrefectureSelect = forwardRef(function (
  { helperText, ...selectProps }: PrefectureSelectProps,
  ref
) {
  const theme = useTheme();
  const setSnackbar = useSetSnackbar();
  const [list, setList] = useState<Location[]>([]);

  useEffect(() => {
    void (async () => {
      try {
        const result = await getLocationList({ type: 'prefecture' });
        setList(result.data.value);
      } catch (e) {
        if (isError(e)) {
          console.error(e.message);
        }
        setSnackbar(true, '都道府県の取得に失敗しました');
      }
    })();
  }, [setSnackbar]);

  return (
    <Stack>
      <Select
        {...selectProps}
        ref={ref as ForwardedRef<HTMLDivElement>}
        MenuProps={{
          ...selectProps.MenuProps,
          sx: [
            { maxHeight: '25rem' },
            // https://mui.com/system/getting-started/the-sx-prop/#passing-the-sx-prop
            ...(selectProps.MenuProps?.sx
              ? Array.isArray(selectProps.MenuProps.sx)
                ? selectProps.MenuProps.sx
                : [selectProps.MenuProps.sx]
              : []),
          ],
        }}
        displayEmpty
        renderValue={(selected: string) => {
          const name = list.find((item) => item.id === selected)?.name;
          if (name) {
            return name;
          }
          return (
            <span
              style={{
                color: theme.customPalette.lightGray,
              }}
            >
              都道府県を選択
            </span>
          );
        }}
      >
        <MenuItem value={selectProps.value ?? ''} disabled>
          都道府県を選択
        </MenuItem>
        {list.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      {helperText && (
        <FormHelperText error={selectProps.error} sx={{ ml: 2 }}>
          {helperText}
        </FormHelperText>
      )}
    </Stack>
  );
});
