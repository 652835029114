import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  TextField,
} from '@mui/material';
import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { z } from 'zod';

import { changeNameFromSetting } from '@app/adapter/user-service';
import {
  AccountSettingHeader,
  AccountSettingPaper,
} from '@app/components/AccountSetting/AccountSettingHeader';
import {
  userAuthInfoSelector,
  snackbarOpenState,
  snackbarSeverityState,
  snackbarTextState,
} from '@app/domain/app';

interface ChangeNameFormContent {
  familyName: string;
  firstName: string;
}

const ChangeNameForm = z.object({
  familyName: z.string().min(1, { message: '姓を入力してください' }),
  firstName: z.string().min(1, { message: '名を入力してください' }),
});

export function NameChangePage() {
  const navigate = useNavigate();
  const userAuthInfoState = useRecoilValue(userAuthInfoSelector);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const setSnackbarState = useSetRecoilState(snackbarSeverityState);

  const { control, handleSubmit } = useForm<ChangeNameFormContent>({
    defaultValues: {
      familyName: '',
      firstName: '',
    },
    mode: 'all',
    resolver: zodResolver(ChangeNameForm),
  });

  const onSubmit = useCallback(
    async (data: ChangeNameFormContent) => {
      const res = await changeNameFromSetting(
        userAuthInfoState.id,
        data.familyName,
        data.firstName
      );
      if (res.status === 200) {
        setSnackbarText('名前を変更しました。');
        setSnackbarState('success');
        setSnackbarOpen(true);
      } else {
        setSnackbarText('名前の変更に失敗しました。');
        setSnackbarState('error');
        setSnackbarOpen(true);
      }
      navigate('/account-setting');
    },
    [
      navigate,
      userAuthInfoState.id,
      setSnackbarText,
      setSnackbarState,
      setSnackbarOpen,
    ]
  );

  return (
    <>
      <AccountSettingHeader title="アカウント設定" />
      <AccountSettingPaper title="お名前変更">
        <Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid sx={{ minWidth: 500 }}>
              <Grid container spacing={3} sx={{ pt: 2 }}>
                <Grid item xs={12}>
                  <FormControl variant="outlined" sx={{ width: 400 }}>
                    <FormLabel>
                      <Typography
                        color="textPrimary"
                        component="div"
                        variant="subtitle2"
                        fontWeight={600}
                        sx={{ textAlign: 'left' }}
                      >
                        新しい姓
                        <Typography component="span" color="error">
                          *
                        </Typography>
                      </Typography>
                    </FormLabel>
                    <Controller
                      name="familyName"
                      control={control}
                      rules={{ required: true }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={error ? true : undefined}
                          helperText={error?.message}
                          margin="dense"
                          placeholder="新しい姓を入力"
                          type="text"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" sx={{ width: 400 }}>
                    <FormLabel>
                      <Typography
                        color="textPrimary"
                        component="div"
                        variant="subtitle2"
                        fontWeight={600}
                        sx={{ textAlign: 'left' }}
                      >
                        新しい名
                        <Typography component="span" color="error">
                          *
                        </Typography>
                      </Typography>
                    </FormLabel>
                    <Controller
                      name="firstName"
                      control={control}
                      rules={{ required: true }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={error ? true : undefined}
                          helperText={error?.message}
                          margin="dense"
                          placeholder="新しい名を入力"
                          type="text"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{ pr: 3, py: 2 }}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                sx={{ pl: 5, pr: 5 }}
              >
                変更
              </Button>
            </Grid>
          </form>
        </Grid>
      </AccountSettingPaper>
    </>
  );
}
