import {
  ArrowBack as ArrowBackIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { reverse } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  getAllMessagesToOneTopic,
  getTopic,
  markMessageAsRead,
} from '@app/adapter/chat-service';
import { ChatInputForm } from '@app/components/Chat/ChatInputForm';
import { MessageItem } from '@app/components/Chat/MessageItem';
import { useDeleteLogic } from '@app/components/Chat/useDeleteLogic';
import {
  userAuthInfoSelector,
  snackbarOpenState,
  snackbarTextState,
} from '@app/domain/app';
import { chatTopicsState, messagesState } from '@app/domain/chat';
import { useAppContext } from '@app/hooks/appContext';
import { Message, MessageReadStatus } from '@app/types/chats';
import { getAge } from '@app/utils';
import { isError } from '@app/utils/error';
import { formatRelativeDate } from '@app/utils/format';

export function ChatDetail() {
  const theme = useTheme();
  const { fetchMessagesAndUnreadCount, unreadCount } = useAppContext();
  const navigate = useNavigate();
  const { topicId } = useParams<{ topicId: string }>();
  const authInfo = useRecoilValue(userAuthInfoSelector);
  const setErrorSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setErrorSnackbarText = useSetRecoilState(snackbarTextState);
  const [messages, setMessages] = useRecoilState(messagesState);
  const [chatTopics] = useRecoilState(chatTopicsState);

  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [nextLink, setNextLink] = useState<string>('');
  const [topicInfo, setTopicInfo] = useState(
    chatTopics.value.find((topic) => topic.id === topicId)
  );

  const loadMoreMessages = async () => {
    await fetchMessages(nextLink);
  };

  const {
    anchorPosition,
    handleCloseContextMenu,
    handleContextMenu,
    handleDeleteMessage,
    handleTouchEnd,
    handleTouchStart,
    messageToDelete,
  } = useDeleteLogic(messages, setMessages);

  const getChatTopics = useCallback(async () => {
    if (!topicId) return;
    try {
      const result = await getTopic(topicId, { expand: 'user' });
      setTopicInfo(result.data);
    } catch (e) {
      if (isError(e)) {
        console.error('getChatTopics', e.message);
      }
    }
  }, [topicId]);

  const replyUser = useMemo(() => {
    const user = topicInfo?.owners?.find((owner) => owner.id !== authInfo?.id);
    return user?.customFields
      ? `${user.customFields.familyName}${user.customFields.firstName}` +
          ` / ${getAge(user.customFields.birthday)}歳・${
            user.customFields.gender
          }`
      : '';
  }, [authInfo, topicInfo]);

  const isInputDisabled = useMemo(() => {
    return messages.at(-1)?.title === 'scout';
  }, [messages]);

  const fetchMessages = useCallback(
    async (next?: string) => {
      if (!topicId) return;
      try {
        setIsLoading(true);
        const result = await getAllMessagesToOneTopic(topicId, {
          expand: 'user',
          nextLink: next,
          orderBy: 'createdAt desc',
        });

        const resultValues = reverse(result.data.value);
        setMessages(next ? [...resultValues, ...messages] : resultValues);
        setNextLink(result.data['@nextLink']);
      } catch (error) {
        if (isError(error)) {
          console.error(error.message);
        }
        setErrorSnackbarText('Messageの取得に失敗しました');
        setErrorSnackbarOpen(true);
      } finally {
        setIsLoading(false);
      }
    },
    [messages, setMessages, setErrorSnackbarOpen, setErrorSnackbarText, topicId]
  );

  const displayMessage = useCallback(
    async (message: Message) => {
      setMessages([...messages, message]);
    },
    [messages, setMessages]
  );

  useEffect(() => {
    if (!topicInfo) {
      void getChatTopics();
    }
    void fetchMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadCount]);

  const markLatestMessageAsRead = useCallback(
    async (message: Message) => {
      if (!authInfo || !topicId) return;
      try {
        if (message.readStatus === MessageReadStatus.UNREAD) {
          await markMessageAsRead(message.id, authInfo.id);
          await fetchMessagesAndUnreadCount();
        }
      } catch (error) {
        console.error('Error marking the latest message as read:', error);
      }
    },
    [authInfo, topicId, fetchMessagesAndUnreadCount]
  );

  useEffect(() => {
    if (!authInfo) return;
    const latestReceive = reverse([...messages]).find(
      (m) => m.senderId !== authInfo.id
    );
    if (latestReceive) {
      void markLatestMessageAsRead(latestReceive);
    }
  }, [authInfo, messages, markLatestMessageAsRead]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <>
      <Stack spacing={2}>
        <Stack direction="row" spacing={1} alignItems="center">
          <IconButton
            size="small"
            onClick={() => navigate('/chats')}
            sx={{
              backgroundColor: 'white',
              border: '1px solid',
              borderRadius: '4px',
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6">メッセージ一覧</Typography>
        </Stack>
        <Box pb={9}>
          <Grid
            sx={{
              alignItems: 'center',
              backgroundColor: theme.customPalette.white,
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
              display: 'flex',
              position: 'sticky',
              py: 2,
              top: 64,
              zIndex: 1,
            }}
          >
            <Typography sx={{ flexGrow: 1, fontSize: '1.2rem', ml: 3 }}>
              {replyUser}
            </Typography>
          </Grid>
          <Divider />
          <Container sx={{ backgroundColor: theme.customPalette.white }}>
            <Stack spacing={3} pt={3}>
              {nextLink && (
                <Box textAlign="center">
                  <Button
                    disabled={isLoading}
                    onClick={loadMoreMessages}
                    sx={{
                      backgroundColor: theme.palette.text.primary,
                      borderRadius: '20px',
                      color: theme.customPalette.white,
                    }}
                  >
                    <Typography variant="body2">やり取りを読み込む</Typography>
                  </Button>
                </Box>
              )}
              {
                messages.reduce<{
                  elements: JSX.Element[];
                  prevDate: string | null;
                }>(
                  (acc, message, index) => {
                    const formattedDate = formatRelativeDate(message.createdAt);
                    if (acc.prevDate !== formattedDate) {
                      acc.elements.push(
                        <Box key={`date-${index}`} textAlign="center">
                          <Chip
                            label={
                              <Typography variant="body2">
                                {formattedDate}
                              </Typography>
                            }
                            sx={{ color: theme.customPalette.gray }}
                          />
                        </Box>
                      );
                      acc.prevDate = formattedDate;
                    }

                    acc.elements.push(
                      <MessageItem
                        key={message.id}
                        message={message}
                        onContextMenu={handleContextMenu}
                        onTouchEnd={handleTouchEnd}
                        onTouchStart={handleTouchStart}
                      />
                    );

                    return acc;
                  },
                  { elements: [], prevDate: null }
                ).elements
              }
              <div ref={messagesEndRef} />
            </Stack>
          </Container>
        </Box>
        <Grid
          sx={{
            backgroundColor: theme.customPalette.white,
            borderTop: `1px solid ${theme.customPalette.silver}`,
            bottom: 0,
            minHeight: '100px',
            pl: '280px',
            position: 'fixed',
            right: 0,
            width: '100%',
          }}
        >
          <ChatInputForm
            topicId={topicId}
            onSent={displayMessage}
            disabled={isInputDisabled}
          />
        </Grid>
      </Stack>
      <Popover
        open={Boolean(anchorPosition)}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        onClose={handleCloseContextMenu}
      >
        <MenuItem
          onClick={() => {
            if (messageToDelete) {
              void handleDeleteMessage(messageToDelete);
            }
            handleCloseContextMenu();
          }}
          sx={{ padding: '10px 18px' }}
        >
          <DeleteOutlineIcon sx={{ marginRight: 1 }} />
          削除
        </MenuItem>
      </Popover>
    </>
  );
}
