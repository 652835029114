import _ from 'lodash';
import IO, { Socket } from 'socket.io-client';

import { NOTIFICATION_SERVICE } from '@app/adapter/axios';
import { Message } from '@app/types/notifications';
import { getAuthorizationHeader } from '@app/utils/authorization';

export class SocketIoClient {
  socket: Socket | undefined;
  async init(token: string, fingerprint: string) {
    if (!token || !fingerprint) {
      //logger.warn('token or fingerprint are not set');
      return false;
    }
    this.socket = IO(`${NOTIFICATION_SERVICE}notifications`, {
      query: {
        'x-nb-fingerprint': fingerprint,
        ...getAuthorizationHeader(token),
      },
      transports: ['websocket'],
    });
    return true;
  }
  isConnected() {
    return this.socket && this.socket.connected;
  }
  isReady() {
    return !_.isNil(this.socket);
  }
  disconnect() {
    this.socket?.disconnect();
  }

  setHandler(key: string, handler: (...args: unknown[]) => void) {
    if (!this.isReady()) {
      //logger.info('is not ready');
    }
    this.socket?.on(key, handler);
  }
  emit(key: string, payload: unknown) {
    this.socket?.emit(key, payload);
  }
}

export const socket = new SocketIoClient();

export function attachListeners(
  socket: SocketIoClient,
  callback: (message: Message) => void
) {
  socket.setHandler('notification', async (notification: unknown) => {
    callback(notification as Message);
  });
}

export function subscribeToStream(socket: SocketIoClient, userId: string) {
  socket.emit('subscribe', { userId });
}

export async function socketMiddleware(
  token: string,
  fingerprint: string,
  userId: string
) {
  if (!socket.isReady()) {
    if (await socket.init(token, fingerprint)) {
      attachListeners(socket, (message) => {
        // Handle the message or call another function here.
      });
      subscribeToStream(socket, userId);
    }
  } else {
    //logger.info('socket ready');
    if (!socket.isConnected) {
      //logger.info('socket lost connection, re-subscribe');
      subscribeToStream(socket, userId);
    }
  }
}
