import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  Divider,
  Link as MuiLink,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback } from 'react';

import { StarsRating } from '@app/components/Shared/StarsRating';
import { useAttributesClinicalDepartment } from '@app/hooks/catalog';
import {
  AttachmentFiles,
  OrderStatus,
  SupportedOrderStatus,
} from '@app/types/order';
import { User } from '@app/types/user';
import { getAge } from '@app/utils';
import { convertNewLineToBr } from '@app/utils/components';

interface ItemBoxProps {
  content: React.ReactNode;
  title: string | React.ReactNode;
}
const ItemBox = ({ content, title }: ItemBoxProps) => (
  <Stack direction="row" alignItems="center">
    <Typography variant="body2" sx={{ width: '190px' }}>
      {title}
    </Typography>
    <Box>{content}</Box>
  </Stack>
);

export interface UserProfileProps {
  attachment?: {
    orderAfterFiles?: AttachmentFiles[];
    orderMatchedFiles?: AttachmentFiles[];
  };
  orderStatus?: SupportedOrderStatus;
  user: User;
}

export function UserProfile({
  user,
  orderStatus,
  attachment,
}: UserProfileProps) {
  const attributeClinicalDepartmentList = useAttributesClinicalDepartment();
  const theme = useTheme();
  const getAttachUri = useCallback(
    (id: string, type: 'orderAfterFiles' | 'orderMatchedFiles') => {
      const target = attachment && attachment[type];
      const result =
        target && target.length && target.find((item) => item.id === id);
      return (result && result.url) || undefined;
    },
    [attachment]
  );

  return (
    <Box sx={{ width: '75%' }}>
      <Stack spacing={3} divider={<Divider />}>
        <Typography>プロフィール</Typography>
        <ItemBox
          title="評価"
          content={
            <Stack direction="row" spacing={1} alignItems="center">
              {!user?.reviewStats?.totalCount ? (
                <Typography>-</Typography>
              ) : (
                <>
                  <Typography>
                    {Math.floor(user?.reviewStats?.averageRate * 10) / 10}
                  </Typography>
                  <StarsRating
                    rate={user?.reviewStats?.averageRate}
                    iconProps={{ fontSize: 'small' }}
                  />
                  <MuiLink href="#review" color={theme.palette.text.primary}>
                    <Typography fontSize="0.9rem">
                      {user?.reviewStats?.totalCount ?? '-'}件の評価
                    </Typography>
                  </MuiLink>
                </>
              )}
            </Stack>
          }
        />
        <ItemBox
          title="性別/年齢"
          content={
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>{user?.customFields?.gender || '-'}</Typography>
              <Typography>/</Typography>
              <Typography>
                {getAge(user?.customFields?.birthday) || '-'}歳
              </Typography>
            </Stack>
          }
        />
        <ItemBox
          title="連絡先"
          content={
            <Stack>
              {!user?.addressLine1 &&
              !user?.addressLine2 &&
              !user?.addressLine3 &&
              !user?.customFields?.addressLine4 &&
              !user?.phoneNumber &&
              !user?.email ? (
                <Typography>-</Typography>
              ) : (
                <>
                  <Typography>
                    {user?.addressLine1}
                    {user?.addressLine2}
                    {user?.addressLine3}
                    {user?.customFields?.addressLine4}
                  </Typography>
                  <Typography>{user?.phoneNumber}</Typography>
                  <Typography>{user?.email}</Typography>
                </>
              )}
            </Stack>
          }
        />
        <ItemBox
          title={
            <>
              第一標榜科目/
              <br />
              診療科目
            </>
          }
          content={
            <Stack>
              {!user?.customFields?.clinicalDepartments?.length &&
              !user?.customFields?.firstClinicalDepartments?.length ? (
                <Typography>-</Typography>
              ) : (
                <>
                  <Typography>
                    {user?.customFields?.clinicalDepartments
                      ?.map(
                        (c) =>
                          attributeClinicalDepartmentList?.find(
                            (a) => a.id === c
                          )?.items?.[0].value ?? c
                      )
                      .join('、') || '-'}
                    {' /'}
                  </Typography>
                  <Typography>
                    {user?.customFields?.firstClinicalDepartments
                      ?.map(
                        (c) =>
                          attributeClinicalDepartmentList?.find(
                            (a) => a.id === c
                          )?.items?.[0].value ?? c
                      )
                      .join('、') || '-'}
                  </Typography>
                </>
              )}
            </Stack>
          }
        />
        <ItemBox
          title={
            <>
              医籍番号・医師免許取得年/
              <br />
              資格
            </>
          }
          content={
            <Stack>
              {!user?.customFields?.medicalRegisterNumber &&
              !user?.customFields?.medicalLicenseYear &&
              !user?.customFields?.license ? (
                <Typography>-</Typography>
              ) : (
                <>
                  <Typography>
                    {user?.customFields?.medicalRegisterNumber || '-'}・
                    {user?.customFields?.medicalLicenseYear || '-'}
                    {'年 /'}
                  </Typography>
                  <Typography>{user?.customFields?.license || '-'}</Typography>
                </>
              )}
            </Stack>
          }
        />
        <ItemBox
          title="出身大学"
          content={
            <Stack>
              <Typography>
                {user?.customFields?.universityName || '-'}
              </Typography>
            </Stack>
          }
        />
        <ItemBox
          title="所属"
          content={
            <Stack>
              {!user?.customFields?.currentHospital &&
              !user?.customFields?.currentDepartment ? (
                <Typography>-</Typography>
              ) : (
                <Typography>
                  {user?.customFields?.currentHospital || '-'}
                  {' / '}
                  {user?.customFields?.currentDepartment || '-'}
                </Typography>
              )}
            </Stack>
          }
        />
        <ItemBox
          title="特記事項"
          content={
            <Stack>
              <Typography>
                {convertNewLineToBr(user?.customFields?.notes || '-')}
              </Typography>
            </Stack>
          }
        />
        <ItemBox
          title="自信があるもの"
          content={
            <Stack>
              {!user?.customFields?.confidences?.length ? (
                <Typography>-</Typography>
              ) : (
                user?.customFields?.confidences.map((c, i) => (
                  <Typography key={i}>{c}</Typography>
                ))
              )}
            </Stack>
          }
        />
        <ItemBox
          title="添付資料名"
          content={
            <>
              {!attachment?.orderAfterFiles?.length &&
              !attachment?.orderMatchedFiles?.length ? (
                <Typography>-</Typography>
              ) : (
                <Stack spacing={1}>
                  {!attachment?.orderAfterFiles?.length ? (
                    <Typography>-</Typography>
                  ) : (
                    user.customFields.orderAfterFiles.map((f, i) => {
                      return (
                        <Stack direction="row" minWidth="25rem" key={i}>
                          <Typography>{f.name}</Typography>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={0.5}
                            color={theme.palette.primary.light}
                            ml="auto"
                          >
                            {getAttachUri(
                              f.attachmentId,
                              'orderAfterFiles'
                            ) && (
                              <>
                                <OpenInNewIcon fontSize="small" />
                                <MuiLink
                                  target="_blank"
                                  href={getAttachUri(
                                    f.attachmentId,
                                    'orderAfterFiles'
                                  )}
                                >
                                  表示する
                                </MuiLink>
                              </>
                            )}
                          </Stack>
                        </Stack>
                      );
                    })
                  )}
                  {!attachment?.orderMatchedFiles?.length ||
                  !(
                    user?.customFields?.orderMatchedFiles?.length &&
                    (orderStatus === OrderStatus.ACCEPTED ||
                      orderStatus === OrderStatus.PROCESSING ||
                      orderStatus === OrderStatus.CLOSED)
                  ) ? (
                    <Typography>-</Typography>
                  ) : (
                    user.customFields.orderMatchedFiles.map((f, i) => {
                      return (
                        <Stack direction="row" minWidth="25rem" key={i}>
                          <Typography>{f.name}</Typography>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={0.5}
                            color={theme.palette.primary.light}
                            ml="auto"
                          >
                            {getAttachUri(
                              f.attachmentId,
                              'orderMatchedFiles'
                            ) && (
                              <>
                                <OpenInNewIcon fontSize="small" />
                                <MuiLink
                                  target="_blank"
                                  href={getAttachUri(
                                    f.attachmentId,
                                    'orderMatchedFiles'
                                  )}
                                >
                                  表示する
                                </MuiLink>
                              </>
                            )}
                          </Stack>
                        </Stack>
                      );
                    })
                  )}
                </Stack>
              )}
            </>
          }
        />
      </Stack>
      <Divider sx={{ mt: 3 }} />
    </Box>
  );
}
