import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';

type TabsProps = {
  acceptedCount: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  pendingCount: number;
  processingCount: number;
  value: number;
  visibleUnderLine?: boolean;
};
export const TabsComponent: React.FC<TabsProps> = ({
  value,
  onChange,
  processingCount,
  acceptedCount,
  pendingCount,
  visibleUnderLine = false,
}) => {
  function a11yProps(index: number) {
    return {
      'aria-controls': `simple-tabpanel-${index}`,
      id: `simple-tab-${index}`,
    };
  }

  return (
    <Box
      sx={{
        borderBottom: visibleUnderLine ? 'none' : 1,
        borderColor: visibleUnderLine ? 'transparent' : 'divider',
      }}
    >
      <Tabs value={value} onChange={onChange} sx={{ mx: 2 }}>
        <Tab label={`応募中 (${pendingCount})`} {...a11yProps(0)} />
        <Tab label={`勤務予定 (${acceptedCount})`} {...a11yProps(1)} />
        <Tab label={`評価待ち (${processingCount})`} {...a11yProps(2)} />
        <Tab label="すべて" {...a11yProps(3)} />
      </Tabs>
    </Box>
  );
};

interface CustomTabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
export const CustomTabPanel: React.FC<CustomTabPanelProps> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </Grid>
  );
};
