import { atom, selector } from 'recoil';

import { getAttributes, getLocationList } from '@app/adapter/catalog-service';
import {
  Attribute,
  AttributeGroupName,
  Catalog,
  Category,
  CategoryResponse,
  LocationType,
  LocationListResponse,
  Product,
  ProductVariant,
  Location,
  ProductLocation,
} from '@app/types/catalog';
import { defaultProductValue } from '@app/utils/constants';

export const selectedProduct = atom<Product>({
  default: defaultProductValue,
  key: 'selectedProduct',
});

// export const normalizedProduct = selector<Product>({
//   get: async ({ get }): Promise<Product> => {
//     const categories = get(categoryList);
//     const product = get(selectedProduct);

//     return await normalizeSelectedProduct(categories, product);
//   },
//   key: 'normalizedProduct',
// });

export const catalogState = atom<Catalog>({
  default: {
    '@nextLink': '',
    count: 0,
    total: 0,
    value: [],
  },
  key: 'catalogState',
});

export const productsState = selector<Product[]>({
  get: ({ get }) => {
    const catalog = get(catalogState);
    const products = catalog.value;
    return products;
  },
  key: 'productsState',
});

export const isEditingProduct = atom<boolean>({
  default: false,
  key: 'isEditingProduct',
});

export const categoryState = atom<CategoryResponse>({
  default: {
    '@nextLink': '',
    count: 0,
    total: 0,
    value: [],
  },
  key: 'categoryState',
});

export const categoryList = selector<Category[]>({
  get: ({ get }) => {
    const category = get(categoryState);
    return category.value;
  },
  key: 'categoryList',
});

export const locationState = atom<LocationListResponse>({
  default: {
    '@nextLink': '',
    count: 0,
    total: 0,
    value: [],
  },
  key: 'locationState',
});

export const locationList = selector<Location[]>({
  get: ({ get }) => {
    const location = get(locationState);
    return location.value;
  },
  key: 'locationList',
});

export const locationsByPrefectureState = atom<ProductLocation[]>({
  default: [],
  key: 'catalog/locationsByPrefectureState',
});
export const locationsByPrefectureSelector = selector<ProductLocation[]>({
  get: async ({ get }) => {
    const state = get(locationsByPrefectureState);
    if (state?.length > 0) return state;

    try {
      const {
        data: { value },
      } = await getLocationList({
        type: LocationType.PREFECTURE,
      });
      return value;
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  key: 'catalog/locationsByPrefectureSelector',
  set: ({ set }, newData) => {
    set(locationsByPrefectureState, newData);
  },
});

export const attributesByClinicalDepartmentState = atom<Attribute[]>({
  default: [],
  key: 'catalog/attributesByClinicalDepartmentState',
});
export const attributesByClinicalDepartmentSelector = selector<Attribute[]>({
  get: async ({ get }) => {
    const state = get(attributesByClinicalDepartmentState);
    if (state?.length > 0) return state;

    try {
      const {
        data: { value },
      } = await getAttributes({
        filter: {
          groupName: [AttributeGroupName.CLINICAL_DEPARTMENT],
        },
        pageNumber: 0,
        pageSize: 100,
      });
      return value;
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  key: 'catalog/attributesByClinicalDepartmentSelector',
  set: ({ set }, newData) => {
    set(attributesByClinicalDepartmentState, newData);
  },
});

export const normalizePlan = (
  plans: ProductVariant[]
): { id?: string; price: string; title: string }[] => {
  const normalizedPlanList = plans.map((plan) => {
    const normalizedPlan = {
      id: plan.id,
      price: plan.price.amount ? plan.price.amount.toString() : '',
      title: plan.title,
    };
    return normalizedPlan;
  });
  if (normalizedPlanList.length === 0) {
    return [
      {
        price: '',
        title: '',
      },
    ];
  }
  return normalizedPlanList;
};

export const getLocationIds = (locations: ProductLocation[]) => {
  const locationIds = locations.map((location) => {
    return location.id;
  });
  return locationIds;
};

// async function normalizeSelectedProduct(
//   categories: Category[],
//   product: Product
// ) {
//   const categoryURI = String(_.get(product, 'category.uri', '')).split('/');
//   const categoryId = categoryURI[categoryURI.length - 1];
//   let selectedCategory = undefined;
//   categories.forEach((category) => {
//     category.children.forEach((subCategory) => {
//       if (subCategory.id === categoryId) {
//         selectedCategory = subCategory;
//       }
//     });
//   });

//   const locations: ProductLocation[] = [];
//   for (let i = 0; i < product.locations.length; i++) {
//     const locationUri = String(_.get(product.locations[i], 'uri', '')).split(
//       '/'
//     );
//     const locationId = locationUri[locationUri.length - 1];
//     const locationInfo = await getLocationById(locationId);
//     locations.push(locationInfo.data);
//   }
//   return {
//     ...product,
//     category: selectedCategory,
//     locations,
//   };
// }
