import SearchIcon from '@mui/icons-material/Search';
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  SxProps,
  Theme,
} from '@mui/material';
import React, { useRef, useState } from 'react';

interface SearchTextBoxProps {
  onSubmit: (value: string) => void;
  sx?: SxProps<Theme>;
  value: string;
}
export function SearchTextBox({ onSubmit, sx, value }: SearchTextBoxProps) {
  const [keyword, setKeyword] = useState<string>(value);
  const isImeOnRef = useRef<boolean>(false); // 日本語入力中の変換確定Enterを抑制
  const filterKeywordInputRef = useRef<HTMLInputElement>(null);
  const handleKeyDownFilterKeyword = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (
      filterKeywordInputRef.current &&
      !isImeOnRef.current &&
      event.key === 'Enter'
    ) {
      onSubmit(filterKeywordInputRef.current.value);
    }
  };

  return (
    <FormControl variant="outlined" sx={sx}>
      <OutlinedInput
        startAdornment={
          <InputAdornment position="start" sx={{ mr: 0 }}>
            <SearchIcon sx={{ m: 1 }} />
          </InputAdornment>
        }
        value={keyword}
        size="small"
        placeholder="キーワード検索"
        inputRef={filterKeywordInputRef}
        onCompositionStart={() => (isImeOnRef.current = true)}
        onCompositionEnd={() => (isImeOnRef.current = false)}
        onChange={(e) => setKeyword(e.target.value)}
        onKeyDown={handleKeyDownFilterKeyword}
        sx={{ pl: 0 }}
      />
    </FormControl>
  );
}
