import { Product } from '@app/types/catalog';
import { formatDate, formatTime, formatWeek } from '@app/utils/format';

/**
 * "YYYY年MM月DD日(曜)"形式または"月曜・火曜・水曜"形式の求人タイトルを取得する。
 * @param product 求人
 * @returns タイトル
 */
export function getProductDayWeekTitle(product: Product | undefined) {
  return product?.category.name === 'スポット'
    ? formatDate(product?.customFields?.day)
    : product?.category.name === '定期非常勤'
    ? formatWeek(product.customFields?.repeatWeek)
    : '';
}

/**
 * "YYYY年MM月DD日(曜) hh:mm~hh:mm"形式または"月曜・火曜・水曜 hh:mm~hh:mm"形式の求人タイトルを取得する。
 * @param product 求人
 * @returns タイトル
 */
export function getProductDayWeekTimeTitle(product: Product | undefined) {
  return `${getProductDayWeekTitle(product)} ${formatTime(
    product?.customFields?.startTime
  )}~${formatTime(product?.customFields?.endTime)}`;
}

export function getCalculatedHourlyPay(
  startTime: string,
  endTime: string,
  breakTime: number | null,
  variantsPriceAmount: number | null
) {
  /** 勤務開始時間 */
  const startMinutes = startTime
    .split(':')
    .map((v) => Number(v))
    .reduce((r, v, i) => (i === 0 ? v * 60 : r + v), 0);
  /** 勤務終了時間 */
  const endMinutes = endTime
    .split(':')
    .map((v) => Number(v))
    .reduce((r, v, i) => (i === 0 ? v * 60 : r + v), 0);
  /** 勤務時間（終了時間-開始時間-休憩時間） */
  const workHour = (endMinutes - startMinutes - (breakTime ?? 0)) / 60;
  /** 想定時給 */
  const calculatedHourlyPay = variantsPriceAmount
    ? Math.trunc(variantsPriceAmount / workHour)
    : 0; // 日給の場合は報酬金額÷勤務時間
  return calculatedHourlyPay;
}

export function getCalculatedDairyPay(
  startTime: string,
  endTime: string,
  breakTime: number | null,
  variantsPriceAmount: number | null
) {
  /** 勤務開始時間 */
  const startMinutes = startTime
    .split(':')
    .map((v) => Number(v))
    .reduce((r, v, i) => (i === 0 ? v * 60 : r + v), 0);
  /** 勤務終了時間 */
  const endMinutes = endTime
    .split(':')
    .map((v) => Number(v))
    .reduce((r, v, i) => (i === 0 ? v * 60 : r + v), 0);
  /** 勤務時間（終了時間-開始時間-休憩時間） */
  const workHour = (endMinutes - startMinutes - (breakTime ?? 0)) / 60;
  /** 想定日給 */
  const calculatedDairyPay = variantsPriceAmount
    ? Math.trunc(variantsPriceAmount * workHour)
    : 0; // 時給の場合は報酬金額×勤務時間
  return calculatedDairyPay;
}
