import { Typography } from '@mui/material';

export function CopyrightBox() {
  const currentYear = new Date().getFullYear();

  return (
    <Typography
      variant="subtitle1"
      component="div"
      display="block"
      gutterBottom
      sx={{
        color: '#5A6170',
        fontSize: '0.85rem',
        mt: 3,
        textAlign: 'center',
      }}
    >
      © {currentYear} Pro Doctors All rights reserved.
    </Typography>
  );
}
