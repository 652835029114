import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

export interface OrderCancelInput {
  cancelReason: string;
  otherReason: string;
}

export const OrderCancelFormDefaultValues: OrderCancelInput = {
  cancelReason: '',
  otherReason: '',
};

const OrderCancelFormSchema = z
  .object({
    cancelReason: z
      .string()
      .min(1, { message: '不採用理由を選択してください' }),
    otherReason: z.string(),
  })
  .refine((data) => !(data.cancelReason === '他' && !data.otherReason), {
    message: '補足コメントを入力してください',
    path: ['otherReason'],
  });

export const OrderCancelForm = {
  defaultValues: OrderCancelFormDefaultValues,
  resolver: zodResolver(OrderCancelFormSchema),
  schema: OrderCancelFormSchema,
} as const;
