import { zodResolver } from '@hookform/resolvers/zod';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Button,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  InputAdornment,
  IconButton,
  useTheme,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { z } from 'zod';

import { resetPasswordFromSetting } from '@app/adapter/user-service';
import {
  AccountSettingHeader,
  AccountSettingPaper,
} from '@app/components/AccountSetting/AccountSettingHeader';
import {
  userAuthInfoSelector,
  snackbarOpenState,
  snackbarSeverityState,
  snackbarTextState,
} from '@app/domain/app';
import { generatePasswordPattern } from '@app/utils/pattern';

interface ChangePasswordFormContent {
  confirmPassword: string;
  password: string;
}

const ChangePasswordForm = z
  .object({
    confirmPassword: z
      .string()
      .min(8, 'パスワードは記号を除く、英数字含む8文字以上を入力してください')
      .max(24, '24文字以下のパスワードを入力してください')
      .regex(generatePasswordPattern(), {
        message: '小文字英字と数字を組み合わせてください',
      }),
    password: z
      .string()
      .min(8, 'パスワードは記号を除く、英数字含む8文字以上を入力してください')
      .max(24, '24文字以下のパスワードを入力してください')
      .regex(generatePasswordPattern(), {
        message: '小文字英字と数字を組み合わせてください',
      }),
  })
  .refine(({ password, confirmPassword }) => password === confirmPassword, {
    message: 'パスワードが一致しません',
    path: ['confirmPassword'],
  });

export function PasswordChangePage() {
  const navigate = useNavigate();
  const userAuthInfoState = useRecoilValue(userAuthInfoSelector);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const setSnackbarState = useSetRecoilState(snackbarSeverityState);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const theme = useTheme();

  const handlePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const { control, handleSubmit } = useForm<ChangePasswordFormContent>({
    defaultValues: {
      confirmPassword: '',
      password: '',
    },
    mode: 'all',
    resolver: zodResolver(ChangePasswordForm),
  });

  const onSubmit = useCallback(
    async (data: ChangePasswordFormContent) => {
      const res = await resetPasswordFromSetting(
        userAuthInfoState.id,
        data.password
      );
      if (res.status === 200) {
        setSnackbarText('パスワードを変更しました。');
        setSnackbarState('success');
        setSnackbarOpen(true);
      } else {
        setSnackbarText('パスワードの変更に失敗しました。');
        setSnackbarState('error');
        setSnackbarOpen(true);
      }
      navigate('/account-setting');
    },
    [
      navigate,
      userAuthInfoState.id,
      setSnackbarText,
      setSnackbarState,
      setSnackbarOpen,
    ]
  );

  return (
    <>
      <AccountSettingHeader title="アカウント設定" />
      <AccountSettingPaper title="パスワード変更">
        <Grid>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid sx={{ minWidth: 500 }}>
              <Grid container spacing={3} sx={{ pt: 2 }}>
                <Grid item xs={12}>
                  <FormControl variant="outlined" sx={{ width: 400 }}>
                    <FormLabel>
                      <Typography
                        color="textPrimary"
                        component="div"
                        variant="subtitle2"
                        fontWeight={600}
                        sx={{ textAlign: 'left' }}
                      >
                        新しいパスワード
                        <Typography component="span" color="error">
                          *
                        </Typography>
                      </Typography>
                    </FormLabel>
                    <Controller
                      name="password"
                      control={control}
                      rules={{ required: true }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={error ? true : undefined}
                          helperText={error?.message}
                          margin="dense"
                          placeholder="新しいパスワードを入力"
                          type={isPasswordVisible ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handlePasswordVisibility}
                                  onMouseDown={(e) => e.preventDefault()}
                                >
                                  {isPasswordVisible ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                    <Typography
                      sx={{
                        color: theme.customPalette.lightGray,
                        textAlign: 'left',
                      }}
                    >
                      8文字以上、英数字含む（記号を除く）
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl variant="outlined" sx={{ width: 400 }}>
                    <FormLabel>
                      <Typography
                        color="textPrimary"
                        component="div"
                        variant="subtitle2"
                        fontWeight={600}
                        sx={{ textAlign: 'left' }}
                      >
                        新しいパスワード確認
                        <Typography component="span" color="error">
                          *
                        </Typography>
                      </Typography>
                    </FormLabel>
                    <Controller
                      name="confirmPassword"
                      control={control}
                      rules={{ required: true }}
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          error={error ? true : undefined}
                          helperText={error?.message}
                          margin="dense"
                          placeholder="新しいパスワードを再度入力"
                          type={isPasswordVisible ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handlePasswordVisibility}
                                  onMouseDown={(e) => e.preventDefault()}
                                >
                                  {isPasswordVisible ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid sx={{ pr: 3, py: 2 }}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                sx={{ pl: 5, pr: 5 }}
              >
                変更
              </Button>
            </Grid>
          </form>
        </Grid>
      </AccountSettingPaper>
    </>
  );
}
