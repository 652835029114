import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SearchIcon from '@mui/icons-material/Search';
import {
  Avatar,
  Badge,
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  FormControlLabel,
  InputAdornment,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  TablePagination,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { parseISO, format } from 'date-fns';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { getUserChatTopicListWithFilter } from '@app/adapter/chat-service';
import {
  userAuthInfoSelector,
  snackbarSeverityState,
  snackbarTextState,
} from '@app/domain/app';
import { chatTopicsState } from '@app/domain/chat';
import { useAppContext } from '@app/hooks/appContext';
import { MessageTypeId } from '@app/types/chats';
import { getAge } from '@app/utils';
import { isError } from '@app/utils/error';

export function ChatList() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [chatTopics, setChatTopics] = useRecoilState(chatTopicsState);
  const userAuthInfoState = useRecoilValue(userAuthInfoSelector);
  const setErrorSnackbarOpen = useSetRecoilState(snackbarSeverityState);
  const setErrorSnackbarText = useSetRecoilState(snackbarTextState);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOnlyUnread, setIsOnlyUnread] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { unreadCount } = useAppContext();
  const [totalRowCount, setTotalRowCount] = useState(0);
  // 指定されたトピックIDに関連するメッセージ情報を取得して、全体のトピックリストの中でそのトピックを更新
  const getChatTopics = useCallback(async () => {
    setIsLoading(true);
    if (!userAuthInfoState) {
      setIsLoading(false);
      return;
    }
    try {
      const result = await getUserChatTopicListWithFilter(
        userAuthInfoState.id,
        {
          expandUser: true,
          pageNumber: page,
          pageSize: rowsPerPage,
        }
      );
      // TopicNameをChat相手の名前に上書き
      const updatedTopics = await Promise.all(
        result.data.value.map(async (topic) => {
          const targetUser = topic.owners?.find(
            (owner) => owner?.typeId === '000'
          );
          if (targetUser) {
            topic.name =
              targetUser.customFields.familyName +
              targetUser.customFields.firstName +
              ' / ' +
              `${getAge(targetUser.customFields.birthday)}歳` +
              '・' +
              targetUser.customFields.gender;
          } else {
            topic.name = '無名';
          }
          return topic;
        })
      );
      setChatTopics({ ...result.data, value: updatedTopics });
      setTotalRowCount(result.data.total);
    } catch (error) {
      if (isError(error)) {
        setErrorSnackbarText(`チャットの取得に失敗しました, ${error.message}`);
      } else {
        setErrorSnackbarText(`チャットの取得に失敗しました`);
      }
      setErrorSnackbarOpen('error');
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setChatTopics, page, rowsPerPage]);

  useEffect(() => {
    if (!userAuthInfoState) {
      navigate('/login');
      return;
    }
    void getChatTopics();
  }, [getChatTopics, navigate, userAuthInfoState, unreadCount]);

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCheckboxChangeUnread = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsOnlyUnread(event.target.checked);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  const displayedChatTopics = chatTopics?.value?.filter((topic) => {
    if (isOnlyUnread && (!topic.unreadCount || topic.unreadCount <= 0))
      return false;
    const includesSearchKeyword = (value: string | undefined | null) => {
      return value ? value.includes(searchKeyword) : false;
    };

    if (
      searchKeyword &&
      !includesSearchKeyword(topic.content) &&
      !includesSearchKeyword(topic.name)
    ) {
      return false;
    }
    return true;
  });

  function formatDate(updatedAt: string) {
    const date = parseISO(updatedAt);
    const differenceInMilliseconds = new Date().getTime() - date.getTime();
    const differenceInHours = differenceInMilliseconds / (60 * 60 * 1000);
    const differenceInDays = differenceInMilliseconds / (24 * 60 * 60 * 1000);
    const differenceInWeeks =
      differenceInMilliseconds / (7 * 24 * 60 * 60 * 1000);

    if (differenceInHours < 24) {
      return format(date, 'HH:mm');
    } else if (differenceInDays < 8) {
      return `${Math.floor(differenceInDays)}日前`;
    } else if (differenceInDays < 30) {
      return `${Math.floor(differenceInWeeks)}週間前`;
    }
    const differenceInMonths = differenceInDays / 30;
    return `${Math.floor(differenceInMonths)}ヶ月前`;
  }

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h5" component="div">
          メッセージ
        </Typography>
        <Link to="/chats/templates" style={{ color: 'inherit' }}>
          テンプレート管理
        </Link>
      </Grid>
      <Paper elevation={1}>
        <Stack direction="row" spacing={1} p={2}>
          <TextField
            variant="outlined"
            placeholder="キーワードで検索"
            value={searchKeyword}
            size="small"
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <FormControlLabel
            label="未読のみで絞り込み"
            control={
              <Checkbox
                checked={isOnlyUnread}
                onChange={handleCheckboxChangeUnread}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              />
            }
          />
        </Stack>
        <Divider />
        {isLoading ? (
          <Grid style={{ padding: '20px', textAlign: 'center' }}>
            <CircularProgress />
          </Grid>
        ) : (
          <Stack divider={<Divider />}>
            {displayedChatTopics.map((chatTopic) => (
              <ListItemButton
                key={chatTopic.id}
                onClick={() => {
                  navigate(`/chats/${chatTopic.id}`);
                }}
              >
                <ListItemAvatar>
                  <Avatar sx={{ backgroundColor: 'lightblue' }}>
                    <PersonOutlineIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <Typography>{chatTopic.name}</Typography>
                      <Typography>
                        {chatTopic.messageTypeId === MessageTypeId.IMAGE
                          ? '画像'
                          : chatTopic.messageTypeId === MessageTypeId.FILE
                          ? 'ファイル'
                          : (chatTopic.lastMessage?.content || '').replace(
                              /\\n/g,
                              '\n'
                            )}
                      </Typography>
                    </>
                  }
                />
                <Stack alignItems="center">
                  <Typography
                    variant="body2"
                    sx={{ color: theme.customPalette.lightGray }}
                  >
                    {formatDate(chatTopic.updatedAt)}
                  </Typography>
                  <Box>
                    <Badge
                      badgeContent={chatTopic.unreadCount}
                      invisible={chatTopic.unreadCount < 1}
                      color="error"
                    />
                  </Box>
                </Stack>
              </ListItemButton>
            ))}
          </Stack>
        )}
        <Divider />
        <TablePagination
          component="div"
          labelRowsPerPage={
            <Typography variant="body2" component="span">
              1ページの表示数
            </Typography>
          }
          rowsPerPageOptions={[5, 10, 20, 30]}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} of ${count}`
          }
          count={totalRowCount}
          page={page}
          rowsPerPage={rowsPerPage}
          showFirstButton
          showLastButton
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          data-e2e="home-pagination"
        />
      </Paper>
    </>
  );
}
