import _ from 'lodash';
import { ReactElement, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { checkToken } from '@app/adapter/auth-service';
import { setRequestInterceptor } from '@app/adapter/axios';
import { getOrganizationDetails } from '@app/adapter/organization-service';
import { getUser } from '@app/adapter/user-service';
import {
  loggedInUserState,
  snackbarOpenState,
  snackbarTextState,
  userAuthInfoSelector,
} from '@app/domain/app';
import { generateFingerPrint } from '@app/domain/fingerprint';
import { organization } from '@app/domain/organization';
import { Organization } from '@app/types/organization';
import { isError } from '@app/utils/error';
import { isVendorUser } from '@app/utils/is_vendor_user';
import { ERROR_MESSAGE } from '@app/utils/message';

export function LoginCallback(): ReactElement {
  const navigate = useNavigate();
  const setUserAuthInfo = useSetRecoilState(userAuthInfoSelector);
  const setLoggedInUser = useSetRecoilState(loggedInUserState);
  const setOrganization = useSetRecoilState(organization);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);

  const location = useLocation();
  const { provider } = useParams<{ provider: string }>();

  useEffect(() => {
    const login = async () => {
      const { search } = location;
      const url = new URLSearchParams(search);
      const code = url.get('code');

      if (code) {
        const checkTokenResponse = await checkToken(code, `${provider}Oauth`);

        //
        // TODO: Oauth Provider からリダイレクトしてくるケース？
        //  ((( 現在利用していないはずなので TODO )))
        //
        const fingerprint = await generateFingerPrint();
        const accessToken = '';

        const {
          data: { userId },
        } = checkTokenResponse;

        setRequestInterceptor({ accessToken, fingerprint });

        const user = await getUser(userId);

        if (!isVendorUser(user.data.typeId)) {
          throw new Error(ERROR_MESSAGE.INVALID_USER_TYPE);
        }
        setUserAuthInfo({
          accessToken,
          fingerprint,
          id: userId,
        });
        if (!_.isEmpty(_.get(user, 'data.name', ''))) {
          setLoggedInUser(user.data);
        }

        const org = await getOrganizationDetails(userId);
        if (!_.isEmpty(_.get(org, 'data.value', ''))) {
          // TODO: UPDATE SHAPE OF ORGANIZATION AND STATE
          // TODO: resolve as
          const orgData = _.get(
            org,
            'data.value[0]'
          ) as unknown as Organization;
          setOrganization(orgData);
          if (orgData?.status !== 'normal') {
            navigate('/register/organization');
          } else {
            navigate('/');
          }
        } else {
          navigate('/register/organization');
        }
      }
    };

    login().catch((error: unknown) => {
      if (isError(error)) {
        setSnackbarText(`ログインに失敗しました, ${error.message}`);
      } else {
        setSnackbarText(`ログインに失敗しました`);
      }
      setSnackbarOpen(true);
      navigate('/login');
    });
  }, [
    location,
    navigate,
    provider,
    setLoggedInUser,
    setOrganization,
    setUserAuthInfo,
    setSnackbarOpen,
    setSnackbarText,
  ]);

  return <></>;
}
