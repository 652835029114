import { AxiosPromise } from 'axios';
import { format as dateFnsFormat } from 'date-fns';

import { axiosInstance, NOTIFICATION_SERVICE } from '@app/adapter/axios';
import { Paginated } from '@app/types/common';
import {
  Message,
  MessageInput,
  MessageStatus,
  MessageTarget,
  MessageUpdate,
  Topic,
  TopicInput,
} from '@app/types/notifications';
import { filterSyntaxGen } from '@app/utils';

export function getMessageListWithFilter(
  userId: string,
  options?: {
    dateRange?: {
      end: string;
      start: string;
    };
    keyword?: string;
    order?: 'createdAt desc' | string;
    pageNumber?: number | 0;
    pageSize?: number | 10;
    statuses?: MessageStatus[];
    targets?: MessageTarget[];
  }
): AxiosPromise<Paginated<Message>> {
  const urlParams: string[][] = [];
  const filterParam = [];
  if (options?.pageSize !== undefined && options?.pageNumber !== undefined) {
    urlParams.push([`$top`, options.pageSize.toString()]);
    urlParams.push([
      `$skip`,
      (options.pageNumber * options.pageSize).toString(),
    ]);
  }
  if (options?.dateRange) {
    const param = `(customFields.publicationTime ge '${dateFnsFormat(
      new Date(options.dateRange.start),
      'yyyy-MM-dd'
    )}' and customFields.publicationTime le '${dateFnsFormat(
      new Date(options.dateRange.end),
      'yyyy-MM-dd'
    )}')`;
    filterParam.push(param);
  }
  if (options?.keyword) {
    const keyword = options.keyword;
    const keywordFilters = [
      `id co '${keyword}'`,
      `title co '${keyword}'`,
      `content co '${keyword}'`,
      `senderId co '${keyword}'`,
    ];
    filterParam.push(`(${keywordFilters.join(' or ')})`);
  }
  if (options?.statuses?.length) {
    filterParam.push(
      `customFields.status in ${filterSyntaxGen(options.statuses)}`
    );
  }
  if (options?.targets?.length) {
    filterParam.push(
      `customFields.target in ${filterSyntaxGen(options.targets)}`
    );
  }
  if (filterParam.length > 0) {
    urlParams.push(['$filter', filterParam.join(' and ')]);
  }
  if (options?.order) {
    urlParams.push(['$orderBy', options?.order]);
  } else {
    urlParams.push(['$orderBy', 'createdAt desc']);
  }
  return axiosInstance.get(
    `${NOTIFICATION_SERVICE}/users/${userId}/messages?${new URLSearchParams(
      urlParams
    ).toString()}`
  );
}

export function getMessages(options?: {
  dateRange?: {
    end: string;
    start: string;
  };
  keyword?: string;
  order?: 'createdAt desc' | string;
  pageNumber?: number | 0;
  pageSize?: number | 10;
  senderIds?: string[];
  statuses?: MessageStatus[];
  targets?: MessageTarget[];
}): AxiosPromise<Paginated<Message>> {
  const urlParams: string[][] = [];
  const filterParam = [];
  if (options?.pageSize !== undefined && options?.pageNumber !== undefined) {
    urlParams.push([`$top`, options.pageSize.toString()]);
    urlParams.push([
      `$skip`,
      (options.pageNumber * options.pageSize).toString(),
    ]);
  }
  if (options?.dateRange) {
    const param = `(customFields.publicationTime ge '${dateFnsFormat(
      new Date(options.dateRange.start),
      'yyyy-MM-dd'
    )}' and customFields.publicationTime le '${dateFnsFormat(
      new Date(options.dateRange.end),
      'yyyy-MM-dd'
    )}')`;
    filterParam.push(param);
  }
  if (options?.keyword) {
    const keyword = options.keyword;
    const keywordFilters = [
      `id co '${keyword}'`,
      `title co '${keyword}'`,
      `content co '${keyword}'`,
      `senderId co '${keyword}'`,
    ];
    filterParam.push(`(${keywordFilters.join(' or ')})`);
  }
  if (options?.senderIds?.length) {
    filterParam.push(`senderId in ${filterSyntaxGen(options.senderIds)}`);
  }
  if (options?.statuses?.length) {
    filterParam.push(
      `customFields.status in ${filterSyntaxGen(options.statuses)}`
    );
  }
  if (options?.targets?.length) {
    filterParam.push(
      `customFields.target in ${filterSyntaxGen(options.targets)}`
    );
  }
  if (filterParam.length > 0) {
    urlParams.push(['$filter', filterParam.join(' and ')]);
  }
  if (options?.order) {
    urlParams.push(['$orderBy', options?.order]);
  } else {
    urlParams.push(['$orderBy', 'createdAt desc']);
  }
  return axiosInstance.get(
    `${NOTIFICATION_SERVICE}/messages?${new URLSearchParams(
      urlParams
    ).toString()}`
  );
}

export function getSingleMessage(messageId: string): AxiosPromise<Message> {
  return axiosInstance.get(`${NOTIFICATION_SERVICE}/messages/${messageId}`);
}

export function createMessage(data: MessageInput): AxiosPromise<Message> {
  return axiosInstance.post(`${NOTIFICATION_SERVICE}/messages`, data);
}

export function updateMessage(
  messageId: string,
  data: MessageUpdate
): AxiosPromise<Message> {
  return axiosInstance.patch(
    `${NOTIFICATION_SERVICE}/messages/${messageId}`,
    data
  );
}

export function deleteMessage(messageId: string): AxiosPromise<void> {
  return axiosInstance.delete(`${NOTIFICATION_SERVICE}/messages/${messageId}`);
}

export function getTopics(options?: {
  ids?: string[];
  names?: string[];
  order?: string;
  ownerIds?: string[];
  ownerTypes?: string[];
  pageNumber?: number | 0;
  pageSize?: number | 10;
}): AxiosPromise<Paginated<Topic>> {
  const urlParams: string[][] = [];
  const filterParam = [];
  if (options?.pageSize !== undefined && options?.pageNumber !== undefined) {
    urlParams.push([`$top`, options.pageSize.toString()]);
    urlParams.push([
      `$skip`,
      (options.pageNumber * options.pageSize).toString(),
    ]);
  }
  if (options?.ids?.length) {
    filterParam.push(`id in ${filterSyntaxGen(options.ids)}`);
  }
  if (options?.names?.length) {
    filterParam.push(`name in ${filterSyntaxGen(options.names)}`);
  }
  if (options?.ownerIds?.length) {
    filterParam.push(`ownerId in ${filterSyntaxGen(options.ownerIds)}`);
  }
  if (options?.ownerTypes?.length) {
    filterParam.push(`ownerType in ${filterSyntaxGen(options.ownerTypes)}`);
  }
  if (filterParam.length > 0) {
    urlParams.push(['$filter', filterParam.join(' and ')]);
  }
  if (options?.order) {
    urlParams.push(['$orderBy', options?.order]);
  }
  return axiosInstance.get(
    `${NOTIFICATION_SERVICE}/topics?${new URLSearchParams(
      urlParams
    ).toString()}`
  );
}

export function createTopic(data: TopicInput): AxiosPromise<Topic> {
  return axiosInstance.post(`${NOTIFICATION_SERVICE}/topics`, data);
}
